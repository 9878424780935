import React, { useState } from 'react';
import { ScrollView, Text, TextInput, View } from 'react-native';
// import Hyperlink from "react-native-hyperlink";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { AboutUs, getProfile } from "../../redux/actions/auth";
import Button from '../../components/button/Button';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import Colors from '../../constants/Colors';
import getPlatformType from '../../helpers/helpers';
import { EmailValidation, NameValidation, SubjectValidation } from '../../helpers/validation';
import { ContactHead, ContactTitle, Container, FormContainer, MainContent } from './styles';
const layoutType = getPlatformType();

const AboutUs = ({ navigation }) => {
  const [state, setState] = useState({
    name: '',
    nameErrorMsg: '',
    email: '',
    emailErrorMsg: '',
    topic: '',
    message: '',
    profile: null,
    first_name: '',
    last_name: '',
    pre_email: '',
    topicErrorMsg: '',
    messageErrorMsg: '',
  });
  const validate = () => {};

  return (
    <View style={{ flex: 1 }}>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <MainContent style={{ flexDirection: 'column' }}>
            <ContactTitle>About Us</ContactTitle>

            <Container
              style={{ backgroundColor: 'white', padding: 20, minHeight: 500, borderTopRightRadius: 10 }}
            ></Container>
          </MainContent>
        </View>
      </ScrollView>
    </View>
  );
};
export default drawerHoc({
  Component: AboutUs,
  showBackButton: true,
});
