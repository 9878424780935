

const Urls = {
  LoginAPI: 'v1.0/login',
  RegistrationAPI: 'v1.0/register',
  DeleteDoc: 'v1.0/properties/document/',
  Properties: 'v1.0/properties',
  Certificates: 'v1.0/properties/certificate',
  ArchiveProperties: 'v1.0/properties/archived',
  UnarchiveProperties: 'v1.0/properties/unarchive',
  Licensing: 'v1.0/properties/license',
  Requirements: 'v1.0/properties/requirements',
  SearchProperty: 'v1.0/search-property',
  MortgageProvider: '/v1.0/mortgage-providers',
  ForgotPassword: 'v1.0/forgot-password',
  ResetPassword: 'v1.0/reset-password',
  SearchAddress: 'v1.0/search-address',
  Upload: 'uploads',
  GasProvider: 'v1.0/gas-providers',
  WifiProvider: 'v1.0/wifi-providers',
  WaterProvider: 'v1.0/water-providers',
  ElectricityProvider: 'v1.0/electricity-providers',
  CouncilProvider: 'v1.0/councils',
  ROOMS: 'v1.0/properties/rooms',
  COMPLETE: 'v1.0/properties/status',
  PROFILE: 'v1.0/user-profile',
  CHANGE_PASSWORD: 'v1.0/change-password',
  Payments: 'v1.0/payments',
  PAYMENTS_CSV: 'v1.0/payments/csv/download',
  ADD_PAYMENTS: 'v1.0/payments',
  PAYMENT_TYPES: 'v1.0/admin/payment-status',
  ADD_REMAINDER: 'v1.0/reminder',
  ADD_REMAINDERS: 'v1.0/reminders',
  ADD_REMAINDERS: 'v1.0/reminders',
  GET_RECENT_PROPERTIES: 'v1.0/properties/recently-viewed',
  GET_REM_TYPES: 'v1.0/admin/reminders',
  GET_REMAINDERS_MONTHLY: 'v1.0/reminders/monthly/',
  GET_PAYMENTS_MONTHL: 'v1.0/payments/rental?',
  GET_PROVIDERS: 'v1.0/admin/get-serviceprovider?groupby=true',
  GET_POST: 'v1.0/admin/get-mediahub?type=',
  GET_POST_DETAILS: '/v1.0/admin/get-mediahub-by/',
  GET_ARCHIVE_DOCUMENTS: 'v1.0/properties/archive-documents',
  CONTACT_US: 'v1.0/contact-us',
  UTILITIES: 'v1.0/properties/utilities',
  PAYMENT_TYPES_2: 'v1.0/admin/payment-types',
  Finance: 'v1.0/properties/finances',
  MY_DOCS: 'v1.0/properties/document',
  GET_ALL_DOCS: 'v1.0/properties-documents',
  landDetails: 'v1.0/landDetails',
  ARCHIVE_PROPERTY: 'v1.0/properties/archive/',
  GET_TEANANT_DETAILS:'v1.0/property/tenant',
  CHECK_TEANANT_EMAIL:'v1.0/property/check-tenant-email/',
  LAND_DETAILS: 'v1.0/properties/landlord-details',
  MoveToDocs: 'v1.0/properties/move-to-mydocs'
};

export default Urls;
