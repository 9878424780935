import React, { useState } from 'react';

import Box from '../../components/box';
import Urls from '../../services/Urls';
import ajax from '../../helpers/ajaxHelper';

import Button from '../../components/button/Button';

import TextInputComponent from '../../components/textinput/TextInputComponent';
import {  FormContainer, FormBox, MainText } from './index.style';


import { PasswordComparisonValidation, PasswordValidation } from '../../helpers/validation';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';

import { navigate } from '../../navigation/RootNavigation';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_pass, setConfirmPass] = useState('');
  const [passError, setPassError] = useState('');
  const [currentError, setCurrentPassError] = useState('');
  const [confirmPassError, setConfirmPassError] = useState('');

  const resetForm = () => {
    setCurrentPassword('');
    setPassword('');
    setConfirmPass('');
  };

  const _onPasswordChange = () => {
    let CurrentPasswordValidate = PasswordValidation(currentPassword);
    let PasswordValidate = PasswordValidation(password);
    let ConfirmPassValidate = PasswordComparisonValidation(password, confirm_pass);
    if (CurrentPasswordValidate.status && PasswordValidate.status && ConfirmPassValidate.status) {
      const payload = { old_password: currentPassword, new_password: password };
      ajax
        .post(Urls.CHANGE_PASSWORD, payload)
        .then(({ data }) => {
          if (data.status) {
            resetForm();
            alert(data.data);
            navigate('Setting');
          } else {
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    } else {
      setPassError(PasswordValidate?.status ? '' : PasswordValidate.msg);
      setCurrentPassError(CurrentPasswordValidate?.status ? '' : CurrentPasswordValidate.msg);
      setConfirmPassError(ConfirmPassValidate?.status ? '' : ConfirmPassValidate.msg);
    }
  };

  return (
    <>
      <MainText>Change Password</MainText>
      <Box>
        <FormBox>
          <TextInputComponent
          isDate={false}
            error={currentError}
            password
            value={currentPassword}
            renderRightComponent
            title={'Current password'}
            onChangeText={(e) => {
              setCurrentPassword(e), setCurrentPassError('');
            }}
            colored
          />
        </FormBox>
  
          <FormBox>
            <TextInputComponent
          isDate={false}

              error={passError}
              password
              renderRightComponent
              value={password}
              title={'New password'}
              onChangeText={(e) => {
                setPassword(e), setPassError('');
              }}
              colored
            />
          </FormBox>
          <FormBox>
            <TextInputComponent
          isDate={false}
              error={confirmPassError}
              password
              renderRightComponent
              value={confirm_pass}
              title={'Confirm new password'}
              onChangeText={(e) => {
                setConfirmPass(e), setConfirmPassError('');
              }}
              colored
            />
          </FormBox>
        
        <Button onPress={_onPasswordChange} width={150} childText={' Apply changes'} alignSelf={'flex-end'} />
      </Box>
    </>
  );
};
export default drawerHoc({
  Component: ChangePassword,
  showBackButton: true,
});
