import React, { useState, useEffect } from 'react';
import Box from '../../components/box';
import Text from '../../components/text';
import getPlatformType from '../../helpers/helpers';
import Button from '../../components/button/Button';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import ListCard from '../../components/listCard';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import RadioButton from '../../components/radioButton';
import Urls from '../../services/Urls';
import { useRoute } from '@react-navigation/native';
import ajax from '../../helpers/ajaxHelper';
import MarkAsComplete from '../../components/markAsComplete/MarkAsComplete';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import { useIsFocused } from '@react-navigation/native';
import {
  AddressValidation,
  EmailValidation,
  LastValidation,
  NameValidation,
  PostCodeValidation,
} from '../../helpers/validation';
import {
  Container,
  ContainerScrollView,
  TextInputWrapper,
  Row,
  HorizontalLine,
  RowCol,
  AddressSelect,
} from './index.style';
import { View } from 'react-native';
import { showSuccesToast } from '../tenants/tenantOnboarding/helpers';

var layoutType = getPlatformType();

const LandlordDetails = ({ navigation }) => {
  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    contact_number: '',
    email: '',
    address: '',
    postcode: '',
  });

  const [desLandlord, setDesLandlord] = useState({
    first_name: '',
    last_name: '',
    contact_number: '',
    email: '',
    address: '',
    postcode: '',
  });
  const [isDesignatedLandlord, setIsDesignatedLandlord] = useState('');
  const [emailError, setEmailError] = useState('');
  const [lastError, setLastError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [pinCodeError, setPinCodeError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [postcodes, setPostcodes] = useState([]);
  const route = useRoute();

  const _handleChange = (e) => {
    setDesLandlord({ ...desLandlord, [e.label]: e.value });
  };
  const getAddress = (address) => {
    let displayUrl = '';
    if (address?.SUB_BUILDING) {
      displayUrl = displayUrl + address?.SUB_BUILDING + ',' + ' ';
    }
    if (address?.BUILDING_NAME) {
      displayUrl = displayUrl + address?.BUILDING_NAME + ',' + ' ';
    }
    if (address?.BUILDING_NUMBER) {
      displayUrl = displayUrl + address?.BUILDING_NUMBER + ',' + ' ';
    }
    if (address?.STREET_NAME) {
      displayUrl = displayUrl + address?.STREET_NAME;
    }
    if (address?.LOCALITY) {
      displayUrl = displayUrl + address?.LOCALITY;
    }
    return displayUrl;
  };
  useEffect(() => {
    getPropertyDetails();
  }, []);

  const getPropertyDetails = () => {
    ajax
      .get(`${Urls.Properties}/${route?.params?.id}`)
      .then(({ data }) => {
        if (data.status) {
          setIsDesignatedLandlord(data?.payload?.is_designated_landlord ? 'Yes' : 'No');

          setState(data?.payload?.landlord);

          setDesLandlord(data?.payload.designatedLandlord || desLandlord);
        }
      })

      .catch((error) => {
        console.log('error', error);
      });
  };

  const _validation = () => {
    let emailValidate = EmailValidation(desLandlord.email);

    let firstNameValidate = NameValidation(desLandlord.first_name);

    let lastNameValidate = LastValidation(desLandlord.last_name);

    let postCodeValidate = PostCodeValidation(desLandlord.postcode);

    let addressValidate = AddressValidation(desLandlord.address);

    if (
      emailValidate.status &&
      firstNameValidate.status &&
      lastNameValidate.status &&
      addressValidate.status &&
      postCodeValidate.status
    ) {
      _onSave();
    } else {
      setEmailError(emailValidate?.status ? '' : emailValidate?.msg);

      setAddressError(addressValidate?.status ? '' : addressValidate?.msg);

      setFirstNameError(firstNameValidate?.status ? '' : firstNameValidate?.msg);

      setLastError(lastNameValidate?.status ? '' : lastNameValidate?.msg);

      setPinCodeError(postCodeValidate?.status ? '' : postCodeValidate?.msg);
    }
  };

  const _onSave = () => {
    const requestBody = {
      is_designated_landlord: isDesignatedLandlord && isDesignatedLandlord == 'Yes' ? true : false,
      landlord_details: {
        first_name: desLandlord?.first_name,
        last_name: desLandlord?.last_name,
        contact_number: desLandlord?.contact_number,
        email: desLandlord?.email,
        address: desLandlord?.address,
        postcode: desLandlord?.postcode,
      },
    };
    ajax
      .post(`${Urls.LAND_DETAILS}/${route?.params?.id}`, requestBody)
      .then(({ data }) => {
        if (data?.status) {
          showSuccesToast(data?.data);
          navigation.navigate('PropertySection');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onPostcodeSearch = () => {
    if (!state?.postcode) {
      alert('Please enter postcode value');
    } else {
      ajax
        .get(Urls.SearchAddress + '?postCode=' + state?.postcode)
        .then(({ data }) => {
          if (data.status) {
            try {
              // NOTE: new logic as per Andrew updated search postcodes API
              if (data?.data?.message) {
                alert(data?.data?.message);
                setPostcodes([]);
              }

              if (data?.data?.results) {
                let temp = [];

                for (let key in data?.data?.results) {
                  let obj = data?.data?.results[key];
                  let label = getAddress(obj);
                  temp = [...temp, { label: label, value: JSON.stringify(obj) }];
                }

                setPostcodes(temp);
              }
            } catch (err) {
              setPostcodes([]);
            }
          } else {
            alert(data.message);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  const _onAddressSelect = (address) => {
    // let address = JSON.parse(e.value);
    setState({
      ...state,
      ...{
        address: `${address?.value.BUILDING_NUMBER} ${
          address?.value.BUILDING_NAME ? address?.value.BUILDING_NAME + ',' : ''
        } ${address?.value.STREET_NAME}`,
        postcode: address?.value.POSTCODE2,
      },
    });
  };

  const options = postcodes.map((p) => {
    let address = JSON.parse(p.value);
    let fullAddress = `${address?.BUILDING_NUMBER} ${address?.BUILDING_NAME ? address?.BUILDING_NAME + ',' : ''} ${
      address?.STREET_NAME
    }`;
    return { value: address, label: fullAddress };
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      fontFamily: 'lato',
    }),
    menu: (provided, state) => ({
      ...provided,
      fontFamily: 'lato',
    }),
  };
  return (
    <>
      <ListCard
        localUrlImage={require('./../../assets/images/finance.png')}
        description={'Please add your details or details of the landlord for this property.'}
        title={'Landlord Details'}
      />

      <MarkAsComplete
        label={'Landlord details'}
        flowID={route?.params?.id}
        flowtype={route?.params?.key}
        isComplete={route?.params?.status === 2 ? true : false}
      />

      <Box>
        <RadioButton
          style={{ margin: 'auto' }}
          value={isDesignatedLandlord}
          options={['Yes', 'No']}
          onChange={(e) => {
            setIsDesignatedLandlord(e);
          }}
          title={'Are you the Landlord?'}
        />

        <If condition={isDesignatedLandlord}>
          {isDesignatedLandlord === 'No' ? (
            <>
              <Text fontSize={15} marginBottom={20} bold>
                Please enter the landlords details
              </Text>
            </>
          ) : (
            <>
              <Text fontSize={15} marginBottom={20} >
                You cannot edit any items below, please go to your account{" "}<Text
                  onPress={() => {
                    navigation.navigate('AccountEdit');
                  }}
                  color={'blue'}
                  fontSize={15}
                  marginBottom={20}
                  bold
                >
                  settings
                </Text>{" "}
                page, to edit your details.
              </Text>
            </>
          )}

          <TextInputComponent
            error={firstNameError}
            disabled={isDesignatedLandlord === 'Yes' ? true : false}
            onChangeText={(e) => _handleChange({ value: e, label: 'first_name' })}
            value={isDesignatedLandlord === 'No' ? desLandlord?.first_name : state?.first_name}
            height={40}
            title={'First name'}
          />

          <TextInputComponent
            error={lastError}
            disabled={isDesignatedLandlord === 'Yes' ? true : false}
            onChangeText={(e) => _handleChange({ value: e, label: 'last_name' })}
            value={isDesignatedLandlord === 'No' ? desLandlord?.last_name : state?.last_name}
            height={40}
            title={'Last name'}
          />
          {/* <View style={{ zIndex: 999, marginTop: 20, marginBottom: 15 }}>
        <RowCol>
          <TextInputWrapper>
            <TextInputComponent
              height={40}
              onChangeText={(e) => _handleChange({ value: e, label: 'postcode' })}
              placeholder={'Enter Postcode'}
              value={state?.postcode}
              error={state?.postcode ? false : '*Required'}
              title={'Search for the property address'}
            />
          </TextInputWrapper>
          <Button
            alignSelf={layoutType == 'phone' ? 'flex-end' : 'auto'}
            type={'stripe'}
            onPress={() => _onPostcodeSearch()}
            childText={'Search'}
            width={95}
          />
        </RowCol>
        {postcodes.length > 0 ? (
          <AddressSelect options={options} styles={customStyles} defaultValue={options.length > 0 ? options[0] : null}  onChange={(e) => _onAddressSelect(e)} isSearchable={true} />
        ) : null}
      </View>  */}
          <TextInputComponent
            error={addressError}
            disabled={isDesignatedLandlord === 'Yes' ? true : false}
            onChangeText={(e) => _handleChange({ value: e, label: 'address' })}
            value={isDesignatedLandlord === 'No' ? desLandlord?.address : state?.address}
            height={40}
            title={'Current Address'}
          />
          <View style={{ marginBottom: 20 }}>
            <TextInputComponent
              error={pinCodeError}
              disabled={isDesignatedLandlord === 'Yes' ? true : false}
              onChangeText={(e) => _handleChange({ value: e, label: 'postcode' })}
              value={isDesignatedLandlord === 'No' ? desLandlord?.postcode : state?.postcode}
              height={40}
              title={'Postcode'}
            />
          </View>
          <TextInputComponent
            disabled={isDesignatedLandlord === 'Yes' ? true : false}
            onChangeText={(e) => _handleChange({ value: e, label: 'contact_number' })}
            value={isDesignatedLandlord === 'No' ? desLandlord?.contact_number : state?.contact_number}
            height={40}
            title={'Contact number'}
          />

          <TextInputComponent
            error={emailError}
            disabled={isDesignatedLandlord === 'Yes' ? true : false}
            onChangeText={(e) => _handleChange({ value: e, label: 'email' })}
            value={isDesignatedLandlord === 'No' ? desLandlord?.email : state?.email}
            height={40}
            title={'Email address'}
          />
        </If>
      </Box>

      <Button
        onPress={() => {
          isDesignatedLandlord === 'No' ? _validation() : _onSave();
        }}
        childText={'Save and Continue'}
        width={150}
        style={{ alignSelf: 'flex-end', marginVertical: 30 }}
      />
    </>
  );
};

export default drawerHoc({
  Component: LandlordDetails,
  showBackButton: true,
});
