import { useIsFocused, useRoute } from '@react-navigation/native';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import Box from '../../components/box';
import Button from '../../components/button/Button';
import DocumentView from '../../components/documentViewer';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import ListCard from '../../components/listCard';
import MarkAsComplete from '../../components/markAsComplete/MarkAsComplete';
import RadioButton from '../../components/radioButton';
import Text from '../../components/text';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import ajax from '../../helpers/ajaxHelper';
import getPlatformType from '../../helpers/helpers';
import Urls from '../../services/Urls';
import { Row, RowCol, TextInputWrapper } from './index.style';
import FilePicker from '../../components/filePicker/filepicker.web';
import { showErrorToast, showSuccesToast } from '../tenants/tenantOnboarding/helpers';

var layoutType = getPlatformType();

const Certifications = ({ navigation }) => {
  const focus = useIsFocused()
  const [gasCertificate, setGas] = useState('No');
  const [epcCertificate, setEPC] = useState('No');
  const [eicrCertificate, setEICR] = useState('No');
  const [state, setState] = useState({
    gas_certificate: [],
    gas_certificate_expiry: moment().format('MM/DD/YYYY'),
    epc_certificate: [],
    epc_certificate_expiry: moment().format('MM/DD/YYYY'),
    eicr_certificate: [],
    eicr_certificate_expiry: moment().format('MM/DD/YYYY'),
    other_certificate: [],
    other_certificate_expiry: moment().format('MM/DD/YYYY'),
    other_certificate_title: '',
  });
  const route = useRoute();
  useEffect(() => {
    if (route?.params?.id) {
      _getCertifactes(route?.params?.id);
    }
  }, [focus]);

  const _getCertifactes = (id) => {
    ajax
      .get(Urls.Certificates + '/' + id)
      .then(({ data }) => {
        if (data.status) {
          setState({
            ...state,
            ...{
              gas_certificate: data?.data?.gas_certificate?.files?.length > 0 ? data?.data?.gas_certificate?.files : [],
              gas_certificate_expiry: data?.data?.gas_certificate?.expiry_date
                ? data?.data?.gas_certificate?.expiry_date
                : moment().format('MM/DD/YYYY'),
              epc_certificate: data?.data?.epc_certificate?.files?.length > 0 ? data?.data?.epc_certificate?.files : [],
              epc_certificate_expiry: data?.data?.epc_certificate?.expiry_date
                ? data?.data?.epc_certificate?.expiry_date
                : moment().format('MM/DD/YYYY'),
              eicr_certificate:
                data?.data?.eicr_certificate?.files?.length > 0 ? data?.data?.eicr_certificate?.files : [],
              eicr_certificate_expiry: data?.data?.eicr_certificate?.expiry_date
                ? data?.data?.eicr_certificate?.expiry_date
                : moment().format('MM/DD/YYYY'),
              other_certificate:
                data?.data?.other_certificate?.files?.length > 0 ? data?.data?.other_certificate?.files : [],
              other_certificate_expiry: data?.data?.other_certificate?.expiry_date
                ? data?.data?.other_certificate?.expiry_date
                : moment().format('MM/DD/YYYY'),
              other_certificate_title: data?.data?.other_certificate?.title,
            },
          });

          setGas(data?.data?.gas_certificate?.status === 'Yes' ? 'Yes' : 'No');
          setEPC(data?.data?.epc_certificate?.status === 'Yes' ? 'Yes' : 'No');
          setEICR(data?.data?.eicr_certificate?.status === 'Yes' ? 'Yes' : 'No');

        
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onSave = (navigatePage = true) => {
    const requestBody = {
      gas_certificate: {
        name: 'gas_certificate',
        files: state?.gas_certificate,
        expiry_date: state?.gas_certificate_expiry,
        status: gasCertificate,
      },
      epc_certificate: {
        name: 'epc_certificate',
        files: state?.epc_certificate,
        expiry_date: state?.epc_certificate_expiry,
        status: epcCertificate,
      },
      eicr_certificate: {
        name: 'eicr_certificate',
        files: state?.eicr_certificate,
        expiry_date: state?.eicr_certificate_expiry,
        status: eicrCertificate,
      },
      other_certificate: {
        name: 'other_certificate',
        files: state?.other_certificate,
        title: state.title,
        expiry_date: state?.other_certificate_expiry,
      },
    };
    ajax
      .post(Urls.Certificates + '/' + route?.params?.id, requestBody)
      .then(({ data }) => {
        if (data.status) {     
          // showSuccesToast(data?.data)   
          if (navigatePage) {
            navigation.navigate('PropertySection', { id: route?.params?.id });
          }else{
            showSuccesToast('Certification archived successfully!');
          }
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const _onAddCertifcate = (value, tag) => {
    let obj = {
      name: tag,
      document_url: value[0]?.openbrixName,
      file_name: tag == 'other_certificate' ? state?.other_certificate_title : value[0]?.name,
    };
    console.log('Obj', obj);
    setState({ ...state, [tag]: [...state[tag], obj] , other_certificate_title: ''});

  };
  const moveToDocs =(data)=>{
    ajax
    .post(Urls.MoveToDocs + '/' + route?.params?.id, data)
    .then(({ data }) => {
      if (data.status) {
        showSuccesToast('Document moved to myDocs section!');
        // _getCertifactes(route?.params?.id);
      } else {
        showErrorToast('Something went wrong!');
      }
    })
    .catch((error) => {
      showErrorToast('Something went wrong!');
    });
  }
  const _onRemove = (obj, tag, i,ismoveToDocs) => {
    let arr = state[tag];
    if(ismoveToDocs){
    moveToDocs(arr[i])
    arr.splice(i, 1);
    setState({ ...state, [tag]: arr });
    }else{
      arr[i].is_deleted = true;
      setState({ ...state, [tag]: arr });
      _onSave(false);
    }
   
  };
  return (
    <>
      <ListCard
        leftIcon={'file-certificate'}
        description={'Please add the compliance certificates for this property.'}
        title={'Certification'}
        showPropertyCard={route.params?.name}
      />
      <Text fontWeight={600} marginTop={15} marginBottom={10} fontSize={18} marginLeft={10}>
        Certificate
      </Text>

      <MarkAsComplete
        label={'Certification requirements'}
        flowID={route?.params?.id}
        flowtype={route?.params?.key}
        isComplete={route?.params?.status === 2 ? true : false}
      />
      <Box>
        <RadioButton
          value={gasCertificate}
          onChange={(e) => {
            if (e == 'No') {
              setState({
                ...state,
                ...{
                  gas_certificate: [],
                  gas_certificate_expiry: '',
                },
              });
            }
            setGas(e);
          }}
          title={'Do you have an up to date Gas Safety Certificate?'}
        />
        {gasCertificate == 'Yes' ? (
          <>
            <Row
              style={{
                marginTop: 20,
                width: layoutType == 'phone' ? '100%' : '80%',
                flexDirection: layoutType == 'phone' ? 'coloumn' : 'row',
                justifyContent: 'space-between',
                alignItems: layoutType == 'phone' ? 'baseline' : 'center',
              }}
            >
              <View>
                <TextInputComponent
                  onChangeText={(e) => setState({ ...state, gas_certificate_expiry: e })}
                  title={'Expire Date'}
                  isDate
                  date={state?.gas_certificate_expiry}
                ></TextInputComponent>
              </View>
              <View>
                <Text fontSize={15} bold marginBottom={10}>
                  Upload Documents
                </Text>
                <FilePicker
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => {
                    _onAddCertifcate(data, 'gas_certificate')}
                  }
                />
              </View>
            </Row>
            {state?.gas_certificate &&
              state?.gas_certificate.map((e, i) => {
                if (e?.is_deleted) {
                  return null;
                } else {
                  return (
                    <DocumentView
                      title={e?.file_name}
                      url={e?.document_url}
                      onDelete={(moveToDocs) => _onRemove(e, 'gas_certificate', i,moveToDocs)}
                    />
                  );
                }
              })}
          </>
        ) : null}
      </Box>
      <Box style={{ marginTop: 15, marginBottom: 15 }}>
        <RadioButton
          value={epcCertificate}
          onChange={(e) => {
            if (e == 'No') {
              setState({
                ...state,
                ...{
                  epc_certificate_expiry: '',
                  epc_certificate: [],
                },
              });
            }
            setEPC(e);
          }}
          title={'Do you have an up to date Energy Performance Certificate (EPC)?'}
        />
        {epcCertificate == 'Yes' ? (
          <>
            <Row
              style={{
                marginTop: 20,
                width: layoutType == 'phone' ? '100%' : '80%',
                justifyContent: 'space-between',
                flexDirection: layoutType == 'phone' ? 'coloumn' : 'row',
                alignItems: layoutType == 'phone' ? 'baseline' : 'center',
              }}
            >
              <TextInputComponent
                onChangeText={(e) => setState({ ...state, epc_certificate_expiry: e })}
                title={'Expire Date'}
                isDate
                date={state?.epc_certificate_expiry}
              ></TextInputComponent>
              <View>
                <Text fontSize={15} bold marginBottom={10}>
                  Upload Documents
                </Text>
                {/* <Button
                  onDataSuccess={(data) => _onAddCertifcate(data, 'epc_certificate')}
                  type={'upload'}
                  childText={'Upload'}
                /> */}
                <FilePicker
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => _onAddCertifcate(data, 'epc_certificate')}
                />
              </View>
            </Row>
            {state?.epc_certificate &&
              state?.epc_certificate.map((e, i) => {
                if (e?.is_deleted) {
                  return null;
                } else {
                  return (
                    <DocumentView
                      title={e?.file_name}
                      url={e?.document_url}
                      onDelete={(moveToDocs) => _onRemove(e, 'epc_certificate', i,moveToDocs)}
                    />
                  );
                }
              })}
          </>
        ) : null}
      </Box>
      <Box style={{ marginBottom: 15 }}>
        <RadioButton
          value={eicrCertificate}
          onChange={(e) => {
            if (e == 'No') {
              setState({
                ...state,
                ...{
                  eicr_certificate: [],
                  eicr_certificate_expiry: '',
                },
              });
            }
            setEICR(e);
          }}
          title={'Do you have an up to date Electrial Inspection Condition Report (EICR)?'}
        />
        {eicrCertificate == 'Yes' ? (
          <>
            <Row
              style={{
                marginTop: 20,
                width: layoutType == 'phone' ? '100%' : '80%',
                justifyContent: 'space-between',
                flexDirection: layoutType == 'phone' ? 'coloumn' : 'row',
                alignItems: layoutType == 'phone' ? 'baseline' : 'center',
              }}
            >
              <TextInputComponent
                onChangeText={(e) => setState({ ...state, eicr_certificate_expiry: e })}
                title={'Expire Date'}
                isDate
                date={state?.eicr_certificate_expiry}
              ></TextInputComponent>
              <View>
                <Text fontSize={15} bold marginBottom={10}>
                  Upload Documents
                </Text>
                <FilePicker
                  style={{ marginBottom: 15, justifyContent: 'space-between' }}
                  onDataSuccess={(data) => _onAddCertifcate(data, 'eicr_certificate')}
                />
              </View>
            </Row>
            {state?.eicr_certificate &&
              state?.eicr_certificate.map((e, i) => {
                if (e?.is_deleted) {
                  return null;
                } else {
                  return (
                    <DocumentView
                      title={e?.file_name}
                      url={e?.document_url}
                      onDelete={(moveToDocs) => _onRemove(e, 'eicr_certificate', i,moveToDocs)}
                    />
                  );
                }
              })}
          </>
        ) : null}
      </Box>
      <Text fontWeight={600} marginTop={10} marginBottom={10} fontSize={18} marginLeft={10}>
        Other Certificate
      </Text>
      <Box>
        <RowCol>
          <TextInputWrapper width={'100%'}>
            <TextInputComponent
              onChangeText={(e) => setState({ ...state, other_certificate_title: e, title: e })}
              height={40}
              title={'Certificate title'}
              value={state?.other_certificate_title}
            />
          </TextInputWrapper>
        </RowCol>
        <Row
          style={{
            marginTop: 20,
            width: layoutType == 'phone' ? '100%' : '80%',
            justifyContent: 'space-between',
            flexDirection: layoutType == 'phone' ? 'coloumn' : 'row',
            alignItems: layoutType == 'phone' ? 'baseline' : 'center',
          }}
        >
          <TextInputComponent
            width={'100%'}
            title={'Expire Date'}
            onChangeText={(e) => setState({ ...state, other_certificate_expiry: e })}
            isDate
            date={state?.other_certificate_expiry}
          ></TextInputComponent>
          <View>
            <Text fontSize={15} bold marginBottom={10}>
              Upload Documents
            </Text>
            <FilePicker
              style={{ marginBottom: 15, justifyContent: 'space-between' }}
              onDataSuccess={(data) => _onAddCertifcate(data, 'other_certificate')}
            />
          </View>
        </Row>
        {state?.other_certificate &&
          state?.other_certificate.map((e, i) => {
            if (e?.is_deleted) {
              return null;
            } else {
              return (
                <DocumentView
                  title={e?.file_name}
                  url={e?.document_url}
                  onDelete={(moveToDocs) => _onRemove(e, 'other_certificate', i,moveToDocs)}
                />
              );
            }
          })}
      </Box>
      <Button
        onPress={() => _onSave()}
        childText={'Save and Continue'}
        width={150}
        style={{ alignSelf: 'flex-end', marginVertical: 30 }}
      />
    </>
  );
};

export default drawerHoc({
  Component: Certifications,
  showBackButton: true,
});
