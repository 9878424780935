import { Dimensions } from "react-native";
const { width } = Dimensions.get("window");

function getPlatformType() {
  if (width <= 767) {
    return "phone";
  } else if (width >= 768 && width <= 1224) {
    return "tab";
  } else if (width >= 1224) {
    return "web";
  }
}
export default getPlatformType;