import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import Box from '../box';
import Text from '../text';
import getPlatformType from '../../helpers/helpers';
import {
  StatusBtn,
  SecondaryText,
  PrimaryText,
  CardWrapper,
  LineSaperator,
  RightWrapper,
  LeftWrapper,
  LeftWrapper2,
} from './CalendarList.style';
import moment from 'moment';
import { MaterialIcons } from '@expo/vector-icons';
import { navigate } from '../../navigation/RootNavigation';

const CalendarList = ({ navigation, eventList }) => {
  const [calendarData, setCalendarData] = useState([]);

  const [calendarDetailsShow, setcalendarDetailsShow] = useState(true);

  const handleCalendarIconChange = () => {
    setcalendarDetailsShow(!calendarDetailsShow);
  };

  useEffect(() => {
    if (eventList && eventList?.length) {
      setCalendarData(eventList);
    } else {
      setCalendarData([]);
    }
  }, [eventList]);

  const renderlist = (data, index) => {
    return (
      <React.Fragment key={data.id}>
        <LineSaperator />
        <CardWrapper>
          <LeftWrapper
            onPress={() => {
              let temp = [...calendarData];
              temp[index] = {
                ...temp[index],
                isOpen: !temp[index].isOpen,
              };
              setCalendarData(temp);
            }}
          >
            <LeftWrapper2>
              <PrimaryText>{data.title}</PrimaryText>
              <MaterialIcons
                name={data?.isOpen ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
                size={24}
                color="black"
              />
            </LeftWrapper2>
            <SecondaryText nopadding={true} isPrimary={data?.isPrimary} marginTop={8} isPrimary={data?.isPrimary}>
              Expiry {moment(data?.date).format('DD/MM/YYYY')}
            </SecondaryText>
          </LeftWrapper>
          <RightWrapper>
            <StatusBtn
              onPress={() => navigate('EditReminder', { id: data.id, type: data.type })}
              isPrimary={data?.isPrimary}
            >
              <SecondaryText isPrimary={data?.isPrimary}>{data.btnText ? data.btnText : 'View'}</SecondaryText>
            </StatusBtn>
          </RightWrapper>
        </CardWrapper>
        {/* {data?.isOpen && (
          <Box style={{ marginBottom: 30 }} backgroundColor={'#BAC9F4'}>
            <Text style={{ color: '#6D08C0' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
            </Text>
          </Box>
        )} */}
      </React.Fragment>
    );
  };

  return (
    <>
      {calendarData?.length ? (
        calendarData.map((data, index) => renderlist(data, index))
      ) : (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginHorizontal: 10,
          }}
        >
          <PrimaryText >No reminders this month!</PrimaryText>

          <StatusBtn onPress={() => navigate('AddReminder', { type: 'other' })} isPrimary={true}>
            <SecondaryText isPrimary={true}>{'Add'}</SecondaryText>
          </StatusBtn>
        </View>
      )}
    </>
  );
};

export default CalendarList;
