import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { tenantActions } from '_actions';
import { Button } from '../atoms/button';
import CounterInput from '../atoms/counterInput';
import { ModalHeader } from '../atoms/modal/style';
import { TextInput } from '../atoms/textinput';
import variables from '../atoms/variables.json';
import {
  getApplicantVluesforEICR,
  getApplicantVluesforEPC,
  getApplicantVluesforGas,
  getApplicantVluesforPat,
} from './commanModalHelpers';
import { RowContainer, SubHeading } from './style';
let initialValues = {
  gas_type: 'Please Select',
  key_pick_up: 'Please Select',
  key_drop_off: 'Please Select',
  Furnishing: 'Fully Furnished',
};
const CommanModal = (props) => {
  const dispatch = useDispatch();
  const [flowId, setFlowId] = React.useState(null);
  const [inventoryCost, setInventoryCost] = React.useState(null);
  const [checkInCost, setCheckInCost] = React.useState(null);

  const { initialCost, minRange, type, flowID, isBuyed, onSubmitted } = props;
  const [totalCost, setTotalCost] = React.useState(initialCost);
  const address = useSelector((state) => state?.users?.user_profile?.agent?.branch?.branch_address);
  const postCode = useSelector((state) => state?.users?.user_profile?.agent?.branch?.postcode);
  const agentContact = useSelector((state) => state?.users?.user_profile?.agent?.branch?.contact_number);
  const agentEmail = useSelector((state) => state?.users?.user_profile?.agent?.branch?.email);
  const [counterValue, setCounterValue] = React.useState(minRange);
  const [counterValueType, setCounterValueType] = React.useState(null);

  const [bed, setBed] = React.useState(1);
  const [ispoa, setIsPoa] = React.useState(false);

  const [fieldsValue, setFieldValues] = React.useState(initialValues);
  const [confirmDateCheckbox, setConfirmDateCheckbox] = React.useState(false);
  const [notifyDateCheckbox, setNotifyDateCheckbox] = React.useState(false);
  const getType = () => {
    if (inventoryCost && checkInCost) {
      return 'invandcheckin';
    } else if (inventoryCost) {
      return 'inventory';
    } else if (checkInCost) {
      return 'checkin';
    }
  };
  const onsubmit = () => {
    let data = {
      ...fieldsValue,
      notes: fieldsValue?.notes + '/nappliances:' + counterValue + '/ngas_type:' + fieldsValue?.gas_type,
      totalCost,
      confirmDateCheckbox,
      notifyDateCheckbox,
      number_of_bedrooms: bed,
      ispoa: ispoa,
      price: ispoa ? 'POA' : totalCost,
    };

    if (type === 'inventory') {
      data = {
        ...fieldsValue,
        notes: fieldsValue.notes + '/nappliances:' + counterValue,
        totalCost,
        confirmDateCheckbox,
        notifyDateCheckbox,
        number_of_bedrooms: bed,
      };
      // dispatch(
      //   tenantActions.buyCer(getType(), data, flowID, (data) => {
      //     if (data) {
      //       if (props.onSubmitted) {
      //         props.onSubmitted(false);
      //       }
      //     }
      //   })
      // );
    } else {
      // dispatch(
      //   tenantActions.buyCer(type, data, flowID, (data) => {
      //     console.log('datadata inside onsubmit', data);
      //     if (data) {
      //       if (props.onSubmitted) {
      //         props.onSubmitted(false);
      //       }
      //     }
      //   })
      // );
    }
  };
  const handleChange = (e, name) => {
    setFieldValues({ ...fieldsValue, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (fieldsValue?.gas_type === 'LPG') {
      setTotalCost(totalCost + 20);
    } else if (fieldsValue?.gas_type === 'Natural') {
      if (totalCost !== 65) setTotalCost(totalCost - 20);
    }
  }, [fieldsValue?.gas_type]);

  useEffect(() => {
    if (fieldsValue?.key_pick_up === 'Collect from Agent') {
      setFieldValues({
        ...fieldsValue,
        key_pick_up_address: address + ',' + postCode,
      });
    } else {
      setFieldValues({ ...fieldsValue, key_pick_up_address: '' });
    }
  }, [fieldsValue?.key_pick_up]);
  useEffect(() => {
    if (fieldsValue?.key_drop_off === 'Return to Agent') {
      setFieldValues({
        ...fieldsValue,
        key_drop_off_address: address + ',' + postCode,
      });
    } else {
      setFieldValues({ ...fieldsValue, key_drop_off_address: '' });
    }
  }, [fieldsValue?.key_drop_off]);

  useEffect(() => {
    if (agentContact || agentEmail) {
      setFieldValues({
        ...fieldsValue,
        agent_contact: agentContact,
        agent_email: agentEmail,
      });
    }
  }, [agentContact, agentEmail]);

  const renderInventaryHeader = () => {
    return (
      <React.Fragment>
        <SubHeading>Using our specialist service you can arrange check in and inventory services</SubHeading>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '30px',
            marginBottom: '20px',
          }}
        >
          <SubHeading>Inventory Cost:</SubHeading>
          <div
            style={{
              height: '50px',
              width: 100,
              backgroundColor: 'white',
              alignItems: 'center',
              paddingLeft: '10px',
              paddingRight: '10px',
              border: '2px solid #ebebeb',
              borderRadius: '6px',
              padding: '5px',
            }}
          >
            <ModalHeader>TBC</ModalHeader>
          </div>
          <Checkbox
            name="inventory cost:"
            style={{ color: variables.heliotrope }}
            checked={inventoryCost}
            onChange={() => setInventoryCost(!inventoryCost)}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <SubHeading>Check in Cost:</SubHeading>
          <div
            style={{
              height: '50px',
              padding: '5px',
              width: 100,
              paddingLeft: '10px',
              alignItems: 'center',
              paddingRight: '10px',
              backgroundColor: 'white',
              border: '2px solid #ebebeb',
              borderRadius: '6px',
            }}
          >
            <ModalHeader>TBC</ModalHeader>
          </div>
          <Checkbox
            name="Check in Cost:"
            style={{ color: variables.heliotrope }}
            checked={checkInCost}
            onChange={(e) => setCheckInCost(!checkInCost)}
          />
        </div>
        <div style={{ marginTop: '30px', marginBottom: '30px' }}>
          <TextInput
            title={'Inventory Check Date'}
            type={'date'}
            disabled={!inventoryCost}
            onChange={handleChange}
            name="inventory_date"
            inputProps={{ min: moment().format('DD-MM-YYYY') }}
            value={fieldsValue.inventory_date}
            wrapperstyle={{
              display: 'flex',
              marginTop: '50px',
              width: '80%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          />
          <TextInput
            title={'Check In Date'}
            type={'date'}
            disabled={!checkInCost}
            value={fieldsValue.check_in_date}
            inputProps={{ min: moment().format('DD-MM-YYYY') }}
            onChange={handleChange}
            name="check_in_date"
            wrapperstyle={{
              display: 'flex',
              marginTop: '15px',
              width: '80%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          />

          <TextInput
            title={
              <>
                <strong>Contact No:</strong>
              </>
            }
            type={'row'}
            wrapperstyle={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '90%',
              marginTop: 20,
              marginBottom: 20,
            }}
            inputTextStyle={{ marginBottom: 0 }}
            containerstyles={{ width: '70%' }}
            value={fieldsValue?.contact}
            name="contact"
            onChange={handleChange}
          />
        </div>
        {renderBed()}
        <div style={{ display: 'flex' }}>
          <SubHeading style={{ marginRight: '100px' }}>Additional Areas:</SubHeading>
          <CounterInput
            minValue={props?.minRange}
            value={counterValue}
            handleCounterChange={(val, type) => {
              setCounterValue(val);
              setCounterValueType(type);
            }}
            isRounder={true}
          />
        </div>
      </React.Fragment>
    );
  };
  const renderBed = () => {
    return (
      <RowContainer>
        <p style={{ marginTop: 20 }}>
          <strong>Number of Bed Rooms:</strong>
        </p>
        <div style={{ paddingLeft: '10%', marginTop: 15 }}>
          <CounterInput
            value={bed}
            minValue={1}
            handleCounterChange={(val, counterType) => {
              setBed(val);
              if (type === 'epc') {
                if (getApplicantVluesforEPC(val, totalCost, counterType) == 'POA') {
                  setIsPoa(true);
                } else {
                  setIsPoa(false);
                  setTotalCost(getApplicantVluesforEPC(val, totalCost, counterType));
                }
              }
              if (type === 'eicr') {
                if (getApplicantVluesforEICR(val, totalCost, counterType) == 'POA') {
                  setIsPoa(true);
                } else {
                  setTotalCost(getApplicantVluesforEICR(val, totalCost, counterType));
                  setIsPoa(false);
                }
              }
            }}
            isRounder={true}
          />
        </div>
      </RowContainer>
    );
  };
  return (
    <div>
      {type === 'inventory' ? (
        renderInventaryHeader()
      ) : (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
            }}
          >
            <TextInput
              title={
                <>
                  <strong>Cost: </strong>
                </>
              }
              type={'row'}
              wrapperstyle={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '40%',
                marginTop: 20,
                marginRight: 10,
              }}
              value={ispoa ? 'POA' : '£' + totalCost}
              inputTextStyle={{ marginBottom: 0 }}
              containerstyles={{ width: '60%' }}
              name="cost"
            />
            +VAT
          </div>
          {type === 'epc' || type === 'eicr' ? null : (
            <React.Fragment>
              <p style={{ marginTop: 20 }}>
                <strong>Number of appliances:</strong>
                <br /> eg boiler, boiler and cooker, boiler, hob and fire
              </p>
              <div style={{ paddingLeft: '10%' }}>
                <CounterInput
                  value={counterValue}
                  minValue={props?.minRange}
                  handleCounterChange={(val, counterType) => {
                    setCounterValue(val);
                    if (type === 'gas') {
                      if (getApplicantVluesforGas(val, totalCost, counterType) == 'POA') {
                        setIsPoa(true);
                      } else {
                        setTotalCost(getApplicantVluesforGas(val, totalCost, counterType));
                        setIsPoa(false);
                      }
                    }

                    if (type === 'pat') {
                      if (getApplicantVluesforPat(val, totalCost, counterType) == 'POA') {
                        setIsPoa(true);
                      } else {
                        setTotalCost(getApplicantVluesforPat(val, totalCost, counterType));
                        setIsPoa(false);
                      }
                    }
                  }}
                  isRounder={true}
                />
              </div>
            </React.Fragment>
          )}
          {renderBed()}
          {type === 'gas' && (
            <>
              <div style={{ width: '70%', marginTop: 20 }}>
                <strong>Gas Type </strong>
                <br />
                eg Natural gas, LPG or Oil
              </div>
              <Select
                value={fieldsValue?.gas_type}
                style={{
                  width: '50%',
                  height: '40px',
                  borderWidth: 0,
                  marginTop: '5%',
                }}
                onChange={handleChange}
                variant="outlined"
                name="gas_type"
                placeholder="Select Gas Type"
              >
                <MenuItem value={'Please Select'}>Please Select</MenuItem>
                <MenuItem value={'Natural'}>Natural</MenuItem>
                <MenuItem value={'LPG'}>LPG + £20</MenuItem>
              </Select>
            </>
          )}
          <TextInput
            title={
              <>
                <strong>Conduct Date:</strong>
              </>
            }
            type={'date'}
            wrapperstyle={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '90%',
              marginTop: 20,
              marginBottom: 20,
            }}
            inputTextStyle={{ marginBottom: 0 }}
            containerstyles={{ width: '70%' }}
            value={fieldsValue?.conduct_date}
            name="conduct_date"
            onChange={handleChange}
          />
          <div style={{ fontWeight: 'bold', marginTop: 20 }}>Access Details:</div>
          <TextInput
            title={
              <>
                <strong>Name:</strong>
              </>
            }
            type={'row'}
            wrapperstyle={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '90%',
              marginTop: 10,
            }}
            inputTextStyle={{ marginBottom: 0 }}
            containerstyles={{ width: '70%' }}
            value={fieldsValue?.name}
            name="name"
            onChange={handleChange}
          />
          <TextInput
            title={
              <>
                <strong>Contact No:</strong>
              </>
            }
            type={'row'}
            wrapperstyle={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '90%',
              marginTop: 20,
              marginBottom: 20,
            }}
            inputTextStyle={{ marginBottom: 0 }}
            containerstyles={{ width: '70%' }}
            value={fieldsValue?.contact}
            name="contact"
            onChange={handleChange}
          />
        </React.Fragment>
      )}
      <FormControl
        style={{
          marginTop: '5%',
          marginBottom: '5%',
          flexDirection: 'row',
          display: 'flex',
        }}
      >
        <InputLabel>Furnishing</InputLabel>
        <Select
          value={fieldsValue?.Furnishing}
          title={'Furnishing'}
          style={{
            width: '50%',
            height: '40px',
            borderWidth: 0,
            marginTop: '5%',
          }}
          onChange={handleChange}
          variant="outlined"
          name="Furnishing"
          placeholder="Furnishing"
        >
          <MenuItem value={'Fully Furnished'}>Furnished</MenuItem>
          <MenuItem value={'Part Furnished'}>Part Furnished</MenuItem>
          <MenuItem value={'Collect from Agent'}>UnFurnished</MenuItem>
        </Select>
      </FormControl>

      <div style={{ fontWeight: 'bold' }}>Key Pick Up Information</div>
      <div>Where are the keys to be picked up?</div>

      <React.Fragment>
        <FormControl style={{ marginTop: '5%', flexDirection: 'row', display: 'flex' }}>
          <InputLabel>Key Pick Up</InputLabel>
          <Select
            value={fieldsValue?.key_pick_up}
            style={{
              width: '50%',
              height: '40px',
              borderWidth: 0,
              marginTop: '5%',
            }}
            onChange={handleChange}
            variant="outlined"
            name="key_pick_up"
            placeholder="key_pick_up"
          >
            <MenuItem value={'Please Select'}>Please Select</MenuItem>
            <MenuItem value={'Occupied - Knock/Ring Door'}>Occupied - Knock/Ring Door</MenuItem>
            <MenuItem value={'Collect from Agent'}>Collect from Agent</MenuItem>
            <MenuItem value={'Collect from Neighbour'}>Collect from Neighbour</MenuItem>
            <MenuItem value={'Collect from Lockbox'}>Collect from Lockbox</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </Select>
        </FormControl>
        <TextInput
          onChange={handleChange}
          containerstyles={{ height: '100px' }}
          multiline
          value={fieldsValue?.key_pick_up_address}
          name="key_pick_up_address"
          wrapperstyle={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            marginTop: '20px',
          }}
          inputStyle={{
            height: '100%',
          }}
        />
        <FormControl style={{ marginTop: '5%', flexDirection: 'row', display: 'flex' }}>
          <InputLabel>Key Drop off</InputLabel>
          <Select
            value={fieldsValue?.key_drop_off}
            style={{
              width: '50%',
              height: '40px',
              borderWidth: 0,
              marginTop: '5%',
            }}
            onChange={handleChange}
            variant="outlined"
            name="key_drop_off"
            placeholder="key_drop_off"
          >
            <MenuItem value={'Please Select'}>Please Select</MenuItem>
            <MenuItem value={'Not Required'}>Not Required</MenuItem>

            <MenuItem value={'Return to Agent'}>Return to Agent</MenuItem>
            <MenuItem value={'Post through letterbox'}>Post through letterbox</MenuItem>
            <MenuItem value={'Return to Neighbour'}>Return to Neighbour</MenuItem>
            <MenuItem value={'Return to Lockbox'}>Return to Lockbox</MenuItem>
            <MenuItem value={'Retain until further instruction'}>Retain until further instruction</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </Select>
        </FormControl>
        <TextInput
          onChange={handleChange}
          containerstyles={{ height: '100px' }}
          multiline
          value={fieldsValue?.key_drop_off_address}
          inputStyle={{
            height: '100%',
          }}
          name="key_drop_off_address"
          wrapperstyle={{
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            marginTop: '20px',
          }}
        />
        <div style={{ fontWeight: 'bold', marginTop: '3%' }}>Agent Details</div>
        <TextInput
          title={'Agent contact'}
          onChange={handleChange}
          containerstyles={{ height: '100%' }}
          multiline
          value={fieldsValue?.agent_contact}
          name="agent_contact"
          inputStyle={{
            height: '100%',
            width: '100%',
          }}
          wrapperstyle={{
            display: 'flex',
            width: '80%',
            alignItems: 'center',
            marginTop: '20px',
            height: '100%',
            justifyContent: 'space-between',
          }}
        />
        <TextInput
          title={'Agent Email'}
          onChange={handleChange}
          containerstyles={{ height: '100%' }}
          multiline
          value={fieldsValue?.agent_email}
          name="agent_email"
          inputStyle={{
            height: '100%',
            width: '100%',
          }}
          wrapperstyle={{
            display: 'flex',
            width: '80%',
            alignItems: 'center',
            marginTop: '20px',
            height: '100%',
            justifyContent: 'space-between',
          }}
        />
        <TextInput
          title={'Notes'}
          onChange={handleChange}
          containerstyles={{ height: '100%' }}
          multiline
          name="notes"
          inputStyle={{
            height: '100%',
            width: '100%',
          }}
          wrapperstyle={{
            display: 'flex',
            width: '80%',
            alignItems: 'center',
            marginTop: '20px',
            height: '100%',
            justifyContent: 'space-between',
          }}
        />
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <SubHeading style={{ width: '70%' }}>Confirm Date and visit with tenants</SubHeading>
          <Checkbox
            style={{ width: '30%' }}
            name="confirm_date"
            style={{ color: variables.heliotrope }}
            checked={confirmDateCheckbox}
            onChange={() => setConfirmDateCheckbox(!confirmDateCheckbox)}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <SubHeading style={{ width: '70%' }}>Notify Date and visit with tenants</SubHeading>
          <Checkbox
            style={{ width: '30%' }}
            name="notify_date"
            style={{ color: variables.heliotrope }}
            checked={notifyDateCheckbox}
            onChange={() => setNotifyDateCheckbox(!notifyDateCheckbox)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => {
              onSubmitted();
            }}
            style={{
              marginTop: '40px',
              borderColor: variables.red,
              color: variables.red,
            }}
            title={'Cancel'}
            type="stripe"
          />
          <Button
            onClick={() => {
              onsubmit();
            }}
            style={{
              marginTop: '40px',
              borderColor: variables.puregreen,
              color: variables.puregreen,
            }}
            title={'Submit'}
            type="stripe"
          />
        </div>
      </React.Fragment>
    </div>
  );
};
export default CommanModal;
