import React, { useState } from 'react';
import {
  Container,
  FormContainer,
  ImageWrapper,
  ImageLogoWrapper,
  LeftSection,
  RightSection,
  TopSection,
  Main,
  TermsLinks
} from './register.style';
import Text from '../../components/text';
import Colors from '../../constants/Colors';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import Button from '../../components/button/Button';
import rightImg from '../../assets/images/forgotImage.png';
import WebImage from '../../assets/images/Logo/HubitaWeb.png';
import { GradientOverlay } from '../login/login.style';

import { View } from 'react-native';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import {
  EmailValidation,
  MobileValidation,
  NameValidation,
  PasswordComparisonValidation,
  PasswordValidation,
} from '../../helpers/validation';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import getPlatformType from '../../helpers/helpers';
import CheckBox from '../../components/checkbox';
import { Link } from '@react-navigation/native';

const RegisterScreen = ({ navigation }) => {
  const layoutType = getPlatformType();
  let LogoImg = WebImage;

  const [first_name, setFirstName] = useState('');
  const [firstNameError, setFSError] = useState('');
  const [last_name, setLastName] = useState('');
  // const [lastNameError, setLSError] = useState('');
  const [contact_no, setContact] = useState('');
  // const [contactError, setContactError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passError, setPassError] = useState('');
  const [confirm_pass, setConfirmPass] = useState('');
  const [confirmPassError, setConfirmPassError] = useState('');
  const [tncStatus, setTncStatus] = useState(false);
  const [contactStatus, setContactStatus] = useState(false);
  const [tncStatusError, setTncStatusError] = useState(false);
  const [contactStatusError, setContactStatusError] = useState(false);

  const terms = (
    <>
      I accept Hubita <TermsLinks>
      <Link to="https://dev.hubita.co.uk/legal-bits" target="_blank">
        T&Cs{" "}
      </Link>
      </TermsLinks>{" "}
      and <TermsLinks>
      <Link to="https://dev.hubita.co.uk/legal-bits" target="_blank">
        privacy policy
      </Link>
      </TermsLinks>
    </>
  );

  const _callRegistrationAPI = async () => {
    const requestBody = {
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name,
      contact_number: contact_no,
    };

    ajax
      .post(Urls.RegistrationAPI, requestBody)
      .then(({ data }) => {
        if (data.status) {
          alert('Your account created successfully, please login');
          navigation.navigate('login');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const _onRegister = () => {
    let FSValidate = NameValidation(first_name);
    // let LSValidate = NameValidation(last_name);
    // let ContactValidate = MobileValidation(contact_no);
    let EmailValidate = EmailValidation(email);
    let PasswordValidate = PasswordValidation(password);
    let ConfirmPassValidate = PasswordComparisonValidation(password, confirm_pass);
    if (
      FSValidate.status &&
      // LSValidate.status &&
      // ContactValidate.status &&
      tncStatus &&
      contactStatus &&
      EmailValidate.status &&
      PasswordValidate.status &&
      ConfirmPassValidate.status
    ) {
      _callRegistrationAPI();
    } else {
      setFSError(FSValidate?.status ? '' : FSValidate.msg);
      // setLSError(LSValidate?.status ? '' : LSValidate.msg);
      // setContactError(ContactValidate?.status ? '' : ContactValidate.msg);
      setTncStatusError(tncStatus ? '': 'Please accept above condition.');
      setContactStatusError(contactStatus ? '': 'Please accept above condition.');
      setEmailError(EmailValidate?.status ? '' : EmailValidate.msg);
      setPassError(PasswordValidate?.status ? '' : PasswordValidate.msg);
      setConfirmPassError(ConfirmPassValidate?.status ? '' : ConfirmPassValidate.msg);
    }
  };

  return (
    <>
      <Container>
        <LeftSection>
          <FormContainer>
            <ImageLogoWrapper source={LogoImg} />
            <Text fontSize={30} marginBottom={30} marginTop={10} color={Colors.light.gradientColor1} fontWeight={450}>
              Register to manage{'\n'}your properties
            </Text>
            <TextInputComponent
              error={firstNameError}
              title={'First Name'}
              onChangeText={(e) => {
                setFirstName(e), setFSError('');
              }}
              colored
            />
            <TextInputComponent
              // error={lastNameError}
              title={'Last Name'}
              onChangeText={(e) => {
                setLastName(e);
              }}
              colored
            />
            <TextInputComponent
              // error={contactError}
              isNumeric
              maxLength={12}
              title={'Contact Number'}
              
              onChangeText={(e) => {
                setContact(e);
                // if (Number(e) || e == '') setContact(e);
              }}
              value={contact_no}
              colored
            />
            <TextInputComponent
              error={emailError}
              title={'Email Address'}
              onChangeText={(e) => {
                setEmail(e), setEmailError('');
              }}
              colored
            />
            <TextInputComponent
              error={passError}
              password
              renderRightComponent={true}
              title={'Password'}
              onChangeText={(e) => {
                setPassword(e), setPassError('');
              }}
              colored
            />
            <TextInputComponent
              error={confirmPassError}
              password
              title={'Confirm Password'}
              renderRightComponent={true}
              onChangeText={(e) => {
                setConfirmPass(e), setConfirmPassError('');
              }}
              colored
            />
            <View
              style={{
                borderTopColor: 'lightgrey',
                paddingTop: '3%',
              }}
            >
              <CheckBox
                id="registerCheckbox-1"
                checked={tncStatus}
                label={terms}
                onPress={() => setTncStatus(!tncStatus)}
              />
              <If condition={tncStatusError}>
                <Text style={{ color: 'red' }}>{tncStatusError}</Text>
              </If>
              <CheckBox
                id="registerCheckbox-2"
                checked={contactStatus}
                label="Agree to being contacted and updated"
                onPress={() => setContactStatus(!contactStatus)}
              />
              <If condition={contactStatusError}>
                <Text style={{ color: 'red' }}>{contactStatusError}</Text>
              </If>
            </View>

            <Button onPress={_onRegister} childText={'Register'} alignSelf={'flex-end'} />
            <Text fontSize={13} color={Colors.light.text} fontWeight={450}>
              By registering to become an account holder you {'\n'}are agreeing to the terms & condtions and{'\n'}
              privacy policy of Hubita
            </Text>
          </FormContainer>
        </LeftSection>
        <RightSection>
          <TopSection>
            {layoutType != 'phone' ? (
              <>
                <Text fontSize={20} color={Colors.light.white} fontWeight={450}>
                  Already have an account?{' '}
                </Text>
                <Button
                  onPress={() => {
                    navigation.navigate('login');
                  }}
                  type={'stripe'}
                  childText={'Login'}
                  color={Colors.light.white}
                  width={105}
                />
              </>
            ) : (
              <>
                <Text fontSize={18} color={Colors.light.text} fontWeight={450}>
                  Already have an account?{' '}
                </Text>
                <Button
                  onPress={() => {
                    navigation.navigate('login');
                  }}
                  type={'stripe'}
                  childText={'Login'}
                  color={Colors.light.purple}
                  width={105}
                />
              </>
            )}
          </TopSection>
          {layoutType == 'phone' ? null : (
            <>
              <ImageWrapper source={rightImg} />
              <GradientOverlay colors={['#7002BC90', '#3364F590']} start={{ x: 1, y: 0 }} end={{ x: 1, y: 1 }} />
            </>
          )}
        </RightSection>
      </Container>
    </>
  );
};

export default RegisterScreen;
