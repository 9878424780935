import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import Box from '../../components/box';
import Text from '../../components/text';
// import DrawerMenu from '../drawerMenu/DrawerMenu';
import { useRoute } from '@react-navigation/native';
import Button from '../../components/button/Button';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import ListCard from '../../components/listCard';
import MarkAsComplete from '../../components/markAsComplete/MarkAsComplete';
import RadioButton from '../../components/radioButton';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import getPlatformType from '../../helpers/helpers';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import { showSuccesToast } from '../tenants/tenantOnboarding/helpers';
var layoutType = getPlatformType();

const Utilities = ({ navigation }) => {
  const [state, setState] = useState({
    Authority_Name: '',
    Account_Number: '',
    Website: '',
    Contact_Number: '',
    Email: '',
  });

  const [groundRentToggle, setGroundRentToggle] = useState(false);
  const [groundObj, setGroundObj] = useState({
    company_name: '',
    account_number: '',
    contact_number: '',
    website: '',
    email: '',
  });
  const [serviceCharge, setServiceCharge] = useState({
    company_name: '',
    account_number: '',
    contact_number: '',
    website: '',
    email: '',
  });
  const [waterObj, setWaterObj] = useState({
    company_name: '',
    account_number: '',
    contact_number: '',
    website: '',
    email: '',
  });
  const [gasObj, setGasObj] = useState({
    company_name: '',
    account_number: '',
    contact_number: '',
    website: '',
    email: '',
  });
  const [electricObj, setElectricObj] = useState({
    company_name: '',
    account_number: '',
    contact_number: '',
    website: '',
    email: '',
  });
  const [serviceChargeToggle, setServiceChargeToggle] = useState(false);
  const [waterAccountToggle, setWaterAccountToggle] = useState(false);
  const [gasAccountToggle, setGasAccountToggle] = useState(false);
  const [electricAccountToggle, setElectricAccountToggle] = useState(false);

  const route = useRoute();
  const _handleChange = (e) => {
    setState({ ...state, [e.label]: e?.value });
  };

  const _getPropertyUtilitesDetails = (id) => {
    ajax
      .get(Urls.UTILITIES + '/' + route?.params?.id)
      .then(({ data }) => {
        if (data.status) {

          setGroundRentToggle(data?.payload?.is_ground_rent_info_available ? 'Yes' : 'No');
          setServiceChargeToggle(data?.payload?.is_service_charge_info_available ? 'Yes' : 'No');
          setWaterAccountToggle(data?.payload?.is_water_account_info_available ? 'Yes' : 'No');
          setGasAccountToggle(data?.payload?.is_gas_account_info_available ? 'Yes' : 'No');
          setElectricAccountToggle(data?.payload?.is_electric_account_info_available ? 'Yes' : 'No');

          console.log('state1', state);
          if (data?.payload?.is_ground_rent_info_available) {
            setGroundObj(data?.payload.property_utility_types.ground_rent_info);
          }
          if (data?.payload?.is_service_charge_info_available) {
            setServiceCharge(data?.payload?.property_utility_types?.service_charge_info);
          }

          if (data?.payload?.is_gas_account_info_available) {
            setGasObj(data?.payload?.property_utility_types?.gas_account_info);
          }
          if (data?.payload?.is_water_account_info_available) {
            setWaterObj(data?.payload?.property_utility_types?.water_account_info);
          }
          if (data?.payload?.is_electric_account_info_available) {
            setElectricObj(data?.payload?.property_utility_types?.electric_account_info);
          }

          setState({
            ...state,
            ...{
              Authority_Name: data?.payload?.authority_name,
              Account_Number: data?.payload?.account_number,
              Website: data?.payload?.website,
              Contact_Number: data?.payload?.contact_number,
              Email: data?.payload?.email,
            },
          });
        } else {
          // alert(data.message);
        }
        console.log('state3', state);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  React.useEffect(() => {
    _getPropertyUtilitesDetails();
  }, []);

  const saveData = () => {
    const property_utility_types = [];

    if (groundRentToggle == 'Yes') {
      property_utility_types.push({
        property_utility_type_name: 'ground_rent_info',
        company_name: groundObj.company_name,
        account_number:groundObj.account_number,
        website:groundObj.website,
        contact_number:groundObj.contact_number,
        email:groundObj.email,
      });
    }

    if (serviceChargeToggle == 'Yes') {
      property_utility_types.push({
        property_utility_type_name: 'service_charge_info',
        company_name: serviceCharge.company_name,
        account_number:serviceCharge.account_number,
        website:serviceCharge.website,
        contact_number:serviceCharge.contact_number,
        email:serviceCharge.email,
      });
    }

    if (waterAccountToggle == 'Yes') {
      property_utility_types.push({
        property_utility_type_name: 'water_account_info',
        company_name: waterObj.company_name,
        account_number:waterObj.account_number,
        website:waterObj.website,
        contact_number:waterObj.contact_number,
        email:waterObj.email,
      });
    }

    if (gasAccountToggle == 'Yes') {
      property_utility_types.push({
        property_utility_type_name: 'gas_account_info',
        company_name: gasObj.company_name,
        account_number:gasObj.account_number,
        website:gasObj.website,
        contact_number:gasObj.contact_number,
        email:gasObj.email,
      });
    }

    if (electricAccountToggle == 'Yes') {
      property_utility_types.push({
        property_utility_type_name: 'electric_account_info',
        company_name: electricObj.company_name,
        account_number:electricObj.account_number,
        website:electricObj.website,
        contact_number:electricObj.contact_number,
        email:electricObj.email,
      });
    }

    const requestBody = {
      authority_name: state?.Authority_Name,
      account_number: state?.Account_Number,
      website: state?.Website,
      contact_number: state?.Contact_Number,
      email: state?.Email,
      is_ground_rent_info_available: groundRentToggle && groundRentToggle == 'Yes' ? true : false,
      is_service_charge_info_available: serviceChargeToggle && serviceChargeToggle == 'Yes' ? true : false,
      is_water_account_info_available: waterAccountToggle && waterAccountToggle == 'Yes' ? true : false,
      is_gas_account_info_available: gasAccountToggle && gasAccountToggle == 'Yes' ? true : false,
      is_electric_account_info_available: electricAccountToggle && electricAccountToggle == 'Yes' ? true : false,
      property_utility_types,
    };

    ajax
      .post(`${Urls.UTILITIES}/${route?.params?.id}`, requestBody)
      .then(({ data }) => {
        if (data.status) {
          // showSuccesToast(data?.data);
          if (route?.params?.id) {
            navigation.navigate('PropertySection', { id: route?.params?.id });
          } else {
            navigation.navigate('PropertySection', { id: data?.payload?.id });
          }
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <>
      <ListCard
        localUrlImage={require('./../../assets/images/Utilties.png')}
        title={'Utilities'}
        description={'Please add the utility companies who serve your property.'}
        showPropertyCard={route.params?.name}
      />

      <MarkAsComplete
        flowID={route?.params?.id}
        flowtype={route?.params?.key}
        isComplete={route?.params?.status === 2 ? true : false}
      />

      <Box style={{ marginVertical: 20 }}>
        <Text fontWeight={'600'} fontSize={15}>
          Local Authority {'\n'}
          {'\n'}
          {'\n'}
        </Text>
        <TextInputComponent
          onChangeText={(e) => _handleChange({ value: e, label: 'Authority_Name' })}
          value={state?.Authority_Name}
          title={'Authority Name'}
        />
        <TextInputComponent
          onChangeText={(e) => _handleChange({ value: e, label: 'Account_Number' })}
          value={state?.Account_Number}
          title={'Account Number'}
        />
        <TextInputComponent
          onChangeText={(e) => _handleChange({ value: e, label: 'Website' })}
          value={state?.Website}
          title={'Website'}
        />
        <TextInputComponent
          isNumeric={true}
          onChangeText={(e) => _handleChange({ value: e, label: 'Contact_Number' })}
          value={state?.Contact_Number}
          title={'Contact Number'}
        />
        <TextInputComponent
          onChangeText={(e) => _handleChange({ value: e, label: 'Email' })}
          value={state?.Email}
          title={'Email'}
        />
      </Box>
      <Box style={{ marginVertical: 20 }}>
        <RadioButton
          style={{ margin: 'auto' }}
          value={groundRentToggle}
          options={['Yes', 'No']}
          onChange={(e) => {
            setGroundRentToggle(e);
          }}
          title={'Add Ground Rent Info?'}
        />
        <If condition={groundRentToggle == 'Yes'}>
          <View style={{ marginTop: 20 }} />
          <TextInputComponent
            style={{ marginTop: 20 }}
            onChangeText={(e) => setGroundObj({ ...groundObj, company_name: e })}
            value={groundObj.company_name}
            title={'Company Name'}
          />
          <TextInputComponent
            onChangeText={(e) => setGroundObj({ ...groundObj, account_number: e })}
            value={groundObj.account_number}
            title={'Account Number'}
          />
          <TextInputComponent
            onChangeText={(e) => setGroundObj({ ...groundObj, website: e })}
            value={groundObj.website}
            title={'Website'}
          />
          <TextInputComponent
            isNumeric
            onChangeText={(e) => setGroundObj({ ...groundObj, contact_number: e })}
            title={'Contact Number'}
            value={groundObj.contact_number}
          />
          <TextInputComponent
            onChangeText={(e) => setGroundObj({ ...groundObj, email: e })}
            value={groundObj.email}
            title={'Email'}
          />
        </If>
      </Box>

      <Box style={{ marginVertical: 20 }}>
        <RadioButton
          style={{ margin: 'auto' }}
          value={serviceChargeToggle}
          options={['Yes', 'No']}
          onChange={(e) => setServiceChargeToggle(e)}
          title={'Add Service Charge Info?'}
        />
        <If condition={serviceChargeToggle == 'Yes'}>
          <View style={{ marginTop: 20 }} />
          <TextInputComponent
            style={{ marginTop: 20 }}
            onChangeText={(e) => setServiceCharge({ ...serviceCharge, company_name: e })}
            value={serviceCharge.company_name}
            title={'Company Name'}
          />
          <TextInputComponent
            onChangeText={(e) => setServiceCharge({ ...serviceCharge, account_number: e })}
            value={serviceCharge.account_number}
            title={'Account Number'}
          />
          <TextInputComponent
            onChangeText={(e) => setServiceCharge({ ...serviceCharge, website: e })}
            value={serviceCharge.website}
            title={'Website'}
          />
          <TextInputComponent
            isNumeric
            onChangeText={(e) => setServiceCharge({ ...serviceCharge, contact_number: e })}
            title={'Contact Number'}
            value={serviceCharge.contact_number}
          />
          <TextInputComponent
            onChangeText={(e) => setServiceCharge({ ...serviceCharge, email: e })}
            value={serviceCharge.email}
            title={'Email'}
          />
        </If>
      </Box>

      <Box style={{ marginVertical: 20 }}>
        <RadioButton
          style={{ margin: 'auto' }}
          value={waterAccountToggle}
          options={['Yes', 'No']}
          onChange={(e) => setWaterAccountToggle(e)}
          title={'Add Water Account Info?'}
        />
        <If condition={waterAccountToggle == 'Yes'}>
          <View style={{ marginTop: 20 }} />
          <TextInputComponent
            style={{ marginTop: 20 }}
            onChangeText={(e) => setWaterObj({ ...waterObj, company_name: e })}
            title={'Company Name'}
            value={waterObj.company_name}
          />
          <TextInputComponent
            onChangeText={(e) => setWaterObj({ ...waterObj, account_number: e })}
            title={'Account Number'}
            value={waterObj.account_number}
          />
          <TextInputComponent
            onChangeText={(e) => setWaterObj({ ...waterObj, website: e })}
            title={'Website'}
            value={waterObj.website}
          />
          <TextInputComponent
            isNumeric
            onChangeText={(e) => setWaterObj({ ...waterObj, contact_number: e })}
            title={'Contact Number'}
            value={waterObj.contact_number}
          />
          <TextInputComponent
            onChangeText={(e) => setWaterObj({ ...waterObj, email: e })}
            title={'Email'}
            value={waterObj.email}
          />
        </If>
      </Box>

      <Box style={{ marginVertical: 20 }}>
        <RadioButton
          style={{ margin: 'auto' }}
          value={gasAccountToggle}
          options={['Yes', 'No']}
          onChange={(e) => setGasAccountToggle(e)}
          title={'Add Gas Account Info?'}
        />
        <If condition={gasAccountToggle == 'Yes'}>
          <View style={{ marginTop: 20 }} />
          <TextInputComponent
            style={{ marginTop: 20 }}
            onChangeText={(e) => setGasObj({ ...gasObj, company_name: e })}
            title={'Company Name'}
            value={gasObj.company_name}
          />
          <TextInputComponent
            onChangeText={(e) => setGasObj({ ...gasObj, account_number: e })}
            title={'Account Number'}
            value={gasObj.account_number}
          />
          <TextInputComponent
            onChangeText={(e) => setGasObj({ ...gasObj, website: e })}
            title={'Website'}
            value={gasObj.website}
          />
          <TextInputComponent
            onChangeText={(e) => setGasObj({ ...gasObj, contact_number: e })}
            isNumeric
            title={'Contact Number'}
            value={gasObj.contact_number}
          />
          <TextInputComponent
            onChangeText={(e) => setGasObj({ ...gasObj, email: e })}
            title={'Email'}
            value={gasObj.email}
          />
        </If>
      </Box>

      <Box style={{ marginVertical: 20 }}>
        <RadioButton
          style={{ margin: 'auto' }}
          value={electricAccountToggle}
          options={['Yes', 'No']}
          onChange={(e) => setElectricAccountToggle(e)}
          title={'Add Electric Account Info?'}
        />
        <If condition={electricAccountToggle == 'Yes'}>
          <View style={{ marginTop: 20 }} />
          <TextInputComponent
            style={{ marginTop: 20 }}
            onChangeText={(e) => setElectricObj({ ...electricObj, company_name: e })}
            title={'Company Name'}
            value={electricObj.company_name}
          />
          <TextInputComponent
            onChangeText={(e) => setElectricObj({ ...electricObj, account_number: e })}
            title={'Account Number'}
            value={electricObj.account_number}
          />
          <TextInputComponent
            onChangeText={(e) => setElectricObj({ ...electricObj, website: e })}
            title={'Website'}
            value={electricObj.website}
          />
          <TextInputComponent
            onChangeText={(e) => setElectricObj({ ...electricObj, contact_number: e })}
            isNumeric
            title={'Contact Number'}
            value={electricObj.contact_number}
          />
          <TextInputComponent
            onChangeText={(e) => setElectricObj({ ...electricObj, email: e })}
            title={'Email'}
            value={electricObj.email}
          />
        </If>
      </Box>
      <Button
        onPress={saveData}
        childText={'Save and Continue'}
        width={150}
        style={{ alignSelf: 'flex-end', marginVertical: 30 }}
      />
    </>
  );
};

export default drawerHoc({
  Component: Utilities,
  showBackButton: true,
});
