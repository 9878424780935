import React from 'react';
import {
    View,
    Text,
    SafeAreaView,
    Image,
    TouchableOpacity,
    Platform,
    ScrollView,
    Button,
    WebView
} from "react-native";
import Hyperlink from 'react-native-hyperlink'
import styled from "styled-components";
import {
    Container,
    SectionWrapper,
    DualView,
    TextBox,
    SectionTitle,
    SectionText,
    ImageBox,
    TeamsSection,
    MemberBoxWrapper,
    MemberBox,
    MemberImage,
    MemberName,
    MemberNameText,
    MemberDetails,
    MemberDetailsText,
    MemberHeader,
    TeamHeader,
    SectionPageTitle,
    SectionTitleTop,
    BulletText,
    Bullet,
    SectionInnerTitle,
    PrivacyGrid,
    PrivacyGridHeader,
    PrivacyGridBody,
    Asterick,
    PrivacyLeftBox,
    PrivacyRightBox,
    CookieTable,
    CookieCells,
    BulletInlineText,
} from "./styles";
import getPlatformType from "../../helpers/helpers";

const rowText = (text1, text2) => {
    return (
        <View style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{text1}</Text>
            <Text style={{ fontSize: 14, marginLeft: 5 }}>{text2}</Text>
        </View>
    )
}

const Cookies = () => {
    const linkStyle = { color: '#2980b9', fontSize: 16 };
    const layoutType = getPlatformType();;

    return (
        <View style={{ flex: 1, padding: 20, width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Hyperlink linkStyle={linkStyle} onPress={(url) => openLink(url)}>

                <SectionPageTitle>Cookies Policy</SectionPageTitle>
                <SectionTitle>What are Cookies?</SectionTitle>
                <SectionText>
                    Cookies are small files which are stored on a user's computer and hold
                    data relating to a specific website. Cookies are placed on the user's
                    computer by that website and accessed each time a user browses the site.
                </SectionText>
                <SectionText>
                    The cookies used by Hubita collect data to help us improve our website
                    by seeing which parts of the site are being used, how users are
                    navigating to each page and what items on each page they prefer to click
                    on.
                </SectionText>
                <SectionText>
                    Below we have listed each of the cookies that our site uses, with detail
                    as to why we use them. We’ve also detailed the time each cookie is kept
                    on your system. This can be persistent (the cookie remains after you
                    leave the site, to be used when you return) or session (the cookie is
                    deleted once your visit is complete).
                </SectionText>
                <SectionText>
                    You can use this page to opt-out of certain types of cookie. Opting out
                    will apply only to the browser or device that you are currently using,
                    so you may need to change these settings again if you use other browsers
                    or devices to access the site.
                </SectionText>
                <SectionText>
                    If you use your browser settings to block all cookies (including
                    essential cookies) you may not be able to access all or parts of our
                    website.
                </SectionText>
                <SectionText>
                    If you wish to restrict or block web browser cookies which are set on
                    your device then you can do this through your browser settings; the Help
                    function within your browser should tell you how. Alternatively, you may
                    wish to visit www.aboutcookies.org, which contains comprehensive
                    information on how to control browser cookies on a wide variety of
                    desktop browsers.
                </SectionText>
                <SectionText>We use the following types of cookies:</SectionText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
                        Strictly necessary cookies :
                    </Text>
                    These are cookies that are required for the operation of our website.
                    They include, for example, cookies that enable you to log into secure
                    areas of our website, use a shopping cart or make use of e-billing
                    services.
                </BulletInlineText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
                        Analytical or performance cookies :
                    </Text>
                    These allow us to recognise and count the number of visitors and to see
                    how visitors move around our website when they are using it. This helps
                    us to improve the way our website works, for example, by ensuring that
                    users are finding what they are looking for easily.
                </BulletInlineText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
                        Functionality cookies :
                    </Text>
                    These are used to recognise you when you return to our website. This
                    enables us to personalise our content for you, greet you by name and
                    remember your preferences (for example, your choice of language or
                    region).
                </BulletInlineText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
                        Targeting cookies :
                    </Text>
                    These cookies record your visit to our website, the pages you have
                    visited and the links you have followed. We will use this information to
                    make our website and the advertising displayed on it more relevant to
                    your interests. We may also share this information with third parties
                    for this purpose.
                </BulletInlineText>
                <SectionText>
                    You can find more information about the individual cookies we use and
                    the purposes for which we use them in the table below. If you have any
                    queries or require more information on this please email
                    corporate@hubita.co.uk
                </SectionText>
                <SectionTitle>Strictly Necessary Cookies</SectionTitle>
                <SectionInnerTitle>
                    Some cookies are essential in order to enable you to move around the
                    website and use its features. Without these cookies, we will not be able
                    to connect you to our site properly.
                </SectionInnerTitle>
                {layoutType != "phone" && (
                    <CookieTable style={{ borderBottomWidth: 0, marginTop: 20 }}>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>Cookie</Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>
                                Description
                            </Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>
                                Application place
                            </Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>
                                Persistent or Session
                            </Text>
                        </CookieCells>
                    </CookieTable>
                )}
                <CookieTable>

                    <CookieCells style={{ marginTop: layoutType == 'phone' ? 10 : 0 }}>__cfduid</CookieCells>
                    <CookieCells>
                        Use to speed up page load times. It is used to override any security
                        restrictions based on the IP address the visitor is coming from. It
                        does not contain any user identification information.
                    </CookieCells>
                    <CookieCells>ClaudFlare</CookieCells>
                    <CookieCells>6 Year(s)</CookieCells>
                    <CookieCells style={{ marginTop: layoutType == 'phone' ? 10 : 0 }}>hjCachedUserAttributes</CookieCells>
                    <CookieCells>
                        This cookie stores User Attributes which are sent through the Hotjar
                        Identify API, whenever the user is not in the sample. These attributes
                        will only be saved if the user interacts with a Hotjar Feedback tool.
                    </CookieCells>
                    <CookieCells>Hotjad Cookie</CookieCells>
                    <CookieCells>Expired</CookieCells>
                </CookieTable>
                <SectionTitle>Performance Cookies</SectionTitle>
                <SectionInnerTitle>
                    These cookies collect information about how visitors use our site, for
                    instance which page is viewed most often, and if error messages are
                    seen. These cookies don't collect information that identifies a visitor.
                    All information that these cookies collect is aggregated and therefore
                    anonymous. It is only used to improve how our website works.
                </SectionInnerTitle>
                <SectionText>
                    Like most websites, we use analytics software, such as Google Analytics.
                    This software gathers anonymous data relating to how our site is being
                    used and then provides us with aggregated visitor statistics, number of
                    page views etc. We supplement this data with our own analytics, which
                    allows us to do more in-depth analysis of how people use our site. We
                    feel that this information is vital to us, helping us to make the site
                    as easy to use and useful as possible.
                </SectionText>
                <SectionText>
                    We regularly add new features and change existing features to make the
                    site work as well as possible, and to make the experience as helpful as
                    possible to all parties (we care about the experience all of our users
                    get!) To ensure that the changes we make are useful, we test variants of
                    these changes across our site, reviewing data from our analytics system
                    to see what works best. The cookies below allow us to do that.
                </SectionText>
                {layoutType != "phone" && (
                    <CookieTable style={{ borderBottomWidth: 0, marginTop: 20 }}>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>Cookie</Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>
                                Description
                            </Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>
                                Application place
                            </Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>
                                Persistent or Session
                            </Text>
                        </CookieCells>
                    </CookieTable>
                )}
                <CookieTable>
                    <CookieCells style={{ marginTop: layoutType == 'phone' ? 10 : 0 }}>_ga</CookieCells>
                    <CookieCells>
                        Name associated with Google Universal Analytics. It is used to
                        distinguish unique users by assigning randomly generated number as a
                        client identifier. It is included in each page request and used to
                        calculate visitors and data from site analytics reports. It expires
                        after two years, by default; however, this can be customised by the
                        website owners.
                    </CookieCells>
                    <CookieCells>Google Analytics</CookieCells>
                    <CookieCells>2 Year(s)</CookieCells>
                    <CookieCells style={{ marginTop: layoutType == 'phone' ? 10 : 0 }}>_gat</CookieCells>
                    <CookieCells>
                        It is used to throttle the request rate, limiting the collection of
                        data on high traffic sites. Expires after 10 minutes.
                    </CookieCells>
                    <CookieCells>Google Analytics</CookieCells>
                    <CookieCells>Expired</CookieCells>
                    <CookieCells style={{ marginTop: layoutType == 'phone' ? 10 : 0 }}>_gid</CookieCells>
                    <CookieCells>
                        Use to distinguish users. Expires in 24 hours.
                    </CookieCells>
                    <CookieCells>Google Analytics</CookieCells>
                    <CookieCells>1 day(s)</CookieCells>
                    <CookieCells style={{ marginTop: layoutType == 'phone' ? 10 : 0 }}>_hjid</CookieCells>
                    <CookieCells>
                        Set when a costumer first lands on a page with the Hotjar script. It
                        is used to persist the random user ID, unique to that site. This
                        ensures that behaviour in subsequent visit to the site will be
                        attributed to the same user ID.
                    </CookieCells>
                    <CookieCells>Hotjar</CookieCells>
                    <CookieCells>11 Month(s)</CookieCells>
                    <CookieCells style={{ marginTop: layoutType == 'phone' ? 10 : 0 }}>_hjIncludedInSapmple</CookieCells>
                    <CookieCells>
                        Set to let Hotjar known whether that visitor is included in the sample
                        which is used to generate funnels. Expires in one year.
                    </CookieCells>
                    <CookieCells>Hotjad Cookie</CookieCells>
                    <CookieCells>Session</CookieCells>
                    <CookieCells style={{ marginTop: layoutType == 'phone' ? 10 : 0 }}>_hjTLDTest</CookieCells>
                    <CookieCells>
                        When the Hotjar script executes we try to determine the most generic
                        cookie path we should use, instead of the page hostname. This is done
                        so that cookies can be shared across subdomains (where applicable). To
                        determine this, we try to store the _hjTLDTest cookie for different
                        URL substring alternatives until it fails. After this check, the
                        cookie is removed.{" "}
                    </CookieCells>
                    <CookieCells>Hotjad Cookie</CookieCells>
                    <CookieCells>Session</CookieCells>
                </CookieTable>
                <SectionTitle>Functionality Cookies</SectionTitle>
                <SectionInnerTitle>
                    These cookies allow us to remember choices you have made to provide a
                    more enhanced, personal service. The information these cookies collect
                    relates only to our website.
                </SectionInnerTitle>
                <SectionText>
                    We have a bunch of features which need to understand what you've been
                    doing on the site recently, and to reduce the amount of times you have
                    to do the same thing. For instance, you don't want to fill in the
                    contact agent form again and again with the same data, so we store that
                    information to reduce the time it takes and to make life easier. All the
                    cookies below perform a similar function.
                </SectionText>
                {layoutType != "phone" && (
                    <CookieTable style={{ borderBottomWidth: 0, marginTop: 20 }}>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>Cookie</Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>
                                Description
                            </Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>
                                Application place
                            </Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>
                                Persistent or Session
                            </Text>
                        </CookieCells>
                    </CookieTable>
                )}
                <CookieTable>
                    <CookieCells style={{ marginTop: layoutType == 'phone' ? 10 : 0 }}>aiovg_rand_seed</CookieCells>
                    <CookieCells>Unspecified</CookieCells>
                    <CookieCells></CookieCells>
                    <CookieCells>1 day (s)</CookieCells>
                </CookieTable>
                <SectionTitle>Local Storage</SectionTitle>
                <SectionText>
                    In addition to cookies, local storage allows us to use a tiny bit of
                    space on your machine to store information, in much the same way as
                    cookies. We use this to store information which, we feel, enhances your
                    journey through the site by remembering previous actions and making them
                    default for the future. All local storage is persistent, with no expiry.
                </SectionText>

                {layoutType != "phone" && (
                    <CookieTable style={{ borderBottomWidth: 0, marginTop: 20 }}>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>Cookie</Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>Description</Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>Application place</Text>
                        </CookieCells>
                        <CookieCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>Persistent or Session</Text>
                        </CookieCells>
                    </CookieTable>
                )}
                <CookieTable>
                    <CookieCells style={{ marginTop: layoutType == 'phone' ? 10 : 0 }}>
                        Not applicable – no Local Storages Cookies have been used
                    </CookieCells>
                    <CookieCells></CookieCells>
                    <CookieCells></CookieCells>
                    <CookieCells></CookieCells>
                </CookieTable>
                <SectionTitle>
                    Targeting Cookies and Online Behavioural Advertising
                </SectionTitle>
                <SectionText>
                    The Hubita site uses cookies from third party advertising partners to
                    deliver relevant advertisements to you both on the Hubita website and
                    on other websites. Below is a list of our advertising partners. To
                    opt-out of a third party's use of cookies and behavioural advertising
                    you must visit its website and follow its specified opt-out procedures.
                    We have provided links and additional assistance below, but we cannot
                    take responsibility for third party websites. This information may
                    change over time.
                </SectionText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>Google :</Text>
                    You can learn how Google uses information from sites or apps that use
                    their services by visiting their website. If you don't want Google to
                    use information based on your Hubita activity for the purpose of
                    showing you more relevant advertisements, you can opt out by following
                    the instructions specified on their website. For reference, Google
                    operates the Google Display Network (comprising partner websites and
                    some Google properties like YouTube), DoubleClick online ad serving
                    products and the DoubleClick Ad Exchange, and Invite Media. Because
                    Google uses DoubleClick and Invite technology, cookies placed by Google
                    for behavioural advertising, including the opt-out cookie, will appear
                    as DoubleClick and Invite cookies.
                </BulletInlineText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>PubMatic :</Text>
                    Google DoubleClick may delegate to another third-party agency, PubMatic,
                    to deliver relevant advertisements to you on Hubita. If you don't want
                    PubMatic to use information based on your activity on Hubita sites for
                    the purpose of showing you more relevant ads, you can follow their
                    specified opt-out procedures shown on the PubMatic website.
                </BulletInlineText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>Facebook :</Text>
                    For more information on how Facebook ads work, and to control the types
                    of adverts shown to you, visit their website here. If you'd prefer that
                    Facebook didn't use information based on your activity on Hubita sites
                    for the purpose of showing you more relevant ads, you can opt-out by
                    accessing your Facebook settings.
                </BulletInlineText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>Captify :</Text>
                    Captify allows advertisers to target users who have searched on Yahoo,
                    Google and Bing. Search Retargeting enables advertisers to focus on
                    users who have expressed intent and match their desired audience with
                    quality inventory. Learn more about Captify here. To opt-out of Captify
                    ads, please visit adchoices here.
                </BulletInlineText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>Samba TV :</Text>
                    Samba has integrated TV data from multiple sources -- from smart TVs to
                    set-top boxes to TV apps -- to help advertisers and broadcasters
                    understand the TV viewership habits of the modern consumer. Learn more
                    about Samba here. You can opt-out of having Samba use your web browsing
                    information for interest-based advertisements by going here to the NAI
                    website or accessing "Opt-out of Samba TV Ads" available on the Samba
                    website here.
                </BulletInlineText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
                        Groundtruth :
                    </Text>
                    GroundTruth is a mobile advertising platform specializing in location
                    powered mobile ads. Their services combine data, technology, and
                    strategy to help advertisers reach the audiences most likely to be
                    interested in their products or services. Find out more here. To opt-out
                    of Groundtruth advertising, you can do so via the YourAdChoices website
                    here.
                </BulletInlineText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>Hotjar :</Text>
                    Hubita uses Hotjar to better understand our users' needs and to
                    optimize our services and our user’s experience. Hotjar is a technology
                    service that helps us better understand our users experience (e.g. how
                    much time they spend on which pages, which links they choose to click,
                    what users do and don't like, etc.) and this enables us to build and
                    maintain our service with user feedback. Hotjar uses cookies and other
                    technologies to collect data on our users' behaviour and their devices
                    (in particular device's IP address (captured and stored only in
                    anonymized form), device screen size, device type (unique device
                    identifiers), browser information, geographic location (country only),
                    preferred language used to display our website). Hotjar stores this
                    information in a pseudonymized user profile. Neither Hotjar nor we will
                    ever use this information to identify individual users or to match it
                    with further data on an individual user. For further details, please see
                    Hotjar's privacy policy by clicking on this link. You can opt-out to the
                    creation of a user profile, Hotjar's storing of data about your usage of
                    our site and Hotjar's use of tracking cookies on other websites by
                    following this opt-out link.
                </BulletInlineText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>MediaMath :</Text>
                    MediaMath provides advertisers with the ability to run interest-based
                    advertising, to make the ads you see more interesting and relevant to
                    you based on the types of sites you visit online and other information
                    that does not personally identify you. Learn more about MediaMath here.
                    To opt-out of MediaMath ads, please visit adchoices here.
                </BulletInlineText>
                <BulletInlineText>
                    <Bullet>{"\u2022"}</Bullet>
                    <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
                        Optimizely :
                    </Text>
                    Some of our pages use a third-party software provider, Optimizely, to
                    test variations in the content to help us improve your experience. You
                    can view the Optimizely privacy policy or opt-out using the links below.
                </BulletInlineText>
            </Hyperlink >
        </View>
    )
}
export default Cookies;