import variables from '../atoms/variables.json';
import { colorsTheme } from './theme';
import moment from 'moment';
import { WSnackBar } from 'react-native-smart-tip';
export const getHoldingDeposite = (Monthly_Rental_Amount, FREQUENCY) => {
  let rentalAmount = Monthly_Rental_Amount;

  if (FREQUENCY == 'week') {
    rentalAmount = (Monthly_Rental_Amount * 52) / 12;
  } else if (FREQUENCY == 'quarterly') {
    rentalAmount = (Monthly_Rental_Amount * 4) / 12;
  } else if (FREQUENCY == '6 monthly') {
    rentalAmount = (Monthly_Rental_Amount * 2) / 12;
  } else if (FREQUENCY == 'annually') {
    rentalAmount = Monthly_Rental_Amount / 12;
  }
  return ((rentalAmount * 12) / 52).toFixed(2);
};

export const GetTenancyDeposit = (Monthly_Rental_Amount, FREQUENCY) => {
  let rentalAmount = Monthly_Rental_Amount;

  if (FREQUENCY == 'week') {
    rentalAmount = (Monthly_Rental_Amount * 52) / 12;
  } else if (FREQUENCY == 'quarterly') {
    rentalAmount = (Monthly_Rental_Amount * 4) / 12;
  } else if (FREQUENCY == '6 monthly') {
    rentalAmount = (Monthly_Rental_Amount * 2) / 12;
  } else if (FREQUENCY == 'annually') {
    rentalAmount = Monthly_Rental_Amount / 12;
  }
  return (((rentalAmount * 12) / 52) * 5).toFixed(2);
};

export const TotalAmountPayable = (initialrental, Monthly_Rental_Amount) => {
  return initialrental + GetTenancyDeposit(Monthly_Rental_Amount) - getHoldingDeposite(Monthly_Rental_Amount);
};
export const GetFilename = (url) => {
  if (url) {
    return url ? url.split('/').pop().split('#').shift().split('?').shift() : null;
  }
  return '';
};

export const getHoldingDepositStatusData = (code_type, data) => {
  switch (code_type) {
    case 0:
      return {
        Text: 'Not Initiated',
        color: colorsTheme.black,
        borderColor: variables.darkgrey,
        backgroundColor: variables.lightgrey,
      };
    case 1:
      return {
        Text: 'Request Sent',
        color: colorsTheme.black,
        backgroundColor: variables.lightyellow,
        borderColor: variables.yellow,
        DateText: 'Sent On:',
        Date: data?.movein_money_sent_at,
      };
    case 2:
      return {
        Text: 'Paid Unconfirmed',
        color: variables.red,
        backgroundColor: variables.new,
        borderColor: variables.new,
        DateText: 'Accepted On:',
        Date: data?.movein_money_accepted_at,
      };
    case 3:
      return {
        Text: 'Confirmed',
        color: colorsTheme.black,
        backgroundColor: variables.peachgreen,
        borderColor: variables.puregreen,
        DateText: 'Received On:',
        Date: data?.movein_money_received_date,
      };
    default:
      return {
        Text: 'Not Initiated',
        color: colorsTheme.black,
        backgroundColor: colorsTheme.pink,
      };
  }
};

export const getHoldingDepositStatusData1 = (code_type, data) => {
  let date;

  switch (code_type) {
    case 0:
      return {
        Text: 'Not Initiated',
        color: 'black',
        borderColor: variables.darkgrey,
        backgroundColor: variables.lightgrey,
      };
    case 1:
      return {
        Text: 'Request Sent',
        color: 'black',
        borderColor: variables.yellow,
        backgroundColor: variables.lightyellow,
        DateText: 'Sent On:',
        Date: moment(data?.deposit_sent_at).format('DD-MM-YYYY'),
      };
    case 2:
      return {
        Text: 'Paid Unconfirmed',
        color: 'black',
        borderColor: variables.new,
        backgroundColor: variables.new,
        DateText: 'Accepted On:',
        Date: moment(data?.deposit_sent_at).format('DD-MM-YYYY'),
      };
    case 3:
      return {
        Text: 'Confirmed',
        color: 'black',
        borderColor: variables.puregreen,
        backgroundColor: variables.peachgreen,
        DateText: 'Received On:',
        Date: moment(data?.date_received_deposit).format('DD-MM-YYYY'),
      };
    default:
      return {
        Text: 'Not Initiated',
        color: 'black',
        borderColor: variables.darkgrey,
        backgroundColor: variables.lightgrey,
      };
  }
};

export const getIdStatusData = (code_type) => {
  switch (code_type) {
    case 0:
      return {
        Text: 'Not Started',
        color: '#9F78FF',
      };

      break;
    case 1:
      return {
        Text: 'InProgess',
        color: '#D3760C',
      };
      break;
    case 2:
      return {
        Text: 'Completed',
        color: '#FC4850',
      };
      break;
    default:
      return {
        Text: 'Not Started',
        color: '#9F78FF',
      };
      break;
  }
};

export const getRentalOfferStatus = (code_type) => {
  switch (code_type) {
    case 0:
      return {
        Text: 'Not requested',
        color: variables.black,
        backgroundColor: variables.lightgrey,
      };

      break;
    case 1:
      return {
        Text: 'Request Sent',
        color: variables.black,
        backgroundColor: variables.yellow,
      };
      break;
    case 2:
      return {
        Text: 'Accepted',
        color: variables.black,
        backgroundColor: variables.peachgreen,
      };
      break;
    default:
      return {
        Text: 'Not requested',
        color: variables.black,
        backgroundColor: variables.lightgrey,
      };
      break;
  }
};

export const getDigitalVerificationStatus = (code_type) => {
  switch (code_type) {
    case 0:
      return {
        Text: 'Not Started',
        code_type: '0',
        color: variables?.purple1,
        textColor: variables?.purple1,
        type: 'custom',
      };

      break;
    case 1:
      return {
        Text: 'Pending',
        code_type,
        type: 'customsolid',
        textColor: variables.yellow,
        color: variables.lightyellow,
      };
      break;
    case 2:
      return {
        Text: 'Verified',
        color: variables.puregreen,
        code_type,
        type: 'solid',
      };
      break;
    case 3:
      return {
        Text: 'Mannually Verified',
        color: '#FC4850',
        code_type,
        type: 'solid',
        color: variables.puregreen,
      };
      break;
    case 5:
      return {
        Text: 'Request Sent',
        color: '#FC4850',
        code_type,
        type: 'customsolid',
        textColor: variables.yellow,
        color: variables.lightyellow,
      };
      break;
    case 9:
      return {
        Text: 'Verification Error',
        color: '#FC4850',
        code_type,
        type: 'customsolid',
        textColor: variables.red,
        color: variables.lightpinkp,
      };
      break;
    default:
      return {
        Text: 'Not Started',
        color: variables?.purple1,
        textColor: variables?.purple1,
        code_type,
        type: 'custom',
      };
      break;
  }
};
export const getLiteReferencingStatus = (code_type) => {
  switch (code_type) {
    case 0:
      return {
        Text: 'not started',
        bgColor: variables.lightgrey,
        color: variables?.lightgrey,
        borderColor: variables?.lightgrey,
      };
      break;
    case 1:
      return {
        Text: 'Pending',
        bgColor: variables.yellow,
        color: variables?.peach,
        borderColor: variables?.orangeButton,
      };
      break;
    case 2:
      return {
        Text: 'Completed',
        color: variables?.peachgreen,
        bgColor: variables.puregreen,
        borderColor: variables?.puregreen,
      };
      break;
    case 9:
      return {
        Text: 'error',
        color: variables?.lightgrey,
        bgColor: variables.red,
        borderColor: variables?.lightgrey,
      };
      break;
    default:
      return {
        Text: 'N/A',
        color: variables?.lightgrey,
        bgColor: variables.lightgrey,
        borderColor: variables?.lightgrey,
      };
      break;
  }
};
export const getFullReferencingStatus = (code_type) => {
  switch (code_type) {
    case 0:
      return {
        Text: 'Not Started',
        bgColor: variables.lightgrey,
        color: variables?.lightgrey,
        borderColor: variables?.lightgrey,
      };

      break;
    case 1:
      return {
        Text: 'Pending',
        bgColor: variables.yellow,
        color: variables?.peach,

        borderColor: variables?.orangeButton,
      };
      break;
    case 2:
      return {
        Text: 'Completed',
        color: variables?.peachgreen,
        bgColor: variables.puregreen,
        borderColor: variables?.puregreen,
      };
      break;
    case 5:
      return {
        Text: 'Request Sent',
        bgColor: variables.yellow,
        color: variables?.peach,

        borderColor: variables?.orangeButton,
      };
      break;
    case 9:
      return {
        Text: 'Error',
        color: variables?.lightgrey,
        bgColor: variables.red,
        borderColor: variables?.lightgrey,
      };
      break;
    default:
      return {
        Text: 'Not Started',
        bgColor: variables.lightgrey,
        color: variables?.lightgrey,
        borderColor: variables?.lightgrey,
      };
      break;
  }
};

export const getMoneyFull = (hour) => {
  switch (hour) {
    case 1:
      return {
        pounds: 60,
        hour: 1,
      };
      break;
    case 24:
      return {
        pounds: 40,
        hour: 24,
      };
      break;
    case 48:
      return {
        pounds: 20,
        hour: 48,
      };
      break;
    default:
      return {
        pounds: 20,
        hour: 48,
      };
      break;
  }
};
export const getMoneyFullDigital = () => {
  return {
    pounds: 5,
    hour: 'N/A',
  };
};
export const moneyValues = [1, 24, 48];

export const showErrorToast = (messsage)=>{
  const snackBarOpts = {
    data:  messsage,
    position: WSnackBar.position.TOP, // 1.TOP 2.CENTER 3.BOTTOM
    duration: WSnackBar.duration.LONG, //1.SHORT 2.LONG 3.INDEFINITE
    textColor: 'white',
    backgroundColor: 'red',
    actionText: '',
    actionTextColor: '#ff490b',
    onActionHide: (isSlideHide) => {
      // Click Action
    },
  };
  WSnackBar.show(snackBarOpts);
}
export const showSuccesToast = (messsage)=>{
  const snackBarOpts = {
    data:  messsage,
    position: WSnackBar.position.TOP, // 1.TOP 2.CENTER 3.BOTTOM
    duration: WSnackBar.duration.LONG, //1.SHORT 2.LONG 3.INDEFINITE
    textColor: 'white',
    backgroundColor: 'green',
    actionText: '',
    actionTextColor: 'white',
    onActionHide: (isSlideHide) => {
      // Click Action
    },
  };
  WSnackBar.show(snackBarOpts);
}