import React, { useEffect, useState } from 'react';
import { ListItem } from '../atoms/listitem';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { tenantActions } from '_actions';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import ContactCard from '../atoms/contactCard';
import variables from '../atoms/variables.json';
import { Button } from '../atoms/button';
import BackComponent from './../atoms/backComponent';
import { DocumentWithStatusCard } from './../atoms/documentWithStatusCard';
import { TextInput } from './../atoms/textinput';
import { CardBox } from '../atoms/card';

import Select from '@material-ui/core/Select';
import moment from 'moment';
// import ID_ICON from './../../../assets/svgIcons/OpenFlo/ID&Reference.svg';
import MenuItem from '@material-ui/core/MenuItem';
import {
  getDigitalVerificationStatus,
  getLiteReferencingStatus,
  getFullReferencingStatus,
  moneyValues,
  getMoneyFull,
  getMoneyFullDigital,
} from '../helpers';
import ToolTip from '../atoms/ToolTip';

import {
  CardWrapper,
  TlyfeSelector,
  LineSeperator,
  Heading,
  SubHeading,
  RowContainer,
  CostTitle,
  CardFooter,
  ProfilesList,
  InputNumber,
} from './style';
import BottomButton from '../atoms/bottomButton';
import OpenFlowModal from '../atoms/modal';
import { ModalHeader } from '../atoms/modal/style';
import Accordion from '../atoms/Accordion';
// import StringConstant from '../../../utils/constants';
import RentalWarrenty from './RentalWarrenty';
// import { alertActions } from '_actions';
// import Constant from '../../../config/Constant';
import Container from '../atoms/Container/Container';
import drawerHoc from '../../../../components/drawerHOC/DrawerHOC';
import DrawerHoc from '../../../../components/drawerHOC';
import getPlatformType from '../../../../helpers/helpers';

const agreementsCol = [{ title: 'Court' }, { title: 'Amount' }, { title: 'Order Date' }];

const judgmentsCol = [{ title: 'Court' }, { title: 'Amount' }, { title: 'Date' }];
const layoutType = getPlatformType();
const TenantIdentification = (props) => {
  const dispatch = useDispatch();
  const [tenants, setTenants] = React.useState([]);
  const [flowId, setFlowId] = React.useState(null);
  const [totalCost, setTotalCost] = React.useState(0);
  const [fullRefData, setFullRefData] = useState(null);
  const [warranty, setWarranty] = React.useState(null);
  const [branchName, setBranchName] = React.useState('');
  const [selectHours, setSelectHours] = React.useState(48);
  const [fccBranchCode, setFccBranchCode] = React.useState('');
  const [isTeanantSelected, setIsTeanantSelected] = useState(false);
  const [liteReferencingData, setLiteReferencingData] = useState({});
  const idendityData = useSelector((state) => state?.tenant?.idendityData);
  const fccBranchData = useSelector((state) => state?.tenant?.fccBranchData);
  const [showReferencingModal, setShowReferencingModal] = React.useState(false);
  const [showLiteReferencingModal, setShowLiteReferencingModal] = useState(false);
  const [showFullReferencingModal, setShowFullReferencingModal] = useState(false);
  const [showIdVerificatioinModal, setIdVerificationModal] = React.useState(false);
  const errorStatusforFullRef = useSelector((state) => state?.tenant?.fullRefError?.data);
  const [showReferencingModalError, setShowReferencingModalError] = React.useState(false);
  const fcc_branch_code = useSelector((state) => state?.users?.user_profile?.agent?.branch?.fcc_branch_code);
  const can_use_digital_reference = useSelector(
    (state) => state?.users?.user_profile?.agent?.branch?.can_use_digital_reference
  );
  const [fields, setFields] = useState({
    open_flow_sub_tenant_id: '',
    post_code: '',
    correspondece_address: '',
    email: '',
    number: '',
    name: '',
    first_name: '',
    last_name: '',
    reference_type: 'Enhanced (Full)',
  });
  const [showSuccessModal, setSuccessModal] = React.useState(false);

  const modalGlobalObject = useSelector((state) => state?.tenant?.idendityData);
  const [modalState, setModalState] = React.useState({
    send_to_tenant: true,
  });

  useEffect(() => {
    if (modalGlobalObject) {
      setModalState({
        send_to_tenant: modalGlobalObject.send_to_tenant,
      });
    }
  }, [modalGlobalObject]);
  useEffect(() => {
    if (fccBranchData?.fccBranchCode) {
      setFccBranchCode(fccBranchData.fccBranchCode);
      setBranchName(fccBranchData.name);
    }
  }, [fccBranchData]);

  useEffect(() => {
    // dispatch(tenantActions.getFccBranchCode());
  }, []);

  useEffect(() => {
    if (props?.match?.params?.id) {
      setFlowId(props?.match?.params?.id);
      // dispatch(tenantActions.getIdentityData(props?.match?.params?.id));
      // dispatch(tenantActions.getDocuments(props?.match?.params?.id));
    }
  }, [props?.match?.params?.id]);

  useEffect(() => {
    setWarranty(idendityData?.flow?.open_flow_rental_warranty);
  }, [idendityData?.flow]);

  useEffect(() => {
    if (idendityData?.tenants && idendityData?.tenants?.length) {
      setTenants(idendityData?.tenants);
    } else {
      setTenants([]);
    }
  }, [idendityData?.tenants]);

  useEffect(() => {
    if (errorStatusforFullRef) {
      if (errorStatusforFullRef === 'This property has incomplete address') {
        setShowReferencingModalError(true);
      }
    }
  }, [errorStatusforFullRef]);

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeGuarantor = (e, index) => {
    let teampArr = [...tenants];
    if (teampArr[index].gaurantor) {
      if (e.target.name == 'first_name') {
        teampArr[index].gaurantor = {
          ...teampArr[index].gaurantor,
          first_name: e.target.value,
        };
      } else if (e.target.name == 'last_name') {
        teampArr[index].gaurantor = {
          ...teampArr[index].gaurantor,
          last_name: e.target.value,
        };
      } else {
        teampArr[index].gaurantor = {
          ...teampArr[index].gaurantor,
          [e.target.name]: e.target.value,
        };
      }
    } else {
      teampArr[index].gaurantor = {
        [e.target.name]: e.target.value,
      };
    }
    setTenants(teampArr);
  };

  const handleReferencingSelection = (startFullReferencing, index, isGarunter) => {
    if (startFullReferencing) {
      setTotalCost(totalCost + 1);
    } else {
      setTotalCost(totalCost - 1);
    }
    let teampArr = [...tenants];
    if (isGarunter) {
      if (teampArr[index].gaurantor) {
        teampArr[index].gaurantor = {
          ...teampArr[index].gaurantor,
          startFullReferencing,
        };
      } else {
        teampArr[index].gaurantor = {
          startFullReferencing,
        };
      }
    } else {
      teampArr[index].startFullReferencing = startFullReferencing;
    }
    setTenants(teampArr);
  };

  const getHandlerContactNumber = (handler) => {
    if (handler) {
      handler = JSON.parse(handler);
      return handler[0]?.phone_number;
    }
  };

  const getHandlerId = (id) => {
    if (id) {
      return id;
    }
  };

  const getHandlerName = (handler) => {
    if (handler) {
      handler = JSON.parse(handler);
      return `${handler[0]?.title} ${handler[0]?.first_name} ${handler[0]?.last_name}`;
    }
  };

  const handleIdSelection = (startFullReferencing, index) => {
    if (startFullReferencing) {
      setTotalCost(totalCost + 1);
    } else {
      setTotalCost(totalCost - 1);
    }
    let teampArr = [...tenants];
    teampArr[index].startIdVerification = true;
    setTenants(teampArr);
  };

  const addDoc = (data, index, type) => {
    let teampArr = [...tenants];
    teampArr[index][type].document = [
      ...teampArr[index][type].document,
      {
        document_url: data[0],
      },
    ];
    setTenants(teampArr);
  };

  const addDocUploadGurantor = (data, index, type) => {
    let teampArr = [...tenants];
    if (teampArr[index]) {
      teampArr[index]['guarantor_details_doc'] = [
        {
          document_url: data[0],
        },
      ];
    }
    setTenants(teampArr);
  };

  const removeDocGurantor = (index, docIndex, type) => {
    let teampArr = [...tenants];

    let tempDocArry = teampArr[index]['guarantor_details_doc'];

    tempDocArry.splice(docIndex, 1);

    teampArr[index]['guarantor_details_doc'] = [...tempDocArry];

    setTenants(teampArr);
  };

  const removeDoc = (index, docIndex, type) => {
    let teampArr = [...tenants];

    let tempDocArry = teampArr[index][type].document;

    tempDocArry.splice(docIndex, 1);

    teampArr[index][type].document = [...tempDocArry];

    setTenants(teampArr);
  };

  const _onSave = (data) => {
    let payload = {
      tenants: tenants,
      ...data,
    };
    // dispatch(tenantActions.saveIdentityData(payload, flowId));
  };

  const renderVerificationStatus = (data) => {
    return (
      <>
        <p style={{ fontWeight: 'bold', fontSize: 13, paddingBottom: 10 }}>Request sent to: {data?.email}</p>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <CardBox
            padding={'7px'}
            style={{
              width: '50%',
              backgroundColor: getLiteReferencingStatus(data?.lite_referencing_status).color,
              border: 'solid 1px ' + getLiteReferencingStatus(data?.lite_referencing_status).borderColor,
              borderRadius: 20,
            }}
            hasShadow={true}
            hasBorderRadius={true}
          >
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                padding: 10,
              }}
            >
              <div style={{ fontSize: '14px' }}>Tenant Pre - Qualification</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: 3,
                }}
              >
                {(data?.lite_referencing_status === 2 || data?.lite_referencing_status === 1) && (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setLiteReferencingData(data.lite_referencing_data);
                      setShowLiteReferencingModal(true);
                    }}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: variables.purple1,
                      }}
                    >
                      View
                    </div>
                  </span>
                )}
              </div>
            </div>
          </CardBox>
          <Button
            style={{
              backgroundColor: getLiteReferencingStatus(data?.lite_referencing_status).bgColor,
              color: 'black',
              borderRadius: 5,
            }}
            type="solid"
            noClick="noClick"
            title={getLiteReferencingStatus(data?.lite_referencing_status).Text}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '2%',
          }}
        >
          <CardBox
            padding={'15px'}
            style={{
              width: '50%',
              backgroundColor: getFullReferencingStatus(data?.full_referencing_status).color,
              border: 'solid 1px ' + getLiteReferencingStatus(data?.lite_referencing_status).borderColor,
            }}
            hasShadow={true}
            hasBorderRadius={true}
          >
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: '14px' }}>Tenant Referencing</div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {data?.full_referencing_data?.fcc_references_updates?.final_response_pdf && (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open(data?.full_referencing_data?.fcc_references_updates?.final_response_pdf, '_blank');
                    }}
                  >
                    <div style={{ fontSize: '14px', fontWeight: 500, color: variables.purple1 }}>Pdf</div>
                  </span>
                )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {data?.full_referencing_data?.fcc_references_updates?.progress && (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setShowFullReferencingModal(true);
                      setFullRefData(data?.full_referencing_data?.fcc_references_updates?.progress);
                    }}
                  >
                    <div style={{ fontSize: '14px', fontWeight: 500, color: variables.purple1 }}>View</div>
                  </span>
                )}
              </div>
            </div>
          </CardBox>
          <Button
            style={{
              backgroundColor: getFullReferencingStatus(data?.full_referencing_status).bgColor,
              borderRadius: 5,
              color: 'black',
            }}
            type="solid"
            noClick="noClick"
            title={getFullReferencingStatus(data?.full_referencing_status).Text}
          />
        </div>
        <p style={{ fontWeight: 'bold', fontSize: 13, marginTop: 15 }}>
          Reference Handler: {getHandlerName(data?.full_referencing_data?.fcc_references_updates?.handler)}
        </p>
        <p style={{ fontWeight: 'bold', fontSize: 13, marginTop: 10 }}>
          Tel: {getHandlerContactNumber(data?.full_referencing_data?.fcc_references_updates?.handler)}
        </p>
        <p style={{ fontWeight: 'bold', fontSize: 13, marginTop: 10 }}>
          Ref: {getHandlerId(data?.full_referencing_data?.fcc_reference_id)}
        </p>
      </>
    );
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  const renderRightToRent = (data) => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 0.7, display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                padding: 10,
              }}
            >
              <div style={{ fontSize: '14px' }}>Tenant Right to Rent share code:</div>
            </div>

            <Button
              color={'#6D08C0'}
              type="stripe"
              noClick="noClick"
              title={data?.right_to_rent ? data?.right_to_rent : 'N/A'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                padding: 10,
              }}
            >
              <div style={{ fontSize: '14px' }}>Tenant Date of Birth:</div>
            </div>

            <Button
              color={'#6D08C0'}
              type="stripe"
              noClick="noClick"
              title={data?.right_to_rent_dob ? data?.right_to_rent_dob : 'N/A'}
            />
          </div>
        </div>
        <div
          style={{
            flex: 0.3,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            color={'#EFC5C5'}
            type="solid"
            title={'Check Right To rent'}
            style={{ color: 'black' }}
            // onClick={() => openInNewTab(Constant.RTR_URL)}
          />

          {data?.right_to_rent ? (
            <Button
              color={data?.right_to_rent_approved ? '#dadada' : '#90C418'}
              type="solid"
              noClick={data?.right_to_rent_approved ? 'noClick' : null}
              style={{ color: 'black', marginTop: 20 }}
              title={data?.right_to_rent_approved ? 'Approved' : 'Approve'}
              onClick={
                () => {}
                // dispatch(
                //   tenantActions.postRtrCode({
                //     rtr_code: data.right_to_rent,
                //   })
                // )
              }
            />
          ) : null}
        </div>
      </div>
    );
  };
  const renderGaurantorVerificationStatus = (data) => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '2%',
          }}
        >
          <CardBox
            padding={'15px'}
            style={{
              width: '50%',
              backgroundColor: getFullReferencingStatus(data?.gaurantor?.full_referencing_status).color,
              border: 'solid 1px ' + getLiteReferencingStatus(data?.gaurantor?.lite_referencing_status).borderColor,
            }}
            hasShadow={true}
            hasBorderRadius={true}
          >
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: '14px' }}>Guarantor full Referencing</div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {data?.gaurantor?.full_referencing_data?.fcc_references_updates?.final_response_pdf && (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open(
                        data?.gaurantor?.full_referencing_data?.fcc_references_updates?.final_response_pdf,
                        '_blank'
                      );
                    }}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: variables.purple1,
                      }}
                    >
                      Pdf
                    </div>
                  </span>
                )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {data?.gaurantor.full_referencing_data?.fcc_references_updates?.progress && (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setShowFullReferencingModal(true);
                      setFullRefData(data?.gaurantor?.full_referencing_data?.fcc_references_updates?.progress);
                    }}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: variables.purple1,
                      }}
                    >
                      View
                    </div>
                  </span>
                )}
              </div>
            </div>
          </CardBox>
          <Button
            style={{
              backgroundColor: getFullReferencingStatus(data?.gaurantor?.full_referencing_status).bgColor,
              borderRadius: 5,
              color: 'black',
            }}
            type="solid"
            noClick="noClick"
            title={getFullReferencingStatus(data?.gaurantor?.full_referencing_status).Text}
          />
        </div>
      </>
    );
  };

  const onChangeHours = (e, name) => {
    setSelectHours(e.target.value);
  };

  const renderModalBody = (data) => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      {data?.opbrix_id && (
        <TextInput
          title={
            <>
              <strong>TLyfeId: </strong>
            </>
          }
          type={'row'}
          wrapperstyle={{
            width: '99%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data?.opbrix_id || ''}
        />
      )}
      <TextInput
        title={
          <>
            <strong>First Name: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.first_name || ''}
      />
      <TextInput
        title={
          <>
            <strong>Last Name: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.last_name || ''}
      />
      <TextInput
        title={
          <>
            <strong>Email: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.email || ''}
      />
      <TextInput
        title={
          <>
            <strong>Contact Number: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.contact_number || ''}
      />
      <TextInput
        title={
          <>
            <strong>Post Code: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.postcode || ''}
      />
      <TextInput
        title={
          <>
            <strong>Address: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.address || ''}
      />
    </div>
  );

  const renderModalBodyGurantor = (data) => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <TextInput
        title={
          <>
            <strong>Full Name: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.gaurantor?.name || ''}
      />

      <TextInput
        title={
          <>
            <strong>Email: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.gaurantor?.email || ''}
      />
      <TextInput
        title={
          <>
            <strong>Contact Number: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.gaurantor?.number || ''}
      />
      <TextInput
        title={
          <>
            <strong>Post Code: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.gaurantor?.post_code || ''}
      />
      <TextInput
        title={
          <>
            <strong>Address: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.gaurantor?.correspondece_address || ''}
      />
    </div>
  );

  const renderReferencingModalErr = () => (
    <div>
      <SubHeading>Thank you for choosing to use FCC Paragon to conduct your tenant referencing.</SubHeading>
      <SubHeading>We are not able to get the correct address for the related propertry.</SubHeading>
      <SubHeading>You want to add the address?.</SubHeading>
      <RowContainer>
        <Button
          onClick={() => {
            setShowReferencingModalError(false);
            setTotalCost(0);
          }}
          title={'No'}
          type="stripe"
        />
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            if (idendityData?.flow?.property_id) {
              const url = `/dashboard/rent/edit/${idendityData?.flow?.property_id}`;
              // props.history.push(url);
            } else {
              alert('invalid Property');
            }
            setTotalCost(0);
            setShowReferencingModalError(false);
          }}
          title={'Yes'}
          type={'stripe'}
          // type={totalCost ? "stripe" : "solid"}
          style={{ borderColor: '#90C418', color: '#90C418' }}
        />
      </RowContainer>
    </div>
  );
  console.log('total coset', totalCost);
  const renderReferencingModal = () => (
    <div>
      <SubHeading>Thank you for choosing to use FCC Paragon to conduct your tenant referencing.</SubHeading>
      <div
        style={{
          display: 'flex',
          width: '49%',
          alignItems: 'center',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          marginTop: 40,
          marginBottom: 40,
        }}
      >
        <Heading style={{ fontSize: 14, width: '100%' }}>Reference Type</Heading>

        <Select
          value={fields?.reference_type}
          className="form-control"
          onChange={handleChange}
          variant="outlined"
          name="reference_type"
          placeholder="duration"
        >
          <MenuItem value={'Enhanced (Full)'}>Enhanced (Full)</MenuItem>
          {can_use_digital_reference && <MenuItem value={'Digital'}>Digital</MenuItem>}
        </Select>
      </div>
      <SubHeading>Who do you want to reference:</SubHeading>
      {isTeanantSelected ? (
        <>
          <Heading>Tenants</Heading>
          <ProfilesList style={{ marginTop: 0 }}>
            {tenants &&
              tenants.map((item, index) => (
                <ContactCard
                  style={{ marginTop: 0 }}
                  key={item.email + new Date()}
                  nonRemove
                  key={item.opbrix_id}
                  row={item.email}
                  is_lead={item.is_lead ? true : false}
                  name={item.first_name + ' ' + item.last_name}
                  renderModal={renderModalBody(item)}
                  selection={item.full_referencing_status == 0}
                  isSelected={item?.startFullReferencing}
                  onSelect={(data) => handleReferencingSelection(data, index, false)}
                />
              ))}
          </ProfilesList>
        </>
      ) : (
        <>
          <Heading>Guarantors</Heading>
          <ProfilesList style={{ marginTop: '5%' }}>
            {tenants &&
              tenants.map((item, index) => {
                if (item?.gaurantor?.email) {
                  return (
                    <div style={{ flexDirection: 'column' }}>
                      {index === 0 && <Heading>Guarantors</Heading>}
                      <ContactCard
                        style={{ marginTop: 0 }}
                        key={item.email + new Date()}
                        nonRemove
                        key={item.opbrix_id}
                        row={item.email}
                        is_lead={false}
                        name={item?.gaurantor?.first_name + item?.gaurantor?.last_name}
                        renderModal={renderModalBodyGurantor(item)}
                        selection={true}
                        isSelected={item?.gaurantor?.startFullReferencing}
                        onSelect={(data) => handleReferencingSelection(data, index, true)}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
          </ProfilesList>
        </>
      )}{' '}
      <SubHeading>You have chosen to Use:</SubHeading>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <Heading>
          {fccBranchCode == '' || fccBranchCode == null ? (
            <Heading>Openbrix Account </Heading>
          ) : (
            <Heading>{branchName} Account</Heading>
          )}
        </Heading>

        {fcc_branch_code || fields?.reference_type === 'Digital' ? null : (
          <RowContainer
            style={{
              marginTop: '20px',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Heading style={{ marginRight: layoutType == 'phone' ? '20px' : '40px' }}>Response: </Heading>
            <Select
              value={selectHours}
              style={{ width: layoutType == 'phone' ? '40%' : '20%', height: '30px' }}
              onChange={onChangeHours}
              variant="outlined"
              name="rental_frequency"
              placeholder="duration"
            >
              {moneyValues.map((val) => (
                <MenuItem value={val}>{val}</MenuItem>
              ))}
            </Select>
            <Heading style={{ marginLeft: layoutType == 'phone' ? '20px' : '40px' }}>Hours</Heading>
          </RowContainer>
        )}

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Heading style={{ marginRight: '30px', marginTop: '30px' }}>Cost per applicant:</Heading>
          {fccBranchCode ? (
            <CostTitle>As agreed with FCC*</CostTitle>
          ) : fields?.reference_type === 'Digital' ? (
            <CostTitle>£5</CostTitle>
          ) : (
            <CostTitle>£{getMoneyFull(selectHours)?.pounds}</CostTitle>
          )}
          <Heading style={{ marginLeft: '40px', marginTop: '30px' }}>+VAT</Heading>
        </div>

        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <Heading style={{ marginRight: '50px', marginTop: '30px' }}>Total Cost:</Heading>
          {fccBranchCode ? (
            <CostTitle>As agreed with FCC*</CostTitle>
          ) : fields?.reference_type === 'Digital' ? (
            <CostTitle>£{getMoneyFullDigital()?.pounds * totalCost}</CostTitle>
          ) : (
            <CostTitle>£{getMoneyFull(selectHours)?.pounds * totalCost}</CostTitle>
          )}
        </div>
        {fccBranchCode && (
          <Heading style={{ marginVartical: 40 }}>
            * You branch is using your own company FCC account, and therefore the costs are private agreed.
          </Heading>
        )}
        <SubHeading>Are you happy with the costs and Ok to proceed?</SubHeading>
        <RowContainer style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <Button
            onClick={() => {
              setShowReferencingModal(false);
              setTotalCost(0);
            }}
            style={{
              borderColor: '#FC4850',
              color: '#FC4850',
              width: layoutType == 'phone' ? '35%' : '30%',
            }}
            title={'No'}
            type="stripe"
          />
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              if (totalCost) {
                let arrtoSend = [...tenants];
                let newData = [];
                arrtoSend.map((data, index) => {
                  delete data['lite_referencing_data'];

                  newData = [...newData, data];
                });
                // dispatch(
                //   tenantActions.fullReferencing(
                //     '',
                //     flowId,
                //     fields?.reference_type === 'Digital' ? 'N/A' : selectHours,
                //     newData,
                //     fields?.reference_type === 'Digital' ? '5' : getMoneyFull(selectHours)?.pounds * totalCost,
                //     fields?.reference_type
                //   )
                // );
                setShowReferencingModal(false);
                setTotalCost(0);
              }
            }}
            title={'Yes'}
            type={'stripe'}
            style={{ borderColor: '#90C418', color: '#90C418', width: layoutType == 'phone' ? '35%' : '30%' }}
          />
        </RowContainer>
      </div>
      <Heading>PLEASE NOTE:</Heading>
      <p>By clicking yes you agree to OpenBrix invoicing you at the end of every month for this reference.</p>
    </div>
  );

  const renderIdVerification = () => (
    <div>
      <SubHeading>
        In order to fulfil your 'Right to Rent' checks please select the tenants you woudl like to have ID Verified.
      </SubHeading>
      <Heading>Tenants</Heading>
      <ProfilesList style={{ marginTop: 0 }}>
        {tenants &&
          tenants.map((item, index) => (
            <ContactCard
              style={{ marginTop: 0 }}
              key={item.email + new Date()}
              nonRemove
              key={item.opbrix_id}
              row={item.email}
              is_lead={item.is_lead ? true : false}
              name={item.first_name + ' ' + item.last_name}
              renderModal={renderModalBody(item)}
              selection={item.idverification === 5 ? false : true}
              isSelected={item?.startIdVerification}
              onSelect={(data) => handleIdSelection(data, index)}
            />
          ))}
      </ProfilesList>

      <SubHeading>You have chosen to Use:</SubHeading>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <Heading>
          {fccBranchCode == '' || fccBranchCode == null ? (
            <Heading>Openbrix Account </Heading>
          ) : (
            <Heading>{branchName} Account</Heading>
          )}
        </Heading>

        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <Heading style={{ marginRight: '30px', marginTop: '30px' }}>Cost:</Heading>
          <CostTitle>£0</CostTitle>
        </div>

        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <Heading style={{ marginRight: '30px', marginTop: '30px' }}>Total Cost:</Heading>
          <CostTitle>£0</CostTitle>
        </div>
        <SubHeading>Are you happy with the costs and Ok to proceed?</SubHeading>
        <RowContainer style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <Button
            onClick={() => {
              setIdVerificationModal(false);
            }}
            title={'No'}
            style={{
              borderColor: '#FC4850',
              color: '#FC4850',
              width: layoutType == 'phone' ? '35%' : '30%',
            }}
            type="stripe"
          />
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              if (totalCost) {
                let arrtoSend = [...tenants];
                let newData = [];
                arrtoSend.map((data, index) => {
                  if (data.startIdVerification) {
                    newData = [...newData, data?.opbrix_id];
                  }
                });

                // dispatch(tenantActions.requestdigital(newData, flowId));

                setIdVerificationModal(false);
              }
            }}
            title={'Yes'}
            // type={totalCost ? "stripe" : "solid"}
            type={'stripe'}
            style={{ borderColor: '#90C418', color: '#90C418', width: layoutType == 'phone' ? '35%' : '30%' }}
          />
        </RowContainer>
      </div>
      <Heading>PLEASE NOTE:</Heading>
      <p>By clicking yes you agree to OpenBrix invoicing you at the end of every month for this reference.</p>
    </div>
  );

  const getValues = (data, isDate) => {
    if (isDate) {
      if (data && data.length) {
        if (data[0] != '') {
          return moment(data[0]).format('DD-MM-YYYY');
        } else {
          return 'N/A';
        }
      } else {
        return 'N/A';
      }
    } else if (data && data.length) {
      if (data[0] != '') {
        return data[0];
      } else {
        return 'N/A';
      }
    } else {
      return 'N/A';
    }
  };

  const renderFullReferencingModal = () => {
    let data = fullRefData && fullRefData.length ? fullRefData[0] : null;
    return (
      <>
        <div
          style={{
            display: 'flex',
            marginTop: '10px',
            justifyContent: 'space-around',
          }}
        >
          <Accordion
            title={'Employment'}
            children={
              <div style={{ padding: 20 }}>
                <SubHeading style={{ color: 'red', margin: 0 }}>Status</SubHeading>
                <SubHeading style={{ margin: 0 }}>{getValues(data?.current_employment_status)}</SubHeading>
                <SubHeading style={{ color: 'red', margin: 0, marginTop: '10px' }}>Comments</SubHeading>
                <SubHeading style={{ margin: 0 }}>{getValues(data?.current_employment_comments)}</SubHeading>
                <SubHeading style={{ color: 'red', margin: 0, marginTop: '10px' }}>Last Update</SubHeading>
                <SubHeading style={{ margin: 0 }}>
                  {getValues(data?.current_employment_date_last_updated, true)}
                </SubHeading>
              </div>
            }
          />
          <Accordion
            title={'Credit Search'}
            children={
              <div style={{ padding: 20 }}>
                <SubHeading style={{ color: 'red', margin: 0 }}>Status</SubHeading>
                <SubHeading style={{ margin: 0 }}>{getValues(data?.search_details_status)}</SubHeading>
                <SubHeading style={{ color: 'red', margin: 0, marginTop: '10px' }}>Comments</SubHeading>
                <SubHeading style={{ margin: 0 }}>{getValues(data?.current_employment_comments)}</SubHeading>
                <SubHeading style={{ color: 'red', margin: 0, marginTop: '10px' }}>Last Update</SubHeading>
                <SubHeading style={{ margin: 0 }}>{getValues(data?.search_details_date_last_updated, true)}</SubHeading>
              </div>
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '10px',
            justifyContent: 'space-around',
          }}
        >
          <Accordion
            title={'Living Status'}
            children={
              <div style={{ padding: 20 }}>
                <div style={{ padding: 20 }}>
                  <SubHeading style={{ color: 'red', margin: 0 }}>Status</SubHeading>
                  <SubHeading style={{ margin: 0 }}>{getValues(data?.current_living_status)}</SubHeading>
                  <SubHeading style={{ color: 'red', margin: 0, marginTop: '10px' }}>Comments</SubHeading>
                  <SubHeading style={{ margin: 0 }}>{getValues(data?.current_living_comments)}</SubHeading>
                  <SubHeading style={{ color: 'red', margin: 0, marginTop: '10px' }}>Last Update</SubHeading>
                  <SubHeading style={{ margin: 0 }}>
                    {getValues(data?.current_living_date_last_updated, true)}
                  </SubHeading>
                </div>
              </div>
            }
          />
          <Accordion
            title={'Tenant Search'}
            children={
              <div style={{ padding: 20 }}>
                <div style={{ padding: 20 }}>
                  <SubHeading style={{ color: 'red', margin: 0 }}>Status</SubHeading>
                  <SubHeading style={{ margin: 0 }}>{getValues(data?.tenant_database_status)}</SubHeading>
                  <SubHeading style={{ color: 'red', margin: 0, marginTop: '10px' }}>Comments</SubHeading>
                  <SubHeading style={{ margin: 0 }}>{getValues(data?.tenant_database_comments)}</SubHeading>
                  <SubHeading style={{ color: 'red', margin: 0, marginTop: '10px' }}>Last Update</SubHeading>
                  <SubHeading style={{ margin: 0 }}>
                    {getValues(data?.tenant_database_date_last_updated, true)}
                  </SubHeading>
                </div>
              </div>
            }
          />
        </div>
      </>
    );
  };

  const renderLiteReferencingModal = () => (
    <>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          marginTop: '10px',
        }}
      >
        <div style={{ width: '400px', marginRight: '40px' }}>
          <ModalHeader>Pre Qualification</ModalHeader>
          <strong>
            Below are the <strong style={{ color: '#E71E82' }}>"SELF CERTIFIED"</strong> details of this tenant.
          </strong>
          <div style={{ display: 'flex', width: '100%', marginTop: 40 }}>
            <div
              style={{
                display: 'flex',
                flex: 0.5,
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: 20,
              }}
            >
              <Heading style={{ textAlign: 'end' }}>
                <strong style={{ color: '#E71E82' }}>Self Certified Yearly Gross Income </strong>
              </Heading>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 0.5,
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: 20,
              }}
            >
              £ {(liteReferencingData?.affordability?.salary).toLocaleString()}
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
            <div
              style={{
                display: 'flex',
                flex: 0.5,
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: 20,
              }}
            >
              <Heading>
                <strong style={{ color: '#E71E82' }}>Affordability </strong>
              </Heading>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 0.5,
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: 20,
              }}
            >
              £ {(liteReferencingData?.affordability?.affordbility_amount).toLocaleString()}
            </div>
          </div>
          <CardBox
            style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}
            padding={'10px'}
            hasShadow={true}
            hasBorderRadius={true}
          >
            <div
              style={{
                display: 'flex',
                paddingHorizontal: 20,
                paddingVertical: '4%',
                flexDirection: 'column',
              }}
            >
              <strong style={{ marginTop: 30 }}>Affordability criteria is determined by each individual agent.</strong>
              <strong style={{ marginTop: 30 }}>
                From the information you have provided by the tenant and the calculations from FCC Paragon, we belive
                this amount would be the tenants rental affordability.
              </strong>
            </div>
          </CardBox>
        </div>
        {/* <div style={{ width: "400px" }}>
          <CardBox padding={"10px"} hasShadow={true} hasBorderRadius={true}>
            <ModalHeader>Lite Reference</ModalHeader>
            <Heading>Below are the details of the tenant CCJ status </Heading>
            <CardBox padding={"10px"} hasShadow={true} hasBorderRadius={true}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 10,
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <p>Is there a CCJ?</p>
                <div
                  style={{
                    height: 40,
                    width: 100,
                    borderRadius: 10,
                    backgroundColor: "red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight:10
                  }}
                >
                  <div style={{ color: "white",fontWeight:'bold',fontSize:15 }}>CCJ</div>
                </div>
              </div>
              <strong style={{marginTop:30
                ,marginBottom:30
                ,display:"flex"}}>From our record we can see that you have the following  CCJ in your name.
              </strong>
            </CardBox>
            <Heading style={{ marginTop: "10%" }}>Active CCJ's</Heading>
            <CardBox padding={"12px"} hasShadow={true} hasBorderRadius={true}>
              <div
                style={{
                  paddingHorizontal: 15,
                  paddingVertical: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {judgmentsCol.map((data, index) => (
                    <div
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <SubHeading
                        style={{
                          color: "#E71E82",
                          fontSize: 14,
                          textAlign: "center",
                        }}
                      >
                        {data.title}
                      </SubHeading>
                    </div>
                  ))}
                </div>
                {liteReferencingData &&
                liteReferencingData.judgments.length > 0 ? (
                  liteReferencingData.judgments.map((data, index) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: " space-around",
                      }}
                    >
                      <SubHeading
                        style={{
                          width: "60px",
                          margin: 0,
                          textAlign: "center",
                          marginBottom: "5px",
                        }}
                      >
                        {data.court_name}
                      </SubHeading>
                      <SubHeading
                        style={{
                          width: "60px",
                          margin: 0,
                          marginRight: "10px",
                          textAlign: "center",
                          marginBottom: "5px",
                        }}
                      >
                        {data.amount}
                      </SubHeading>
                      <SubHeading
                        style={{
                          width: "80px",
                          margin: 0,
                          textAlign: "center",
                          marginBottom: "5px",
                        }}
                      >
                        {data.judgment_date}
                      </SubHeading>
                     
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <text> No Judgments Found </text>
                  </div>
                )}
              </div>
            </CardBox>
            
            <Heading style={{display:"flex", marginTop: 20, marginBottom: 20 }}>
              Bankruptcy & Agreements
            </Heading>
            <CardBox padding={"5px"} hasShadow={true} hasBorderRadius={true}>
              <div
                style={{
                  paddingHorizontal: 15,
                  paddingVertical: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {agreementsCol.map((data, index) => (
                    <div
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <SubHeading
                        style={{
                          color: "#E71E82",
                          fontSize: 14,
                          textAlign: "center",
                        }}
                      >
                        {data.title}
                      </SubHeading>
                    </div>
                  ))}
                </div>
                {liteReferencingData &&
                liteReferencingData.agreements.length > 0 ? (
                  liteReferencingData.agreements.map((data, index) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: " space-around",
                      }}
                    >
                      <SubHeading
                        style={{
                          width: "60px",
                          margin: 0,
                          textAlign: "center",
                          marginBottom: "5px",
                        }}
                      >
                        {data.court_name}
                      </SubHeading>
                      <SubHeading
                        style={{
                          width: "60px",
                          margin: 0,
                          textAlign: "center",
                          marginBottom: "5px",
                        }}
                      >
                        {data?.amount}
                      </SubHeading>
                      <SubHeading
                        style={{
                          width: "80px",
                          margin: 0,
                          textAlign: "center",
                          marginBottom: "5px",
                        }}
                      >
                        {data.order_date}
                      </SubHeading>
                     
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <text> No Agreements Found </text>
                  </div>
                )}
              </div>
            </CardBox>
            
          </CardBox>
        </div> */}
      </div>
    </>
  );

  return (
    <Container>
      <CardWrapper>
        <ListItem
          type={'complete'}
          title={'Tenant Identification'}
          listIcon={<ListAltTwoToneIcon style={{ fontSize: '25px', color: variables.purple1 }} />}
          // icon={ID_ICON}
          showPropertyCard
          description={`All Applicant Tenant(s) Identification and Verification is received from TLyfe which interacts with OpenFlo to maximise the process efficiency and referral fees.`}
        />

        <BackComponent
          id={flowId}
          isComplete={idendityData?.flow?.verification_status == 1 ? true : false}
          flowtype={'verification_status'}
        />
        <TlyfeSelector style={{ marginTop: 0, justifyContent: 'space-between' }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Heading>Tenant ID Verification</Heading>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          ></div>
        </TlyfeSelector>
        <CardBox hasShadow hasBorderRadius style={{ marginTop: 25, position: 'relative', width: '100%' }}>
          {/* <ToolTip
            style={{ top: "0%", right: "-40%" }}
            heading={"Tool Tips"}
            ContentData={[
              "Why not use our GDPR compliant ID Verification tool",
            ]}
          /> */}
          <SubHeading>ID Verification details</SubHeading>
          <SubHeading style={{ marginBottom: 20 }}>
            Request that the Applicant Tenant(s) register with TLyfe to verify their ID and perform the "Right to Rent"
            obligations.
          </SubHeading>
          <Button
            type="solid"
            style={{ backgroundColor: variables.navyblue, alignSelf: 'left' }}
            onClick={() => {
              setIdVerificationModal(true);
            }}
            title={'Request ID Verification'}
          />
          <ToolTip
            style={{ top: '0%', right: '-40%' }}
            heading={'ID Verification'}
            ContentData={[
              'Use OpenBrix free ID verification to verify your tenants via tlyfe!',
              `If your tenant(s) is already verified, you can download their verified documentation.`,
              'If you click "Other Docs" you can see all the other documents the tenant has chosen to share.',
            ]}
          />
        </CardBox>

        {tenants.map((data, index) => {
          return (
            <CardBox hasShadow hasBorderRadius style={{ marginTop: '25px' }}>
              <DocumentWithStatusCard
                flowID={flowId}
                topAlignment="space-between"
                docValue={data?.verification?.document} //i.e {[{document_url:url}]}
                onClick={(data) => addDoc(data, index, 'verification')}
                // onRemove={(docIndex) =>
                //   removeDoc(index, docIndex, "verification")
                // }
                appUserID={data.app_user_id}
                // middleButtonText={
                //   data?.idverification == 0
                //     ? "Request Digital Verification"
                //     : null
                // }
                // onMiddleButtonClick={() =>
                //   dispatch(
                //     tenantActions.requestdigital(data?.opbrix_id, flowId)
                //   )
                // }
                // hideUpload={data?.idverification === 2 ? true : false}
                hideUpload={true}
                isPrimary={data.is_lead}
                name={data?.first_name + ' ' + data?.last_name}
                status={getDigitalVerificationStatus(data?.idverification).code_type}
                dateValue={data?.app_user?.idverification_started_at}
                showDate
                middleButtonText={'Documents page'}
                onMiddleButtonClick={
                  () => {}
                  // props.history.push({
                  //   pathname: `/dashboard/peview-tenants/${data?.opbrix_id}/${data?.last_name}`,
                  // })
                }
              />
            </CardBox>
          );
        })}
        <LineSeperator />
        <SubHeading>Right to Rent</SubHeading>
        {tenants.map((data, index) => {
          return (
            <CardBox hasShadow hasBorderRadius style={{ marginTop: '25px' }}>
              <DocumentWithStatusCard
                flowID={flowId}
                isPrimary={data.is_lead}
                name={data?.first_name + ' ' + data?.last_name}
                renderCustomBody={() => renderRightToRent(data)}
                hideUpload={true}
              />
            </CardBox>
          );
        })}
        <LineSeperator />
        <TlyfeSelector style={{ marginTop: 0, justifyContent: 'space-between' }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Heading>Tenant Referencing</Heading>
          </div>
        </TlyfeSelector>
        <CardBox
          hasShadow
          hasBorderRadius
          style={{ marginTop: 25, marginBottom: 25, position: 'relative', width: '100%' }}
        >
          <SubHeading>Reference details</SubHeading>
          <SubHeading style={{ marginBottom: 20 }}>
            Using FCC Paragon you can request your tenants be fully referenced prior to taking up this tenancy.
          </SubHeading>
          <Button
            type="custom"
            color={'white'}
            style={{ backgroundColor: variables.navyblue, alignSelf: 'left' }}
            onClick={() => {
              setTotalCost(0);
              setIsTeanantSelected(true);
              setShowReferencingModal(true);
            }}
            title={'Start Referencing'}
          />
        </CardBox>

        <div style={{ marginBottom: 40 }}>
          {tenants.map((data, index) => {
            return (
              <CardBox hasShadow hasBorderRadius style={{ marginTop: '25px', position: 'relative' }}>
                {/* {index === 0 && (
                  <>
                    <ToolTip
                      style={{ bottom: "0%", right: "-40%" }}
                      heading={"See Tenants Rent Record"}
                      ContentData={[
                        "Why not open up the tenant's rent record and see how great this tenant is?",
                      ]}
                    />
                    <ToolTip
                      style={{ bottom: "-150%", right: "-40%" }}
                      heading={"Tool tip title"}
                      ContentData={[
                        "Check with your landlords insurance provider, mortgage company or freeholder  the level of ID and Referencing required for this tenancy.",
                        "Please Note: If the property is subject to a licence, full referencing maybe required.",
                      ]}
                    />
                  </>
                )} */}
                <DocumentWithStatusCard
                  flowID={flowId}
                  isPrimary={data.is_lead}
                  name={data?.first_name + ' ' + data?.last_name}
                  renderCustomBody={() => renderVerificationStatus(data)}
                  onClick={(data) => addDoc(data, index, 'referencing')}
                  docValue={data?.referencing?.document}
                  onRemove={(docIndex) => removeDoc(index, docIndex, 'referencing')}
                  dateValue={data?.app_user?.full_referencing_started_at}
                  showDate
                />
              </CardBox>
            );
          })}
        </div>
        <LineSeperator />

        <Heading>Guarantors</Heading>
        <CardBox hasShadow={true} hasBorderRadius={true} style={{ marginBottom: '5%', width: '100%' }}>
          <div
            style={{
              display: 'flex',
              marginTop: 10,
              marginBottom: 20,
              flexDirection: 'column',
            }}
          >
            <SubHeading>
              If your tenant needs a guarantor, you have 2 options:
              <br />
              <br />
              1) You can add guarantor details if you have them at hand by clicking "complete now."
              <br />
              2) You can ask your tenant to provide their guarantor details by clicking "ask tenant.”
            </SubHeading>
          </div>
        </CardBox>
        {tenants &&
          tenants.map((data, index) => (
            <CardBox
              key={data?.app_user_id}
              style={{ marginBottom: '5%', backgroundColor: '#f7f7f7' }}
              hasShadow={true}
              hasBorderRadius={true}
            >
              <DocumentWithStatusCard
                flowID={flowId}
                topAlignment="space-between"
                docValue={data?.guarantor_details_doc}
                onClick={(data) => addDocUploadGurantor(data, index)}
                onRemove={(docIndex) => removeDocGurantor(index, docIndex, 'guarantor_details_doc')}
                dateValue={data?.gaurantor?.full_referencing_started_at}
                showDate
                appUserID={data.app_user_id}
                hideUpload={false}
                isPrimary={data.is_lead}
                name={
                  <SubHeading>
                    Guarantor Details for{' '}
                    <b>
                      {' '}
                      {data?.first_name} {data?.last_name}
                    </b>{' '}
                  </SubHeading>
                }
              />
              <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                <Button
                  type="custom"
                  onClick={() => {
                    setIsTeanantSelected(false);
                    setShowReferencingModal(true);
                  }}
                  title={'Start Reference'}
                  color={'white'}
                  style={{
                    backgroundColor: variables.navyblue,
                    alignSelf: 'right',
                    marginBottom: 15,
                    display: 'flex',
                  }}
                />
              </div>
              {data?.gaurantor?.show_card ? (
                <ContactCard
                  nonRemove
                  name={`${data?.gaurantor?.first_name} ${data?.gaurantor?.last_name}`}
                  onViewClick={() => {
                    handleChangeGuarantor(
                      {
                        target: {
                          name: 'show_card',
                          value: false,
                        },
                      },
                      index
                    );
                  }}
                />
              ) : (
                <React.Fragment>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="selection_status"
                      style={{ display: 'flex', flexDirection: 'row' }}
                      name="selection_status"
                      value={data?.gaurantor?.selection_status}
                      onChange={(e) => handleChangeGuarantor(e, index)}
                    >
                      <FormControlLabel
                        value="completenow"
                        control={
                          <Radio
                            style={{
                              color: variables.purple1,
                              fontSize: 10,
                              justifyContent: 'flex-end',
                            }}
                          />
                        }
                        label="Complete Now"
                      />
                      <FormControlLabel
                        value="asktenant"
                        control={
                          <Radio
                            style={{
                              color: variables.purple1,
                              fontSize: 10,
                              justifyContent: 'flex-end',
                            }}
                          />
                        }
                        label="Ask Tenant"
                      />
                    </RadioGroup>
                  </FormControl>

                  {data?.gaurantor?.selection_status ? (
                    <FormControl
                      component="fieldset"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div style={{ width: '45%', marginBottom: 15 }}>
                        <SubHeading style={{ fontSize: 14, width: '100%' }}>Title</SubHeading>
                        <Select
                          disabled={data?.gaurantor?.selection_status === 'asktenant' ? true : false}
                          value={data?.gaurantor?.title}
                          style={{
                            width: '100%',
                            height: '40px',
                          }}
                          onChange={(e) => handleChangeGuarantor(e, index)}
                          variant="outlined"
                          name="title"
                          placeholder="Title"
                        >
                          {/* {StringConstant.OPENFLOW_TITLES.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })} */}
                        </Select>
                      </div>
                      <TextInput
                        disabled={data?.gaurantor?.selection_status === 'asktenant' ? true : false}
                        title={'Guarantor First Name'}
                        isfullwidth={false}
                        type={'input'}
                        wrapperstyle={{ width: '22%', marginBottom: 15 }}
                        containerstyles={{ width: '100%' }}
                        onChange={(e) => handleChangeGuarantor(e, index)}
                        value={data?.gaurantor?.first_name}
                        name="first_name"
                      />
                      <TextInput
                        disabled={data?.gaurantor?.selection_status === 'asktenant' ? true : false}
                        title={'Guarantor Last Name'}
                        isfullwidth={false}
                        type={'input'}
                        wrapperstyle={{ width: '23%', marginBottom: 15 }}
                        containerstyles={{ width: '100%' }}
                        onChange={(e) => handleChangeGuarantor(e, index)}
                        value={data?.gaurantor?.last_name}
                        name="last_name"
                      />

                      <TextInput
                        disabled={data?.gaurantor?.selection_status === 'asktenant' ? true : false}
                        title={'Correspondence Address'}
                        isfullwidth={false}
                        type={'input'}
                        wrapperstyle={{ width: '45%', marginBottom: 15 }}
                        containerstyles={{ width: '100%' }}
                        onChange={(e) => handleChangeGuarantor(e, index)}
                        value={data?.gaurantor?.correspondece_address}
                        name="correspondece_address"
                      />
                      <TextInput
                        disabled={data?.gaurantor?.selection_status === 'asktenant' ? true : false}
                        title={'Post Code'}
                        isfullwidth={false}
                        type={'input'}
                        wrapperstyle={{ width: '50%', marginBottom: 15 }}
                        containerstyles={{ width: '100%' }}
                        onChange={(e) => handleChangeGuarantor(e, index)}
                        value={data?.gaurantor?.post_code}
                        name="post_code"
                      />
                      <TextInput
                        disabled={data?.gaurantor?.selection_status === 'asktenant' ? true : false}
                        title={'Email Address'}
                        isfullwidth={false}
                        type={'email'}
                        wrapperstyle={{ width: '45%', marginBottom: 15 }}
                        containerstyles={{ width: '100%' }}
                        onChange={(e) => handleChangeGuarantor(e, index)}
                        value={data?.gaurantor?.email}
                        name="email"
                      />
                      <InputNumber
                        disabled={data?.gaurantor?.selection_status === 'asktenant' ? true : false}
                        title={'Contact Number'}
                        isfullwidth={false}
                        type={'number'}
                        wrapperstyle={{ width: '50%', marginBottom: 15 }}
                        containerstyles={{ width: '100%' }}
                        onChange={(e) => handleChangeGuarantor(e, index)}
                        value={data?.gaurantor?.number}
                        name="number"
                      />

                      {data?.gaurantor?.selection_status === 'asktenant' ? (
                        <Button
                          type="stripe"
                          title={data?.gaurantor?.email ? 'Accept' : 'Submit'}
                          style={{
                            marginTop: '3%',
                            marginLeft: '72%',
                            borderColor: '#90C418',
                            color: '#90C418',
                          }}
                          onClick={() => {
                            if (!data?.gaurantor?.email) {
                              // dispatch(alertActions.success('Guarantor information has been requested from Tenant!'));
                            } else {
                              handleChangeGuarantor({ target: { name: 'show_card', value: true } }, index);
                            }
                          }}
                        />
                      ) : (
                        <Button
                          type="stripe"
                          title={'Save Guarantor'}
                          style={{
                            marginTop: '3%',
                            marginLeft: '72%',
                            borderColor: '#90C418',
                            color: '#90C418',
                          }}
                          onClick={() =>
                            handleChangeGuarantor(
                              {
                                target: {
                                  name: 'show_card',
                                  value: true,
                                },
                              },
                              index
                            )
                          }
                        />
                      )}
                    </FormControl>
                  ) : null}
                  <LineSeperator />
                </React.Fragment>
              )}
              {data?.gaurantor?.email && (
                <div style={{ marginTop: '2%' }}>
                  <Heading>Reference details</Heading>
                  {renderGaurantorVerificationStatus(data)}
                </div>
              )}
            </CardBox>
          ))}
        <CardFooter></CardFooter>

        <RentalWarrenty warranty={warranty} flowId={flowId}>
          {' '}
        </RentalWarrenty>

        <BottomButton
          showSendbutton={false}
          showContinueModal={false}
          onContinue={() => {
            _onSave();
          }}
        />
      </CardWrapper>

      {showReferencingModal && (
        <OpenFlowModal
          showModal={showReferencingModal}
          setShowModal={setShowReferencingModal}
          modalBody={renderReferencingModal()}
          showFooter={false}
          modalHeading="Reference"
          modalWidth={600}
        />
      )}
      {showReferencingModalError && (
        <OpenFlowModal
          showModal={showReferencingModalError}
          setShowModal={setShowReferencingModalError}
          modalBody={renderReferencingModalErr()}
          showFooter={false}
          modalHeading="Full Reference Errror!"
          modalWidth={600}
        />
      )}
      {showIdVerificatioinModal && (
        <OpenFlowModal
          showModal={showIdVerificatioinModal}
          setShowModal={setIdVerificationModal}
          modalBody={renderIdVerification()}
          showFooter={false}
          modalHeading="Id Verification"
          modalWidth={600}
        />
      )}
      {showLiteReferencingModal && (
        <OpenFlowModal
          showModal={showLiteReferencingModal}
          setShowModal={setShowLiteReferencingModal}
          modalBody={renderLiteReferencingModal()}
          showFooter={false}
          modalWidth={1000}
        />
      )}
      {showFullReferencingModal && (
        <OpenFlowModal
          showModal={showFullReferencingModal}
          setShowModal={setShowFullReferencingModal}
          modalBody={renderFullReferencingModal()}
          showFooter={false}
          modalHeading="Tenant Referencing"
          modalWidth={1000}
        />
      )}
    </Container>
  );
};

export default DrawerHoc({
  Component: TenantIdentification,
  showBackButton: true,
});
