import React, { useEffect, useState } from 'react';
import { View, Image } from 'react-native';
import Box from '../../components/box';
import Text from '../../components/text';
import getPlatformType from '../../helpers/helpers';
import { TextInputWrapper, Row, RowCol } from './index.style';
import Button from '../../components/button/Button';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import Urls from '../../services/Urls';
import ajax from '../../helpers/ajaxHelper';
import DropDownPicker from '../../components/picker/DropDownPicker';
import { useRoute } from '@react-navigation/native';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import Colors from '../../constants/Colors';
import DatePickerComponent from '../../components/datepicker/DatePickerComponent';
import { TouchableOpacity } from 'react-native-gesture-handler';
import PopUp from '../../components/popUp/PopupView';
import { WSnackBar } from 'react-native-smart-tip';

var layoutType = getPlatformType();

const PaymentDetails = ({ navigation }) => {
  const route = useRoute();
  const [state, setState] = useState({
    description: '',
    date: new Date(),
    amount: '',
    property_id: '',
    receipt: [],
    is_incoming: route.name === 'PaymentIncomingDetails' ? true : false,
    is_received: route.name === 'PaymentIncomingDetails' ? false : true,
  });
  const [paymentStatuses, setPaymentStatuses] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [properties, setProperties] = useState([]);
  const [mode, setMode] = useState('ADD');
  const dateUpdateInput = (date) => {
    setState({ ...state, date: date });
  };

  useEffect(() => {
    if (route?.params?.id) {
      setMode('VIEW');
      _getDetails(route?.params?.id);
    }
    if (route?.params?.type == 'EDIT') {
      setMode('EDIT');
    }
    ajax
      .get(Urls.Properties)
      .then(({ data }) => {
        if (data.status && data?.payload) {
          let temp = [];
          data?.payload?.forEach((element) => {
            temp = [...temp, { label: element.name, value: element.id }];
          });
          setProperties(temp);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });

    ajax
      .get(Urls.PAYMENT_TYPES) // payment status
      .then(({ data }) => {
        if (data.status && data?.data?.paymentStatusTypes) {
          let temp = [];
          data?.data?.paymentStatusTypes?.forEach((element) => {
            temp = [...temp, { label: element.name, value: element.id }];
          });
          // setPaymentTypes(temp);
          setPaymentStatuses(temp);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });

    ajax
      .get(Urls.PAYMENT_TYPES_2) // payment types
      .then(({ data }) => {
        if (data.status && data?.data?.paymentTypes) {
          let temp = [];
          data?.data?.paymentTypes?.forEach((element) => {
            temp = [...temp, { label: element.name, value: element.id }];
          });
          setPaymentTypes(temp);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  const _handleChange = (e) => {
    setState({ ...state, [e.label]: e.value });
  };
  const _getDetails = (id) => {
    ajax
      .get(Urls.Payments + '/' + id)
      .then(({ data }) => {
        if (data.status) {
          setState({
            ...state,
            ...{
              description: data?.payload?.description,
              date: data?.payload?.date ? new Date(data?.payload?.date) : '',
              amount: data?.payload?.amount,
              property_id: data?.payload?.property_id,
              tenant_id: data?.payload?.tenant_id,
              receipt: data?.payload?.receipt,
              payment_type_id: data?.payload?.payment_type_id,
              payment_status_id: data?.payload?.payment_status_id,
            },
          });
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onSave = () => {
    if (state.amount && state.date && state.description && state.property_id && state.payment_type_id) {
      ajax
        .post(Urls.ADD_PAYMENTS, state)
        .then(({ data }) => {
          if (data.status) {
            if (route?.params?.id) {
              navigation.navigate('PropertySection');
            } else {
              navigation.navigate('Payments');
            }
          } else {
            // alert(data.message);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    } else {
      const snackBarOpts = {
        data: 'Please complete all required fields',
        position: WSnackBar.position.TOP, // 1.TOP 2.CENTER 3.BOTTOM
        duration: WSnackBar.duration.LONG, //1.SHORT 2.LONG 3.INDEFINITE
        textColor: 'white',
        backgroundColor: 'red',
        actionText: '',
        actionTextColor: '#ff490b',
        onActionHide: (isSlideHide) => {
          // Click Action
        },
      };
      WSnackBar.show(snackBarOpts);
    }
  };
  const _onDelete = () => {
    ajax
      .delete(Urls.ADD_PAYMENTS + '/' + route?.params?.id)
      .then(({ data }) => {
        if (data.status) {
          if (route?.params?.id) {
            setIsVisible(false);
            navigation.navigate('Payments');
          }
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onUpdate = () => {
    if(!state.payment_status_id){
      delete state.payment_status_id;
    }
    
    ajax
      .put(Urls.ADD_PAYMENTS + '/' + route?.params?.id, state)
      .then(({ data }) => {
        if (data.status) {
          if (route?.params?.id) {
            navigation.navigate('Payments');
          } else {
            navigation.navigate('Payments');
          }
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getIsEditable = () => {
    if (mode === 'VIEW') {
      return true;
    } else {
      return false;
    }
  };
  console.log('modemode', mode);
  console.log('state', state);

  return (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text fontWeight={600} marginTop={30} marginBottom={20} fontSize={18} marginLeft={10}>
          {state?.is_incoming ? 'Incoming Payment' : 'Outgoing Payment'}
        </Text>
        {mode === 'VIEW' ? (
          <TouchableOpacity onPress={() => setMode('EDIT')}>
            <Text color={'#6D08C0'} fontWeight={600} marginTop={30} marginBottom={20} fontSize={18} marginLeft={10}>
              Edit
            </Text>
          </TouchableOpacity>
        ) : null}
      </View>
      <Box style={{ zIndex: 999 }}>
        <RowCol>
          <TextInputWrapper width={'100%'}>
            <TextInputComponent
              onChangeText={(e) => _handleChange({ value: e, label: 'description' })}
              value={state?.description}
              height={40}
              disabled={getIsEditable()}
              title={'Payment Description'}
              error={state?.description ? false : '*Required'}
            />
          </TextInputWrapper>
        </RowCol>
        <RowCol style={{ zIndex: 999 }}>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <TextInputComponent
              onChangeText={(e) => _handleChange({ value: e, label: 'amount', isNumeric: true })}
              value={state?.amount}
              keyboardType="numeric"
              height={40}
              title={'Amount (£)'}
              disabled={getIsEditable()}
              isAmount
              error={state?.amount ? false : '*Required'}
            />
          </TextInputWrapper>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <DatePickerComponent
              placeholder={'Date'}
              value={state?.date}
              onUpdateInput={(date) => {
                dateUpdateInput(date);
              }}
              isDisable={getIsEditable()}
              error={state?.date ? false : '*Required'}
            />
          </TextInputWrapper>
        </RowCol>
        <RowCol>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <DropDownPicker
              items={properties}
              title={'Property'}
              defaultValue={state?.property_id}
              onChangeItem={(e) => _handleChange({ value: e.value, label: 'property_id' })}
              disabled={mode === 'VIEW' ? true : false}
              error={state?.property_id ? false : '*Required'}
            />
          </TextInputWrapper>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <DropDownPicker
              items={[]}
              title={'Tenants'}
              onChangeItem={(e) => _handleChange({ value: e.value, label: 'tenant_id' })}
              disabled={mode === 'VIEW' ? true : false}
              // error={state?.tenant_id ? false : '*Required'}
            />
          </TextInputWrapper>
        </RowCol>

        <RowCol>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <DropDownPicker
              items={paymentTypes}
              title={'Payment Type'}
              defaultValue={state?.payment_type_id}
              onChangeItem={(e) => _handleChange({ value: e?.value, label: 'payment_type_id' })}
              disabled={mode === 'VIEW' ? true : false}
              error={state?.payment_type_id ? false : '*Required'}
            />
          </TextInputWrapper>
          {state?.is_incoming ? (
            <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
              <DropDownPicker
                items={paymentStatuses}
                title={'Payment Made?'}
                defaultValue={state?.payment_status_id}
                onChangeItem={(e) => _handleChange({ value: e.value, label: 'payment_status_id' })}
                disabled={mode === 'VIEW' ? true : false}
              />
            </TextInputWrapper>
          ) : null}
        </RowCol>
      </Box>

      <Box style={{ marginTop: 20 }}>
        <Text fontWeight={600} marginTop={10} marginBottom={10} fontSize={16} marginLeft={0}>
          Receipt Record
        </Text>

        <Button
          type={'upload'}
          childText={'Upload'}
          disabled={mode == 'EDIT'}
          onDataSuccess={(value) => _handleChange({ value: [...state.receipt, ...value], label: 'receipt' })}
        />

        {state?.receipt && state?.receipt.length
          ? state?.receipt.map((item, index) => (
              <Row style={{ paddingHorizontal: 20, marginTop: 10 }}>
                <Row style={{ width: '60%', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Image
                    style={{ height: 18, width: 13, marginRight: '4%' }}
                    resizeMode={'contain'}
                    source={require('./../../assets/images/Logo/downloadIcon/document-star.png')}
                  />
                  <TouchableOpacity                  
                   onPress={() => {
                    window.open(item.openbrixName);
                  }}>
                  <Text fontSize={15} fontWeight={'500'}>
                    {item?.name}
                  </Text>
                  </TouchableOpacity>
                </Row>
                <TouchableOpacity
                  onPress={() => {
                    let tempArr = state.receipt;
                    tempArr.splice(index, 1);
                    setState({ ...state, receipt: tempArr });
                  }}
                >
                  <Text fontWeight={'600'} color={Colors.light.lightPurple} fontSize={15}>
                    Remove
                  </Text>
                </TouchableOpacity>
              </Row>
            ))
          : null}
      </Box>
      {mode === 'ADD' ? (
        <View style={{ width: '100%', flexDirection: 'column', justifyContent: 'flex-end',alignItems:'flex-end' ,marginTop: '2%' }}>
          <Button type={'solid'} childText={'Save Payment'} onPress={_onSave} width={150} />
          <Button onPress={() => navigation.navigate('Payments')}
            type={'stripe'}
            childText={'Cancel'}
            color= {Colors.light.red}
            width={150}
          />
        </View>
      ) : null}
      {mode === 'VIEW' ? (
        <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: '2%' }}>
          <Button type={'stripe'} childText={'Delete'} onPress={() => setIsVisible(true)} width={150} />
        </View>
      ) : null}
      {mode === 'EDIT' ? (
        <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: '2%' }}>
          <Button type={'stripe'} childText={'Delete'} onPress={() => setIsVisible(true)} width={150} />
          <Button
            type={'solid'}
            childText={'Save Changes'}
            onPress={_onUpdate}
            width={150}
            style={{ marginRight: '4%' }}
          />
        </View>
      ) : null}

      <PopUp
        modalVisible={isVisible}
        titleText={'Delete Payment?'}
        titleColor={'#6D08C0'}
        subTitleText={'Are you sure you want to delete this payment?'}
        subTitleColor={'black'}
        actionButtons={[
          {
            text: 'Delete',
            width: 100,
            onPress: () => {
              _onDelete();
            },
          },
          {
            text: 'Cancel',
            width: 100,
            onPress: () => {
              setIsVisible(false);
            },
          },
        ]}
        closePopup={() => setIsVisible(false)}
      />
        {mode != 'ADD' ?
         <Button onPress={() => navigation.navigate('Payments')}
            type={'stripe'}
            childText={'Cancel'}
            color= {Colors.light.red}
            width={150}
            style={{ marginRight: '4%' }}
          />:null}

    </>
  );
};

export default drawerHoc({
  Component: PaymentDetails,
  title: 'PaymentDetails',
  showBackButton: true,
});
