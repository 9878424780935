import { Entypo } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { useIsFocused, useRoute } from '@react-navigation/native';

import Box from '../../components/box';
import Urls from '../../services/Urls';
import Knob from '../../components/knob';
import Text from '../../components/text';
import ajax from '../../helpers/ajaxHelper';
import Colors from '../../constants/Colors';
import ListCard from '../../components/listCard';
import Button from '../../components/button/Button';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import { Row, Right, Left, Center, BoxContainer, ImageContainer } from './index.style';
import { View } from '../../components/Themed';
import PageHeader from '../../components/pageHeader/pageHeader';
import { navigate } from '../../navigation/RootNavigation';

const PropertySection = ({ navigation }) => {
  const route = useRoute();
  const focused = useIsFocused();

  useEffect(() => {
    if (route?.params?.id) {
      _getDetails(route?.params?.id);
    }
  }, [focused]);
  const [state, setState] = useState({
    postcode: '',
    houseName: '',
    address1: '',
    city: '',
    country: '',
    property_name: '',
    property_worth: '',
    mortgage_provider: '',
    id: '',
    hubita_id: '',
  });
  const _getDetails = (id) => {
    ajax
      .get(Urls.Properties + '/' + id)
      .then(({ data }) => {
        if (data.status) {
          setState({
            ...state,
            ...{
              address1: data?.payload?.address_line1,
              postcode: data?.payload?.postcode,
              property_name: data?.payload?.name,
              city: data?.payload?.city,
              houseName: data?.payload?.house_name_num,
              property_worth: data?.payload?.price_worth,
              mortgage_provider: data?.payload?.mortgage_provider,
              hubita_id: data?.payload?.hubita_id,
              id: data?.payload?.id,
              country: data?.payload?.country,
              location_details_status: data?.payload?.location_details_status,
              room_details_status: data?.payload?.room_details_status,
              certificate_details_status: data?.payload?.certificate_details_status,
              property_requirements_status: data?.payload?.property_requirements_status,
              license_details_status: data?.payload?.license_details_status,
              percentage: data?.payload?.percentage,
              finance_details_status: data?.payload?.finance_details_status,
              utilities_details_status: data?.payload?.utilities_details_status,
              landlord_details_status: data?.payload?.landlord_details_status,
              tenant_details_status: data?.payload?.tenant_details_status
            },
          });
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  return (
    <>
      <PageHeader
        property_name={state?.property_name}
        property_worth={state?.property_worth}
        id={state?.id}
        percentage={state?.percentage}
        address1={state?.houseName}
        city={state?.city}
        postcode={state?.postcode}
        continueText="Summary"
        onContinuePress={() => navigation.navigate('PropertyInfo', { id: route?.params?.id })}
      />
      <ListCard
        onPress={() =>
          navigation.navigate('PropertyAddress', {
            status: state?.location_details_status,
            id: route?.params?.id,
            name: state?.property_name,
            key: 'location_details_status',
          })
        }
        leftIcon={'map-marker'}
        statusButton
        status={state?.location_details_status}
        title={'Location & Address'}
        key={'Location & Address'}
        description={'Please add the location information of your property.'}
      />
      <ListCard
        onPress={() =>
          navigation.navigate('LandlordDetails', {
            status: state?.landlord_details_status,
            id: route?.params?.id,
            name: state?.property_name,
            key: 'landlord_details_status',
          })
        }
        leftIcon={'view-list-outline'}
        statusButton
        status={state?.landlord_details_status}
        title={'Landlord Details'}
        description={'Please add your details or details of the landlord for this property.'}
      />
      <ListCard
        onPress={() =>
          navigation.navigate('PropertyDetails', {
            status: state?.room_details_status,
            id: route?.params?.id,
            name: state?.property_name,
            key: 'room_details_status',
          })
        }
        leftIcon={'view-list-outline'}
        statusButton
        status={state?.room_details_status}
        description={'Please add property details such as photo’s, floor plan and other amenities.'}
        title={'Property Details'}
      />
      <ListCard
        onPress={() =>
          navigation.navigate('Certification', {
            status: state?.certificate_details_status,
            id: route?.params?.id,
            name: state?.property_name,
            key: 'certificate_details_status',
          })
        }
        leftIcon={'file-certificate'}
        statusButton
        status={state?.certificate_details_status}
        title={'Certification'}
        description={'Please add the compliance certificates for this property.'}
      />
      <ListCard
        onPress={() =>
          navigation.navigate('Requirements', {
            status: state?.property_requirements_status,
            id: route?.params?.id,
            name: state?.property_name,
            key: 'property_requirements_status',
          })
        }
        leftIcon={'file-document'}
        statusButton
        status={state?.property_requirements_status}
        description={'Please add the habitation requirements required to let your property.'}
        title={'Requirements'}
      />
      <ListCard
        onPress={() =>
          navigation.navigate('Licensing', {
            status: state?.license_details_status,
            id: route?.params?.id,
            name: state?.property_name,
            key: 'license_details_status',
          })
        }
        leftIcon={'file-star'}
        statusButton
        status={state?.license_details_status}
        description={'Please add the local authority licencing requirements that is required.'}
        title={'Licencing'}
      />
      <ListCard
        onPress={() =>
          navigation.navigate('financeDetails', {
            status: state?.finance_details_status,
            id: route?.params?.id,
            name: state?.property_name,
            key: 'finance_details_status',
          })
        }
        localUrlImage={require('./../../assets/images/finance.png')}
        statusButton
        status={state?.finance_details_status}
        description={'Please add the financial information relating to this property.'}
        title={'Finance'}
      />

      <ListCard
        onPress={() =>
          navigation.navigate('Utilities', {
            status: state?.utilities_details_status,
            id: route?.params?.id,
            name: state?.property_name,
            key: 'utilities_details_status',
          })
        }
        localUrlImage={require('./../../assets/images/Utilties.png')}
        statusButton
        status={state?.utilities_details_status}
        title={'Utility'}
        description={'Please add the utility companies who serve your property.'}
      />
      <ListCard
        onPress={() =>
          navigation.navigate('PropertyTenantDetails', {
            status: state?.tenant_details_status,
            id: route?.params?.id,
            name: state?.property_name,
            key: 'tenant_details_status',
          })
        }
        localUrlImage={require('./../../assets/svg/teaant.svg')}
        statusButton
        status={state?.tenant_details_status}
        title={'Tenants'}
        description={`Please add the information of any tenants who are occupants of this property. You can leave this empty if the property is vacant.`}

      />
      <Button
        childText={'Save and Continue'}
        width={150}
        onPress={() => navigate('PropertyInfo', { id: route?.params?.id })}
        style={{ alignSelf: 'flex-end', marginVertical: 30, key: 'license_details_status' }}
      />
    </>
  );
};

export default drawerHoc({
  Component: PropertySection,
  showBackButton: true,
  // onBackCallback: () => navigate('PropertyDetails'),
});
