import React from 'react';
import {
    View,
    Text,
    SafeAreaView,
    Image,
    TouchableOpacity,
    Platform,
    ScrollView,
    Button,
    WebView
  } from "react-native";
  import styled from "styled-components";
  import {
    Container,
    SectionWrapper,
    DualView,
    TextBox,
    SectionTitle,
    SectionText,
    ImageBox,
    TeamsSection,
    MemberBoxWrapper,
    MemberBox,
    MemberImage,
    MemberName,
    MemberNameText,
    MemberDetails,
    MemberDetailsText,
    MemberHeader,
    TeamHeader,
    SectionPageTitle,
    SectionTitleTop,
    BulletText,
    Bullet,
    SectionInnerTitle,
    PrivacyGrid,
    PrivacyGridHeader,
    PrivacyGridBody,
    Asterick,
    PrivacyLeftBox,
    PrivacyRightBox,
    CookieTable,
    CookieCells,
    BulletInlineText,
  } from "./styles";

  const rowText=(text1,text2)=>{
    return(
      <View style={{flexDirection:'row',width:'100%',marginTop:10}}>
        <Text style={{fontSize:14,fontWeight:'bold'}}>{text1}<Text style={{fontSize:14,marginLeft:5,fontWeight:'400'}}>{text2}</Text> </Text>
        
      </View>
    )
   }

const Terms = () => {
    return (
        <View style={{ flex: 1, padding: 20, width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Text style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginVertical: 20 }}>Terms and Conditions </Text>
            {rowText("Definitions", "")}
            {rowText("Contract:", "the contract between Hubita and a Tenant in accordance with these terms and the Terms and Conditions.")}
            {rowText("Hubita:", "means Hubita Ltd registered in England and Wales with company number 11305881.")}
            {rowText("Identity Service:", "the service referred to in clause 1.1.1 ")}
            {rowText("Platform:", "the Hubita Platform found at www.hubita.co.uk.")}
            {rowText("Rent Record Service:", "the service referred to in clause 1.1.2 ")}
            {rowText("Rent Reference Service:", " the service referred to in clause 1.1.3. ")}
            {rowText("Services:", "the various services set out at clause 1. ")}
            {rowText("Tenant:", "a private tenant who uses the Services. ")}
            {rowText("Tenant Guide Service:", "the service referred to in clause 1.1.4 ")}
            {rowText("Terms and Conditions:", "the general terms and conditions of service of Hubita which can be found here.  ")}
            <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>1.{`       `}SERVICES</Text>
            <View style={{ paddingHorizontal: 30 }}>
                <Text style={{ fontSize: 14, marginTop: 20 }}>1.1{`        `}Subject to these terms, and the Terms and Conditions, such of the following services as the Tenant requests when registering with Hubita:</Text>
                <View style={{ paddingHorizontal: 30 }}>
                    <Text style={{ fontSize: 14, marginTop: 20 }}>1.1.1{`        `}access to the Platform for the purposes of uploading property details and  documents relating facilitating the process of the Agent letting a property to a tenant, such as template tenancy agreements and energy performance certificates; </Text>
                    <Text style={{ fontSize: 14, marginTop: 20 }}>1.1.2{`        `}access to a third party provided rent record service, subject to the third party’s own terms and conditions;</Text>
                    <Text style={{ fontSize: 14, marginTop: 20 }}>1.1.3{`        `}access to a third party tenant reference agency, subject to the third party’s own terms and conditions; and </Text>
                    <Text style={{ fontSize: 14, marginTop: 20 }}>1.1.4{`        `}access to the Tenant’s Guide to Letting available on the Platform.</Text>

                </View>
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>2.{`       `}RESPONSIBILITIES OF TENANTS </Text>
            <View style={{ paddingHorizontal: 30 }}>
                {[`2.1        Will at all times comply with the Terms and Conditions;`, `2.2       Ensure any information or documents they upload to the Platform are full, accurate and not misleading in any respect; `, `2.3        Ensure any documents they upload to the Platform are free from viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. `, `2.4        Will enter in to and comply with terms of service supplied to them by any third party provider of Services. `, `2.5        Will address any complaints relating to a third party provider in relation to the Services directly to that third party provider and copy in Hubita to all complaints and related correspondence. `].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>
                )}

            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>3.{`       `}CHARGES AND PAYMENT</Text>
            <View style={{ paddingHorizontal: 30 }}>
                {[`3.1        Details available here`, `3.2       All amounts due to Hubita shall be paid in full without any set-off, counterclaim, deduction or withholding (other than any deduction or withholding of tax as required by law).  `].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>
                )}
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>4.{`       `}TERMINATION</Text>
            <View style={{ paddingHorizontal: 30 }}>
                {[`4.1        Without affecting any other right or remedy available to it, Hubita reserves the right to immediately suspend or terminate access to the Platform and/or the applicable Services where a Tenant commits either a material breach of these terms or the Terms and Conditions.`].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>
                )}
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>5.{`       `}Limitation of liability: ATTENTION IS PARTICULARLY DRAWN TO THIS CLAUSE </Text>
            <View style={{ paddingHorizontal: 30 }}>
                {[`5.1        To the fullest extent permitted by law, Hubita accepts no liability whatsoever to a Tenant relating to either the Contract and/or the Services or for the acts or omissions of any third party provider in relation to the Services. `, `5.2       A Tenant agrees to fully indemnify Hubita for any costs or losses sustained or incurred arising directly or indirectly from that Tenant’s failure to comply with these terms, failure to comply with all applicable laws and regulations and in relation to any third party claims`, `5.3        Without prejudice to the generality of clauses 5.1 and 5.2: 

        5.3.1 A Tenant acknowledges that an identity produced by the  Identity Service may not be accepted as adequate proof of the Tenant’s identity, and that other forms of identity may be required from the Tenant; 

        5.3.2 A Tenant acknowledges that a rental payment may not be validated under the Rent Record Service; and 

        5.3.3 A Tenant acknowledges that a rental payment may not be uploaded to their credit record under the Rent Reference Service. `].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>
                )}
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>6.{`       `}GENERAL</Text>
            <View style={{ paddingHorizontal: 30 }}>
                {[`6.1        Mediation 

Subject to clause 1.6, If any dispute arises in connection with this agreement, the parties agree to enter into mediation in good faith to settle such a dispute and will do so in accordance with the Centre for Effective Dispute Resolution (CEDR) Model Mediation Procedure. Unless otherwise agreed between the parties within 10 Business Days of notice of the dispute, the mediator will be nominated by CEDR. `, `6.2       Governing law. 

The Contract, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by, and construed in accordance with the law of England and Wales. `, `6.3        Jurisdiction. 

Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with the Contract or its subject matter or formation.`].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>
                )}

            </View>
        </View>
    )
}
export default Terms;