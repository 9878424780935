import React, { useState } from 'react';
import { View, SafeAreaView, Platform, ScrollView, Linking } from 'react-native';
import { Container } from './styles';
const layoutType = getPlatformType();
import MediaNewsHub from './mediaNewsHub';
import MediaBlogHub from './mediaBlogHub';
import MediaVideoHub from './mediaVideoHub';
import getPlatformType from '../../helpers/helpers';
import TripleTabs from '../../components/tripleTabs/tripleTabs';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';

const MediaHub = (props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const tabList = [
    {
      label: 'News',
      target: <MediaNewsHub {...props} />,
      targetfooter: null,
    },
    {
      label: 'Blog',
      targetfooter: null,
      target: <MediaBlogHub {...props} />,
    },
    {
      label: 'Video',
      target: <MediaVideoHub {...props} />,
      targetfooter: null,
    },
  ];

  const setCurrentTab = (index) => {
    setCurrentTabIndex(index);
  };

  return (
    <SafeAreaView style={{ backgroundColor: '#FAFAFA' }}>
      <ScrollView>
        <Container style={{ paddingBottom: 100 }}>
          <TripleTabs
            isFullWidth={false}
            tabList={tabList}
            title={'Media Hub'}
            titlePink={true}
            setCurrentTabIndex={setCurrentTab}
          />
        </Container>
      </ScrollView>
    </SafeAreaView>
  );
};
export default drawerHoc({
  Component: MediaHub,
  showBackButton: true,
});
