import React from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const text = {
  fontSize: 14,
  fontFamily: 'lato',
};

const inputStyle = {
  width: '100%',
  padding: 2,
  borderRadius: '6px',
  borderWidth: 0,
};
const container = {
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
};
const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});
export const TextInput = (props) => {
  const classes = useStyles();
  return (
    <div
      style={
        ({ display: props.type == 'row' ? 'flex' : 'block', ...container },
        props.wrapperstyle ? { ...props.wrapperstyle } : {})
      }
    >
      <p
        style={
          ({ text }, props.inputTextStyle ? { ...props.inputTextStyle, fontFamily: 'lato' } : { fontFamily: 'lato' })
        }
      >
        {props.title}
      </p>
      <div
        style={Object.assign(
          {
            height: '40px',
            // width: props.isfullwidth ? "100%" : "50%",
            backgroundColor: 'white',
            border: props?.borderBox ? props?.borderBox : '2px solid #ebebeb',
            borderRadius: '6px',
          },
          props?.containerstyles ? props.containerstyles : {}
        )}
      >
        <TextField
          InputLabelProps={{ shrink: true }}
          InputProps={{ classes, ...props.inputProps }}
          style={inputStyle}
          value={props?.value}
          type={props.inputType ? props.inputStyle : null}
          {...props}
          isfullwidth=""
        />
        {props.error ? (
          <p style={{ marginTop: '5px', color: 'red', textAlign: 'right', marginTop: 10, fontFamily: 'lato' }}>
            This field is required!
          </p>
        ) : null}
      </div>
    </div>
  );
};

{
  /*
Example.(type=row/column)
<div style={{width:'50%'}}>
<TextInput placeholder="Here" type="row" title={"Holding text deposite"}/>   
</div>
*/
}
