import React, { useState } from 'react';
import { View } from 'react-native';
import getPlatformType from '../../helpers/helpers';
import { FontAwesome } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import { Container, ContainerScrollView, TableTools } from './index.style';
import SortList from '../../components/sortList';
import SearchCardComponent from '../../components/searchCard';
import PropertyTable from '../../components/propertyTable';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import TenantTable from '../../components/tenantTable';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
var layoutType = getPlatformType();
var DATA = [
  {
    name: 'Acorn Place',
    location: 'London, NW6 7HJ',
    date: '01/10/22',
  },
  {
    name: 'Acorn Place',
    location: 'London, NW6 7HJ',
    date: '01/10/22',
  },
  {
    name: 'Acorn Place',
    location: 'London, NW6 7HJ',
    date: '01/10/22',
  },
  {
    name: 'Acorn Place',
    location: 'London, NW6 7HJ',
    date: '01/10/22',
  },
];
var colData = ['Tenant', 'Property', 'Tenancy End Date'];
var sortData = [
  {
    name: 'A-Z',
    value: 'A to Z',
  },
  {
    name: 'Z-A',
    value: 'Z to A',
  },
  {
    name: 'ID',
    value: 'ID',
  },
];

const TenantArchive = ({ navigation }) => {
  const [sort, setSort] = useState({ name: 'A-Z', value: 'A-Z' });

  const setSortData = (name, value) => {
    setSort({
      name,
      value,
    });
  };
  return (
    <>
      <Container>
        <TableTools>
          <View style={{ width: layoutType === 'web' ? '80%' : layoutType === 'tab' ? '70%' : '100%' }}>
            <SearchCardComponent
      placehorderText={'Search Tenants'}
            leftText={'Tenant archive'} />
          </View>
          <SortList renderTitle={sort.name} setSort={setSortData} data={sortData} width={200} />
        </TableTools>

        <TenantTable
          data={DATA}
          colData={colData}
          rowIcon={<FontAwesome name="user" size={20} color={Colors.light.purple} />}
        />
      </Container>
    </>
  );
};

export default drawerHoc({
  Component: TenantArchive,
  showBackButton: true,
});

