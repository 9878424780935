import React, { useState, useEffect } from 'react';
import Button from '../../components/button/Button';
import SearchCardComponent from '../../components/searchCard';
import ListCard from '../../components/listCard';
import Urls from '../../services/Urls';
import { useRoute } from '@react-navigation/native';
import ajax from '../../helpers/ajaxHelper';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import {} from './index.style';
import DropDownPicker from '../../components/picker/DropDownPicker';
import Box from '../../components/box';
import FilePicker from '../../components/filePicker/filepicker.web';
import DocsComponent from '../../components/docsComponent';
import { Container, LeftSection, RightSection } from './index.style';
import Text from '../../components/text';
import { View } from 'react-native';
import PopUp from '../../components/popUp/PopupView';

const MyDocs = () => {
  const route = useRoute();
  const [state, setState] = useState({
    property_id: '',
    file_name: '',
    selected_files: [],
  });
  const [properties, setProperties] = useState([]);
  const [allPropertiesDocs, setAllPropertiesDocs] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [deteledIndex, setDeteledIndex] = useState(false);
  const [allFilteredPropertiesDocs, setAllFilteredPropertiesDocs] = useState([]);


  const getDocxDetailAPICall = () => {
    ajax
      .get(Urls.Properties)
      .then(({ data }) => {
        if (data.status && data?.payload) {
          let temp = [];
          data?.payload?.forEach((element) => {
            temp = [...temp, { label: element.name, value: element.id }];
          });
          setProperties(temp);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });

    // api for getting properties having docs
    ajax
      .get(Urls.GET_ALL_DOCS)
      .then(({ data }) => {
        if (data.status && data?.payload) {
          setAllPropertiesDocs(data?.payload);
          setAllFilteredPropertiesDocs(data?.payload);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    if (route?.params?.id) {
      setMode('VIEW');
      _getDetails(route?.params?.id);
    }
    if (route?.params?.type == 'EDIT') {
      setMode('EDIT');
    }

    // api for getting all properties for select
    getDocxDetailAPICall();
  }, []);

  const _handleChange = (e) => {
    setState({ ...state, [e.label]: e.value });
  };

  const _onSave = () => {
    if (!state?.property_id) {
      return alert('Please select a property');
    }
    if (!state?.selected_files?.length) {
      return alert('Please select a document to save');
    }

    let propertyDocs = [];
    state?.selected_files &&
      state?.selected_files?.map((doc) => {
        propertyDocs.push({
          document_name: doc?.name,
          document_url: doc?.openbrixName,
        });
      });

    const requestBody = {
      file_name: state?.file_name,
      property_documents: propertyDocs,
    };

    ajax
      .post(`${Urls.MY_DOCS}/${state?.property_id}`, requestBody)
      .then(({ data }) => {
        if (data.status) {
          navigation.navigate('MyDocs');
        }
        else {
          alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const onArchive = (id) => {
    console.log(id);
    ajax
      .put(Urls.DeleteDoc + id)
      .then(({ data }) => {
        if (data.status) {
          getDocxDetailAPICall();
          setDeteledIndex(null)
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const filter = (list, search) => {
    if (search) {
      const response = list.filter((allPropertiesDocs) => {
        return (
          allPropertiesDocs.name?.toLowerCase().includes(search.toLowerCase())
       
        );
      });
      return response;
    }
    return list;
  };

  const filterDocs = (search) => {
    setAllFilteredPropertiesDocs(filter(allPropertiesDocs, search));
  };

  return (
    <>
      <ListCard
        localUrlImage={require('./../../assets/icons/My Docs inside.png')}
        description={
          'This is your property documentation store. Please add all relevant documentation that you would like to keep and have access to. Your documents will be organised per property you have added to your account.'
        }
        title={'My Docs'}
      />
      {/* <SearchCardComponent placehorderText={'Search My Docs'} onChangeText={(e) => {filterDocs(e)}} leftText={'My Docs'} /> */}
      <Container style = {{marginTop: 24}}>
        <LeftSection>
          <Box>
            <DropDownPicker
              items={properties}
              title={'Property'}
              defaultValue={state?.property_id}
              onChangeItem={(e) => _handleChange({ value: e.value, label: 'property_id' })}
            />
          </Box>
        </LeftSection>
        <RightSection>
          <Box>
            <FilePicker
              onDataSuccess={(data) => {
                setState({ ...state, selected_files: [...state?.selected_files, ...data] });
              }}
              onDelete={(index) => {
                let deteledArray = state?.selected_files;
                deteledArray.splice(index, 1);

                setState({ ...state, selected_files: deteledArray });
              }}
              files={state?.selected_files}
            />
          </Box>
          <Button
            onPress={() => _onSave()}
            childText={'Save'}
            width={150}
            style={{ alignSelf: 'flex-end', marginVertical: 30 }}
          />
        </RightSection>
      </Container>
      <Text fontSize={15} bold>
        My Files
      </Text>
      <View style={{ marginBottom: 20 }}>
        <If condition={allFilteredPropertiesDocs.length > 0}>
          {allFilteredPropertiesDocs.map((val) => (
            <DocsComponent
              title={val.name}
              listOfDoc={val.property_documents}
              deleteFile={(data) => {
                setDeteledIndex(data);
                setIsVisible(true);
              }}
            />
          ))}
        </If>
      </View>
      <PopUp
        modalVisible={isVisible}
        titleText={'Archive files?'}
        titleColor={'#6D08C0'}
        subTitleColor={'black'}
        customSubtitle={
          <View>
            <Text>
              Are you sure you want to archive this file? (Hint this file will be archived and can be viewed
              <Text
                bold
                onPress={() => {
                  navigation.navigate('DocumentArchive');
                }}
                style={{ color: 'black' }}
              >
                {' '}
                here
              </Text>{' '}
              )
            </Text>
          </View>
        }
        actionButtons={[
          {
            text: 'Archive',
            width: 100,
            onPress: () => {
              onArchive(deteledIndex);
              setIsVisible(false);
            },
          },
          {
            text: 'Cancel',
            width: 100,
            onPress: () => {
              setIsVisible(false);
            },
          },
        ]}
        closePopup={() => setIsVisible(false)}
      />
    </>
  );
};
export default drawerHoc({
  Component: MyDocs,
  showBackButton: true,
});
