import React, { useState } from 'react';
import { FlatList, TextInput, TouchableOpacity, View } from 'react-native';

import PropTypes from 'prop-types';
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';
import Box from '../box';
import StatusButton from '../statusButton/statusButton';
import Button from '../button/Button';
import { DescView, IconView, Main } from './index.style';
const PlaceHolder = (props) => {
  const { isrow, header, icon, title, description, renderChild, buttonText, style, onRightTextPress } = props;
  return (
    <View style={[{ flexDirection: 'column' }, style]}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text fontWeight={600} fontSize={18} marginLeft={10}>
          {header}
        </Text>
        {onRightTextPress && (
          <Text onPress={onRightTextPress} color={'#7002BC90'} fontWeight={600} fontSize={18} marginLeft={10}>
            Edit
          </Text>
        )}
      </View>
      <Choose>
        <When condition={renderChild}>
          <Main style={{ height: 'auto' }}>
            <Box style={{ flex: 1 }}>{renderChild}</Box>
          </Main>
        </When>
        <Otherwise>
          <Main>
            <Box dashed style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <View
                style={
                  isrow
                    ? {
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                    : {
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                }
              >
                <IconView>
                  <MaterialCommunityIcons name="file-certificate" size={isrow ? 40 : 75} color={Colors.light.purple} />
                </IconView>
                <DescView>
                  <Text
                    fontSize={13}
                    fontWeight={'600'}
                    color={Colors.light.purple}
                    align={layoutType == 'phone' ? 'center' : 'left'}
                    style={isrow ? { marginTop: 10, textAlign: 'center' } : {}}
                  >
                    {title}
                  </Text>
                  <Text
                    fontSize={11}
                    marginBottom={5}
                    marginTop={10}
                    fontWeight={'500'}
                    color={'#B5BED5'}
                    align={layoutType == 'phone' ? 'center' : 'left'}
                  >
                    {description}
                  </Text>
                  <Button
                    style={isrow ? { alignSelf: 'center', marginTop: 4 } : ''}
                    type="solid"
                    childText={buttonText}
                    width={110}
                    onPress={props?.onPress}
                  />
                </DescView>
              </View>
            </Box>
          </Main>
        </Otherwise>
      </Choose>
    </View>
  );
};
PlaceHolder.propTypes = {};

PlaceHolder.defaultProps = {};

export default PlaceHolder;
//Eg.
{
  /* <TextInputComponent title={"Email Address"} onChangeText={(e)=>console.log(e)} error={"This Field is required"}/> */
}
