import styled from 'styled-components/native';
import Box from '../../components/box';
import getPlatformType from '../../helpers/helpers';
let layoutType = getPlatformType();
let ContainerScrollView, Container, TableTools;
if (layoutType == 'web') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 65%;
    // margin-left: 320px;
    padding: 10px;
    margin-top: 20px;
  `;
  TableTools = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  `;
} else if (layoutType == 'phone') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
    padding: 10px;
  `;
  Container = styled.View`
    width: 100%;
    padding: 10px;
  `;
  TableTools = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  `;
} else {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 90%;
    padding: 10px;
    // margin-left: 10%;
  `;
  TableTools = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `;
}
export { ContainerScrollView, Container, TableTools };
