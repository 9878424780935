import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons'; 

import Box from '../../components/box';
import Urls from '../../services/Urls';
import Knob from '../../components/knob';
import Text from '../../components/text';
import ajax from '../../helpers/ajaxHelper';
import Colors from '../../constants/Colors';
import ListCard from '../../components/listCard';
import Button from '../../components/button/Button';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import {
  Row,
  Right,
  Left,
  Center,
  BoxContainer,
  ImageContainer,
  CircleIcon,
  InfoSection,
  RemainderSection,
} from './index.style';
import PlaceHolder from '../../components/placeholder';
import { FlatList, TouchableOpacity, View } from 'react-native';
import CalenderComponent from '../../components/calendar';
import { navigate } from '../../navigation/RootNavigation';
import moment from 'moment';
const _ = require('lodash');
import getPlatformType from '../../helpers/helpers';
import CalendarList from '../../components/calendarList';
import PageHeader from '../../components/pageHeader';
import { colorsTheme } from '../tenants/tenantOnboarding/helpers/theme';
import { LineSeperator } from '../tenants/tenantOnboarding/atoms/backComponent/styles';
var layoutType = getPlatformType();

const PropertyInfo = ({ navigation }) => {
  const route = useRoute();
  const focused = useIsFocused();
  const [fEventsData, setFEventsData] = useState({});
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    if (route?.params?.id) {
      _getDetails(route?.params?.id);
      _getCalEvents(new Date().getMonth() + 1, new Date().getFullYear());
    }
  }, [focused]);
  const [state, setState] = useState({
    postcode: '',
    houseName: '',
    address1: '',
    city: '',
    country: '',
    property_name: '',
    property_worth: '',
    mortgage_provider: '',
    id: '',
    hubita_id: '',
    teanant: false,
  });
  const _getDetails = (id) => {
    ajax
      .get(`${Urls.Properties}/${id}?excludeDeleted=true`)
      .then(({ data }) => {
        if (data.status) {
          setState({
            ...state,
            ...{
              address1: data?.payload?.address_line1,
              postcode: data?.payload?.postcode,
              property_name: data?.payload?.name,
              city: data?.payload?.city,
              houseName: data?.payload?.house_name_num,
              property_worth: data?.payload?.price_worth,
              mortgage_provider: data?.payload?.mortgage_provider,
              hubita_id: data?.payload?.hubita_id,
              id: data?.payload?.id,
              country: data?.payload?.country,
              location_details_status: data?.payload?.location_details_status,
              room_details_status: data?.payload?.room_details_status,
              certificate_details_status: data?.payload?.certificate_details_status,
              property_requirements_status: data?.payload?.property_requirements_status,
              license_details_status: data?.payload?.license_details_status,
              percentage: data?.payload?.percentage,
              certificates: data?.payload?.certificates,
              licenses: data?.payload?.licenses,
              payments: data?.payload?.payments,
              teanant: data?.payload?.tenantDetails,
            },
          });
        } else {
          navigation.navigate('Properties');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const arrToObj = (arr, key) => {
    return arr.reduce((obj, item) => {
      obj[moment(item[key]).format('YYYY-MM-DD')] = {
        customStyles: {
          container: {
            backgroundColor: moment(item.date).isBefore(moment().format()) ? '#BDA6F4' : '#21B1C4',
            elevation: 4,
          },
          text: {
            color: 'white',
          },
        },
      };
      return obj;
    }, {});
  };
  const _getCalEvents = (month, year) => {
    ajax
      .get(Urls.GET_REMAINDERS_MONTHLY + month + '/' + year + '?propertyId=' + route?.params?.id)
      .then(({ data }) => {
        if (data.status) {
          if (data?.payload && data.payload.length) {
            setFEventsData(arrToObj(data?.payload, 'date'));
            setEventsData(data?.payload);
          } else {
            setFEventsData([]);
            setEventsData([]);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const GetBeuatyName = (name) => {
    if (name && name.length > 0) {
      let splitname = name.split('_');
      return splitname[0];
    }
  };
  const _listItem = (type, data, labels) => {
    return (
      <>
        {layoutType != 'phone' ? (
          <Row style={{ marginBottom: 10, width: '82%', marginTop: 10 }}>
            <Text fontSize={16} fontWeight={'600'}>
              {type}
            </Text>
            <Text fontSize={16} fontWeight={'600'}>
              Expiry Date
            </Text>
          </Row>
        ) : null}

        {labels.map((e) => (
          <FlatList
            data={data[e]}
            renderItem={({ item }) =>{            
             if(item?.status=="Yes")
              return(
              <Row
                style={{
                  paddingHorizontal: 5,
                  paddingVertical: 15,
                  borderColor: '#cdcdcd',
                  borderBottomWidth: 1,
                  // alignItems: 'center',
                  // flexWrap: 'wrap',
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    item?.document_url ? window.open(item?.document_url) : null;
                  }}
                  style={{ width: '50%' }}
                >
                  <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                    <MaterialCommunityIcons name={'file-certificate'} color={Colors.light.lightPurple} size={16} />
                    <Text style={{ textTransform: 'capitalize' }} fontSize={16} marginLeft={10}>
                      {GetBeuatyName(item?.name)}
                    </Text>
                  </Row>
                </TouchableOpacity>
                {layoutType == 'phone' ? (
                  <View style={{ width: '100%', flexDirection: 'row', marginTop: 10, justifyContent: 'space-between' }}>
                    <Text fontSize={16}>{moment(item?.expiry_date).format('DD/MM/YYYY')}</Text>
                    <MaterialCommunityIcons
                      onPress={() => window.open(item?.document_url)}
                      name={'download'}
                      color={Colors.light.lightPurple}
                      size={20}
                    />
                  </View>
                ) : (
                  <>
                    <Text fontSize={16}>{moment(item?.expiry_date).format('DD/MM/YYYY')}</Text>
                    {item?.document_url ? (
                      <MaterialCommunityIcons
                        onPress={() => window.open(item?.document_url)}
                        name={'download'}
                        color={Colors.light.lightPurple}
                        size={20}
                      />
                    ) : (
                      <View />
                    )}
                  </>
                )}
              </Row>
            )}
          }
          />
        ))}
      </>
    );
  };
  const _paymentList = (data) => {
    return (
      <FlatList
        data={data}
        ListHeaderComponent={() =>
          layoutType != 'phone' && (
            <Row style={{ marginBottom: 10, width: '70%', marginTop: 10 }}>
              <Text fontSize={16} fontWeight={'600'}>
                Description
              </Text>
              <Text fontSize={16} fontWeight={'600'}>
                Amount (£)
              </Text>
              <Text fontSize={16} fontWeight={'600'}>
                Date
              </Text>
            </Row>
          )
        }
        renderItem={({ item }) => (
          <TouchableOpacity onPress={() => navigate('PaymentOutgoingDetails', { id: item?.id })}>
            <Row
              style={{
                paddingHorizontal: 5,
                paddingVertical: 15,
                borderColor: '#cdcdcd',
                borderBottomWidth: 1,
                alignItems: 'center',
              }}
            >
              <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                {item.is_incoming ? (
                  <AntDesign name="doubleright" size={24} color={Colors.light.purple} />
                ) : (
                  <>
                    <AntDesign name="doubleleft" size={24} color={Colors.light.purple} />
                  </>
                )}
                <Text fontSize={16} marginLeft={0} width={80}>
                  {item.description}
                </Text>
              </Row>
              <Text fontSize={16}>{item?.amount}</Text>
              <Text fontSize={16}>{moment(item?.date).format('DD/MM/YYYY')}</Text>
              <MaterialCommunityIcons name={'chevron-right'} color={Colors.light.lightPurple} size={20} />
            </Row>
          </TouchableOpacity>
        )}
      />
    );
  };
  return (
    <View style={{ flex: 1 }}>
      <PageHeader
        property_name={state?.property_name}
        property_worth={state?.property_worth}
        id={state?.id}
        percentage={state?.percentage}
        address1={state?.houseName}
        city={state?.city}
        postcode={state?.postcode}
      />
      <View
        style={{
          flexDirection: layoutType == 'phone' ? 'column-reverse' : 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <InfoSection>
          <PlaceHolder
            onRightTextPress={() => {
              navigation.navigate('Certification', {
                status: state?.certificate_details_status,
                id: route?.params?.id,
                name: state?.property_name,
                key: 'certificate_details_status',
              });
            }}
            header={'Certificates'}
            title={'Does this property require a license?'}
            renderChild={
              !_.isEmpty(state?.certificates)
                ? _listItem('Certificate Type', state?.certificates, [
                    'eicr_certificate',
                    'epc_certificate',
                    'gas_certificate',
                    'other_certificate',
                  ])
                : null
            }
            onPress={() =>
              navigation.navigate('Certification', {
                status: state?.certificate_details_status,
                id: route?.params?.id,
                name: state?.property_name,
                key: 'certificate_details_status',
              })
            }
            description={`Keep track of licenses and applications by uploading them to your property profile along with their expiry date`}
            buttonText={'Add Certificate'}
          />
          <PlaceHolder
            header={'Licences'}
            title={'Does this property require a licence?'}
            onRightTextPress={() => {
              navigation.navigate('Licensing', {
                status: state?.license_details_status,
                id: route?.params?.id,
                name: state?.property_name,
                key: 'license_details_status',
              });
            }}
            renderChild={
              !_.isEmpty(state?.licenses)
                ? _listItem('License Type', state?.licenses, [
                    'additional_license',
                    'hmo_license',
                    'other_license',
                    'selective_license',
                  ])
                : null
            }
            onPress={() =>
              navigation.navigate('Licensing', {
                status: state?.license_details_status,
                id: route?.params?.id,
                name: state?.property_name,
                key: 'license_details_status',
              })
            }
            description={`Keep track of licences and applications by uploading them to your property profile along with their expiry date`}
            buttonText={'Add Licence'}
          />
          <PlaceHolder
            header={'Payments'}
            onRightTextPress={() => {
              navigation.navigate('Payments');
            }}
            title={'Does you have any outgoing payment for this property?'}
            renderChild={!_.isEmpty(state?.payments) ? _paymentList(state?.payments) : null}
            icon={''}
            description={`Keep a record of all outgoing payments or costs for your property, this will help with management & future tax returns.`}
            buttonText={'New Payment'}
            onPress={() => navigation.navigate('PaymentOutgoingDetails')}
          />
          <PlaceHolder
            header={'Tenants'}
            title={'Vacant property'}
            description={'Once a tenant is added their information will become available'}
            onRightTextPress={() => {
              navigation.navigate('PropertyTenantDetails', { id: route?.params?.id })
            }}
            renderChild={
              state?.teanant?.length ? (
                <View style={{ padding: 3 }}>
                  <TouchableOpacity 
                  onPress={() => {
                    navigation.navigate('PropertyTenantDetails', { id: route?.params?.id })
                  }}
                  style={{ height: 30, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Ionicons style={{ marginRight: 10 }} name="person" size={24} color={colorsTheme.purple} />
                      <Text bold={300} fontSize={16}>
                        {state?.teanant[0].tenant?.first_name + ' ' + state?.teanant[0].tenant?.last_name}
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <MaterialCommunityIcons name={'chevron-right'} color={Colors.light.lightPurple} size={20} />
                    </View>
                  </TouchableOpacity>
                  <LineSeperator />
                  <View style={{ height: 20, flexDirection: 'row', justifyContent: 'space-between',alignItems: 'center', marginBottom: 19,marginTop:10 }}>
                      <Text  fontSize={16}>Start of Tenancy</Text>
                      <Text fontSize={15}>
                        {state?.teanant[0].tenancy?.tenancy_start_date? moment(state?.teanant[0].tenancy?.tenancy_start_date).format('DD/MM/YYYY'):"-"}
                      </Text>
                  </View>
                  <View style={{ height: 20, flexDirection: 'row', justifyContent: 'space-between',alignItems: 'center', marginBottom: 19 }}>
                      <Text fontSize={16}>End of Tenancy</Text>
                      <Text fontSize={15}>
                      {state?.teanant[0].tenancy?.tenancy_end_date? moment(state?.teanant[0].tenancy?.tenancy_end_date).format('DD/MM/YYYY'):"-"}
                      </Text>
                  </View>
                  <View style={{ height: 20, flexDirection: 'row', justifyContent: 'space-between',alignItems: 'center' }}>
                      <Text  fontSize={16}>Rental Amount</Text>
                      <Text fontSize={15}>
                        {state?.teanant[0].tenancy?.rental_amount?"£ "+state?.teanant[0].tenancy?.rental_amount:"-" }
                      </Text>
                  </View>
                </View>
              ) : null
            }
            onPress={() => navigation.navigate('PropertyTenantDetails', { id: route?.params?.id })}
            buttonText={'Add tenant'}
          />
        </InfoSection>
        <RemainderSection>
          <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
            <Text fontWeight={600} marginTop={20} fontSize={18}>
              Remainder
            </Text>

            <Text
              onPress={() => navigate('AddReminder', { type: 'other' })}
              color={Colors.light.purple}
              fontWeight={600}
              marginTop={20}
              fontSize={18}
            >
              Add
            </Text>
          </View>
          <Box style={{ width: '100%', marginTop: 20 }}>
            <CalenderComponent markers={fEventsData} onMonthChange={(data) => _getCalEvents(data.month, data.year)} />
            <CalendarList eventList={eventsData} />
          </Box>
        </RemainderSection>
      </View>
    </View>
  );
};

export default drawerHoc({
  Component: PropertyInfo,
  showBackButton: true,
});
