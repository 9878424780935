import React from 'react';
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
  WebView
} from "react-native";
import Hyperlink from 'react-native-hyperlink'
import styled from "styled-components";
import {
  Container,
  SectionWrapper,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  MemberDetails,
  MemberDetailsText,
  MemberHeader,
  TeamHeader,
  SectionPageTitle,
  SectionTitleTop,
  BulletText,
  Bullet,
  SectionInnerTitle,
  PrivacyGrid,
  PrivacyGridHeader,
  PrivacyGridBody,
  Asterick,
  PrivacyLeftBox,
  PrivacyRightBox,
  CookieTable,
  CookieCells,
  BulletInlineText,
} from "./styles";
import getPlatformType from "../../helpers/helpers";

const rowText = (text1, text2) => {
  return (
    <View style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
      <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{text1}</Text>
      <Text style={{ fontSize: 14, marginLeft: 5 }}>{text2}</Text>
    </View>
  )
}

const Privacy = () => {
  const linkStyle = { color: '#2980b9', fontSize: 16 };
  const layoutType = getPlatformType();;

  return (
    <View style={{ flex: 1, padding: 20, width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
      <Hyperlink linkStyle={linkStyle} onPress={(url) => openLink(url)}>

        <SectionPageTitle>Data Privacy Notice – for Customers (Tenants, Landlords and other Individuals).</SectionPageTitle>
        <SectionTitle>What are Cookies?</SectionTitle>
        <SectionText> Data Controller: Hubita </SectionText>
        <SectionText>
          Point of Contact: enquiry@hubita.co.uk
        </SectionText>
        <SectionText>
          This privacy policy applies to all
          processing activities involving personal data carried out by Hubita Limited
          (hereinafter referred to as “the Company, “the Organisation”, “we” or “us”).
        </SectionText>
        <SectionText>
          The Company is the data controller in
          respect of personal data that we process in connection with our business
          (including the products and services we provide).
        </SectionText>
        <SectionText>
          The Company collects personal information
          about you when you visit our website, when you enrol for our services, when you
          use our online or offline services, or when you contact us. We may use this
          personal information along with other information collected or generated during
          our relationship with you. We collect your contact information, information
          about your use of our services, details of any payors or beneficiaries and
          marketing choices.
        </SectionText>
        <SectionText>
          THE COMPANY is committed to protect and
          respect your privacy by being transparent about how it collects and uses that
          data and to meeting its data protection obligations. The purpose of this notice
          is to explain:
        </SectionText>

        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>1.{`       `}What information does THE COMPANY collect?</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>2.{`       `}How we collect personal data?</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>3.{`       `}Why does THE COMPANY process personal data?</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>4.{`       `}How and why we share your personal data</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>5.{`       `}Transferring personal data overseas</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>6.{`       `}How does THE COMPANY protect data?</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>7.{`       `}For how long does THE COMPANY keep data?</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>8.{`       `}Purposes for which we will use your personal data</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>a.{`       `}What data do we process,</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>b.{`       `}How do we process your data,</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>c.{`       `}Legal basis to process your data</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>9.{`       `}Your rights</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>10.{`       `}Links to other websites</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>11.{`       `}Changes to the privacy policy</Text>

        <SectionText>It is important that you read this privacy
          notice carefully to ensure you are fully aware of how and why we are using your
          data.
        </SectionText>
        <SectionText>If you have any questions about this
          privacy notice, data protection generally or you wish to exercise your legal
          rights please contact our Data Protection Officer (DPO) using the details set
          out in “Your rights” section below.</SectionText>

        <SectionTitle>1. What information does THE COMPANY collect?</SectionTitle>

        <SectionText>
          We may collect, use, store and/or transfer
          different kinds of personal data about you. What we mean by personal data is
          any information about an individual from which that person can be identified
          (either by itself or when combined with other information). We will limit the
          collection and processing of personal data to what is necessary to achieve one
          or more purpose(s) as identified in this notice. The personal data we collect
          may include:
        </SectionText>

        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Basic personal data to identify you such as your
          first name, maiden name, last name, username or similar identifier, marital
          status, title, date of birth;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Your contact information including your email
          address, address and telephone numbers;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Financial information – including bank account
          details, card payment details and transactional information and history;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Products and services provided to you;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Online information and online activity based on
          your interaction with us, our websites and applications for example your
          internet protocol (IP) address, your login data, browser type and version, time
          zone setting and location, browser plug-in types, searches, site visits and
          versions, operating system and platform, and other technology on the devices
          you use to access this website;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Images and personal appearance such as copies of
          your passport or driver’s licence or CCTV images;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Profile Data which may include your username and
          password, purchases or orders made by you, your interests, preferences,
          feedback and survey responses; and
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Usage Data including statistical data including
          information about how you use our website, products and services.
        </BulletInlineText>

        <SectionText>
          Marketing and Communications Data includes
          your preferences in receiving marketing from us and our third parties and your
          communication preferences.
        </SectionText>

        <SectionText>
          It is important that the personal data we
          hold about you is accurate and current. Please keep us informed if your
          personal data changes during your relationship with us.
        </SectionText>


        <SectionTitle>2. How we collect personal data?</SectionTitle>

        <SectionText>
          THE COMPANY collects this information in a
          variety of ways. For example:
        </SectionText>

        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          data is collected through application forms, CVs
          or biographies;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          obtained from your passport or other identity
          documents;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          from forms completed by you;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          from correspondence with you;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          or through calls, meetings or other types of
          exchange;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          while providing customer support, consultation,
          live chat, or other interaction;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          through the process of maintaining and upgrading
          our services; through means such as communications protocols and e-mail
          communications;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          through our mobile applications;
        </BulletInlineText>

        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          when information is provided to us from third
          parties authorised under applicable law to initiate transactions or perform
          account-related services on your behalf, such as account information service
          providers or payment initiation service providers.
        </BulletInlineText>

        <SectionTitle>3. Why does THE COMPANY process personal data?</SectionTitle>

        <SectionText>
          THE COMPANY needs to use your personal
          information to perform its contract with you or provide our services to you. To
          conduct a payment or money transfer, or to enter into a foreign exchange
          derivative contract with us and thus enable you to become a client, you must
          provide certain information required by us to execute the transaction and to
          enable us to comply with our legal obligations associated with providing our
          services. Failure to provide some of your personal information may affect our
          ability to provide our services to you. We will inform you when information is
          required in order to use our services.
        </SectionText>

        <SectionText>
          We use the information you provide and that
          we collect from other people for the following legal bases and legitimate
          business purposes:
        </SectionText>


        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
            Providing our services to you:
          </Text>
          This
          includes using information necessary performing contracts or for taking steps
          to enter into a contract with you, including conducting money transfers,
          payment services, or delivering other products or services.
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
            Legal and compliance purposes:
          </Text>
          This
          includes using your personal information to comply with legal and regulatory
          duties, such as anti-money laundering and counter-terrorist financing,
          detection, prevention and prosecution of fraud and theft as well as preventing
          illegitimate or prohibited use of our services. This may also include
          establishing, exercising, or defending legal rights and claims, monitoring and reporting
          compliance issues, or responding to legal process. Additionally, this includes
          using your details to validate and authenticate your identity and using third
          parties to help us do this, as well as retaining records containing your
          personal information as required under applicable law.
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
            Legitimate business purposes:
          </Text>
          We
          use your personal information to analyse and improve our products, services,
          operations, the running of our websites and your customer experience. We also
          use your personal information to provide customer service, to conduct other
          administrative tasks necessary to provide our services, to help manage risks
          related to security, fraud and identity, and to protect the security and
          integrity of our technology, systems, and services. We conduct analysis of the
          information we hold to better understand our customers. We may also conduct
          credit checks on you and your business using a licensed credit reference
          agency.
        </BulletInlineText>


        <SectionTitle>4. How and why we share your personal data</SectionTitle>

        <SectionText>
          The Organisation may from time to time
          share your personal data with the following organisations who are also required
          to keep your information confidential, safe and secure:
        </SectionText>

        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Third parties, commercial partners, agents,
          professionals and subcontractors who provide products, services and
          administrative support to the Company;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Third parties or professionals who may be
          engaged on your behalf;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Where we are required by law and law enforcement
          agencies, judicial bodies, credit reference agencies, fraud prevention
          agencies, governmental entities, tax authorities or regulatory bodies around
          the world;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Where required as part of any proposed sale,
          reorganisation, transfer, financial arrangement, asset disposal or other
          transaction relating to our business and/or business assets;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Anyone else with your permission.
        </BulletInlineText>


        <SectionTitle>5. Transferring personal data overseas</SectionTitle>

        <SectionText>
          From time to time we may share your
          personal data with organisations in other countries including organisations
          within the Hubita network.
        </SectionText>

        <SectionText>
          Additionally, when you send or receive
          money to or from another country, we may send some of your personal information
          to country as required or permitted by law. The privacy law in countries to
          which personal information is transferred may not provide the same level of data protection as is in force within the
          EEA. If this is the case, personal information transferred outside the EEA will
          be protected by EU-approved mechanisms for transferring data internationally,
          including appropriate contractual clauses, as required by law.
        </SectionText>


        <SectionTitle>6. How does THE COMPANY protect data?</SectionTitle>

        <SectionText>
          THE COMPANY takes the security of your data
          seriously. THE COMPANY has internal policies and controls in place to ensure
          that your data is not lost, accidentally destroyed, misused or disclosed, and
          is not accessed except by its employees in the performance of their duties.
        </SectionText>

        <SectionText>
          Where THE COMPANY engages third parties to
          process personal data on its behalf, they do so on the basis of written
          instructions, are under a duty of confidentiality and are obliged to implement
          appropriate technical and organisational measures to ensure the security of data.
        </SectionText>



        <SectionTitle>7. For how long does THE COMPANY keep data?</SectionTitle>

        <SectionText>
          We will not retain your personal data
          longer than is necessary. The retention periods for which THE COMPANY will hold
          your personal data will vary depending on its category including but not
          limited to the nature and type of record, the nature of the activity, the
          product or service and any applicable legal or regulatory requirements. Your
          personal information will be retained in accordance with statutory periods
          contained in regulations applicable to financial transactions, including those
          in anti-money laundering, anti-terrorist financing and other laws applicable to
          us. For the most part, your personal data will be retained for no longer than
          seven years after the end of our relationship, unless there is an overarching
          regulatory reason for holding onto it for longer.
        </SectionText>

        <SectionTitle>8. Purposes for which we will use your
          personal data</SectionTitle>

        <SectionText>
          We have set out below, in a table format, a description of all the
          ways we plan to use your personal data, and which of the legal bases we rely on
          to do so. We have also identified what our legitimate interests are where
          appropriate.
        </SectionText>

        <SectionText>
          Note that we may process your personal data for more than one lawful
          ground depending on the specific purpose for which we are using your data.
          Please contact us if you need details about the specific legal ground we are
          relying on to process your personal data where more than one ground has been
          set out in the table below.
        </SectionText>

        <SectionTitle>9. Your rights</SectionTitle>

        <SectionText>
          You have the right to know if we are
          processing your personal information, and to ask us for a copy of your
          information free of charge. You have the right to request a structured and
          machine-readable copy of certain information you have provided to us. We may
          charge you a reasonable fee for providing the information, or not act upon your
          request, if the request is manifestly unfounded or excessive. You have the
          right to stop us sending you marketing communications. You have the right to
          ask us to correct information about you that is incomplete, inaccurate or
          out-of-date. You have the right to ask us to erase certain information about
          you, to restrict certain uses of your information, and also to object to
          certain uses of it. You also have the right to lodge a complaint with a
          supervisory authority about our processing of personal information. When we
          receive a request, we may ask for additional information from you to verify
          your identity.
        </SectionText>

        <SectionText>
          To exercise these rights, please contact
          our data protection officer (DPO) at Hubita through the following channels:
        </SectionText>

        <SectionText>
          Data Protection
          Officer - enquiry@Hubita.co.uk
        </SectionText>

        <SectionTitle>10. Links to other websites</SectionTitle>

        <SectionText>
          Within our website we may have links to
          third party websites, plug-ins and applications. Clicking those links may
          enable third parties to share or collect your personal data. Hubita do not
          control such third-party websites and are not responsible for their privacy
          statements or the contents of those websites. We would encourage you to read
          the privacy policy of every website you visit.
        </SectionText>

        <SectionTitle>11. Changes to the privacy notice</SectionTitle>

        <SectionText>
          We keep our privacy notice under regular
          review. Any changes to our privacy notice in the future will be posted on this
          page. We encourage you to review this page regularly to identify any updates or
          changes to our privacy notice.
        </SectionText>
      </Hyperlink >
    </View>
  )
}
export default Privacy;