import React, { useEffect, useState } from 'react';
import { View, FlatList } from 'react-native';
import StatusBar from '../../components/statusBar';
import Box from '../../components/box';
import { FontAwesome } from '@expo/vector-icons';
import Text from '../../components/text';
// import DrawerMenu from '../drawerMenu/DrawerMenu';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
import { Container, ContainerScrollView, TextInputWrapper, Row, HorizontalLine, RowCol, AddressSelect } from './index.style';
import CalenderComponent from '../../components/calendar';
import CalendarList from '../../components/calendarList';
import { HOME } from './../../constants/svg';
import Button from '../../components/button/Button';
import SearchCardComponent from '../../components/searchCard';
import PropertyTable from '../../components/propertyTable';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import ListCard from '../../components/listCard';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import Urls from '../../services/Urls';
import ajax from '../../helpers/ajaxHelper';
import DropDownPicker from '../../components/picker/DropDownPicker';
import { useRoute } from '@react-navigation/native';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import CheckBox from '../../components/checkbox';
import MarkAsComplete from '../../components/markAsComplete/MarkAsComplete';
var layoutType = getPlatformType();
import { useIsFocused } from '@react-navigation/native';
import Select from 'react-select';
import { showSuccesToast } from '../tenants/tenantOnboarding/helpers';
const PropertyAddress = ({ navigation }) => {
  const [state, setState] = useState({
    postcode: '',
    houseName: '',
    address1: '',
    city: '',
    country: '',
    property_name: '',
    property_worth: '',
    mortgage_provider: '',
    id: '',
    hubita_id: '',
  });
  const [postcodes, setPostcodes] = useState([]);
  const [selectedAddress, setAddress] = useState('');
  const [mortageProviders, setMP] = useState([]);
  const route = useRoute();
  const focused = useIsFocused();
  useEffect(() => {
    _getMortgageProvider();
    if (route?.params?.id) {
      _getDetails(route?.params?.id);
    }
  }, [focused]);
  const _handleChange = (e) => {
    setState({ ...state, [e.label]: e.value });
  };
  const getAddress = (address,isAuto) => {
    console.log("addressaddress",address)
    let displayUrl = '';
    if(isAuto){
      displayUrl=address?.SINGLE_LINE_ADDRESS? address?.SINGLE_LINE_ADDRESS:"-"
    }else{
      if (address?.SUB_BUILDING) {
        displayUrl = displayUrl + address?.SUB_BUILDING + ',' + ' ';
      }
      if (address?.BUILDING_NAME) {
        displayUrl = displayUrl + address?.BUILDING_NAME + ',' + ' ';
      }
      if (address?.BUILDING_NUMBER) {
        displayUrl = displayUrl + address?.BUILDING_NUMBER + ',' + ' ';
      }
      if (address?.STREET_NAME) {
        displayUrl = displayUrl + address?.STREET_NAME;
      }
      if (address?.LOCALITY) {
        displayUrl = displayUrl + address?.LOCALITY;
      }
    }
   
    return displayUrl;
  };
  const _getDetails = (id) => {
    ajax
      .get(Urls.Properties + '/' + id)
      .then(({ data }) => {
        if (data.status) {
          setState({
            ...state,
            ...{
              address1: data?.payload?.address_line1,
              postcode: data?.payload?.postcode,
              property_name: data?.payload?.name,
              city: data?.payload?.city,
              houseName: data?.payload?.house_name_num,
              property_worth: data?.payload?.price_worth,
              mortgage_provider: data?.payload?.mortgage_provider,
              hubita_id: data?.payload?.hubita_id,
              id: data?.payload?.id,
              country: data?.payload?.country,
            },
          });
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onAddressSelect = (address) => {
    setState({
      ...state,
      ...{
        address1: `${address?.value.BUILDING_NAME ? address?.value.BUILDING_NAME + ',' : ''} ${address?.value.STREET_NAME}`,
        postcode: address?.value.POSTCODE2,
        city: address?.value.TOWN_NAME,
        houseName: address?.value.SUB_BUILDING || address?.value.BUILDING_NUMBER,
        country: 'UK',
        property_name: address?.value.BUILDING_NAME,
      },
    });
  };

  const _onSave = () => {
    const requestBody = {
      name: state?.property_name,
      house_name_num: state?.houseName,
      address_line1: state?.address1,
      postcode: state?.postcode,
      city: state?.city,
      country: state?.country,
      price_worth: state?.property_worth?.toString(),
      mortgageProvider_id: state?.mortgage_provider,
      id: route?.params?.id,
    };
    ajax
      .post(Urls.Properties, requestBody)
      .then(({ data }) => {
        if (data.status) {
          // showSuccesToast(data?.data);
          if (route?.params?.id) {
            
            navigation.navigate('PropertySection', { id: route?.params?.id });
          } else {
            // navigation.navigate('Properties', { id: route?.params?.id });
            navigation.navigate('PropertySection', { id: data?.payload?.id });
          }
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _getMortgageProvider = () => {
    ajax
      .get(Urls.MortgageProvider)
      .then(({ data }) => {
        if (data.status) {
          let temp = [];
          data?.data.forEach((element) => {
            temp = [...temp, { label: element.name, value: element.id }];
          });
          setMP(temp);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onPostcodeSearch = () => {
    if (!state?.postcode) {
      alert('Please enter postcode value');
    } else {
      ajax
        .get(Urls.SearchAddress + '?postCode=' + state?.postcode)
        .then(({ data }) => {
          if (data.status) {
            try {
              // if (Array.isArray(data?.data)) {
              //   let temp = [];
              //   data?.data.forEach((element) => {
              //     let label = getAddress(element);
              //     temp = [...temp, { label: label, value: JSON.stringify(element) }];
              //   });
              //   setPostcodes(temp);
              // } else {
              //   alert('Postcode not found');
              //   setPostcodes([]);
              // }

              // NOTE: new logic as per Andrew updated search postcodes API
              if(data?.data?.message) {
                alert(data?.data?.message);
                setPostcodes([]);
              }

              if (data?.data?.results) {
                let temp = [];

                for (let key in data?.data?.results) {
                  let obj = data?.data?.results[key];
                  let label = getAddress(obj,true);
                  temp = [...temp, { label: label, value: JSON.stringify(obj) }];
                }

                setPostcodes(temp);
              }
            } catch (err) {
              setPostcodes([]);
            }
          } else {
            alert(data.message);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  const customStyles = {  
    control: base => ({
      ...base,
      fontFamily: 'lato',
    }),
    menu: (provided, state) => ({
      ...provided,
      fontFamily: 'lato',
    }),
  }

  const options = postcodes.map((p) => { 

    let address = JSON.parse(p.value);
    let fullAddress = `${address?.BUILDING_NUMBER} ${address?.BUILDING_NAME ? address?.BUILDING_NAME + ',' : ''} ${address?.STREET_NAME}`;
      return { value: address, label: p?.label};
    
  });

  return (
    <>
      <ListCard title={'Location & Address'} 
      description={'Please add the location information of your property.'}
      showPropertyCard={route.params?.name} />
      <MarkAsComplete
        label={'Property details'}
        flowID={route?.params?.id}
        flowtype={route?.params?.key}
        isComplete={route?.params?.status === 2 ? true : false}
        
      />
      <Text fontWeight={600} marginTop={15} marginBottom={10} fontSize={18} marginLeft={10}>
        Address
      </Text>
      <Box style={{ zIndex: 999 }}>
        <RowCol>
          <TextInputWrapper>
            <TextInputComponent
              height={40}
              onChangeText={(e) => _handleChange({ value: e, label: 'postcode' })}
              placeholder={'Enter Postcode'}
              value={state?.postcode}
              error={state?.postcode ? false : '*Required'}
              title={'Search for the property address'}
            />
          </TextInputWrapper>
          <Button
            alignSelf={layoutType == 'phone' ? 'flex-end' : 'auto'}
            type={'stripe'}
            onPress={() => _onPostcodeSearch()}
            childText={'Search'}
            width={95}
          />
        </RowCol>
        {postcodes.length > 0 ? (
          <AddressSelect options={options} defaultValue={options.length > 0 ? options[0] : null}  styles={customStyles} onChange={(e) => _onAddressSelect(e)} isSearchable={true} />
        ) : null}
      </Box>
      <Text fontWeight={600} marginTop={30} marginBottom={20} fontSize={18} marginLeft={10}>
        or enter address manually below
      </Text>
      <Box>
        <RowCol>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <TextInputComponent
              onChangeText={(e) => _handleChange({ value: e, label: 'houseName' })}
              value={state?.houseName}
              height={40}
              title={'House name or number'}
              error={state?.houseName ? false : '*Required'}
            />
          </TextInputWrapper>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <TextInputComponent
              height={40}
              title={'Address Line 1'}
              onChangeText={(e) => _handleChange({ value: e, label: 'address1' })}
              value={state?.address1}
              error={state?.address1 ? false : '*Required'}
            />
          </TextInputWrapper>
        </RowCol>
        <RowCol>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <TextInputComponent
              onChangeText={(e) => _handleChange({ value: e, label: 'city' })}
              value={state?.city}
              keyboardType="letters"
              height={40}
              title={'Town/city'}
              error={state?.city ? false : '*Required'}
            />
          </TextInputWrapper>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <TextInputComponent
              height={40}
              title={'Country'}
              onChangeText={(e) => _handleChange({ value: e, label: 'country' })}
              value={state?.country}
              error={state?.country ? false : '*Required'}
            />
          </TextInputWrapper>
        </RowCol>
      </Box>
      <Box style={{ marginTop: 15 }}>
        <RowCol>
          <TextInputWrapper width={'100%'}>
            <TextInputComponent
              height={40}
              onChangeText={(e) => _handleChange({ value: e, label: 'property_name' })}
              value={state?.property_name}
              title={'Property Name'}
              error={state?.property_name ? false : '*Required'}
            />
          </TextInputWrapper>
        </RowCol>
      </Box>
      {/* <HorizontalLine />
      <Text fontWeight={600} marginTop={10} marginBottom={10} fontSize={18} marginLeft={10}>
        Property details
      </Text> */}
      {/* <Box>
        <RowCol>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <TextInputComponent
              onChangeText={(e) => _handleChange({ value: e, label: 'property_worth' })}
              value={state?.property_worth}
              height={40}
              title={'Property worth (£)'}
              isNumeric={true}
              // error={state?.property_worth ? false : '*Required'}
            />
          </TextInputWrapper>
          <TextInputWrapper width={layoutType != 'phone' ? '48%' : '100%'}>
            <DropDownPicker
              // error={state?.mortgage_provider ? false : true}
              items={mortageProviders}
              title={'Mortgage provider'}
              defaultValue={state?.mortgage_provider}
              onChangeItem={(e) => _handleChange({ value: e.value, label: 'mortgage_provider' })}
            />
          </TextInputWrapper>
        </RowCol>
      </Box>*/}
      <Button
        onPress={() => _onSave()}
        childText={'Save and Continue'}
        width={150}
        style={{ alignSelf: 'flex-end', marginVertical: 30 }}
      />
    </>
  );
};

export default drawerHoc({
  Component: PropertyAddress,
  title: 'PropertyAddress',
  showBackButton: true,
});
