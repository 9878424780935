import React, { useState } from 'react';
import {
  GradientOverlay,
  FormContainer,
  ImageWrapper,
  TopSection,
  TouchableWrapper,
  RightSection,
} from './login.style';
import Text from '../../components/text';
import Colors from '../../constants/Colors';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import Button from '../../components/button/Button';
import GradientText from '../../components/gradientText/GradientText';
import rightImg from '../../assets/images/forgotImage.png';
import { EmailValidation, PasswordValidation } from '../../helpers/validation';
import Urls from '../../services/Urls';
import ajax, { setAuthHeaders } from '../../helpers/ajaxHelper';
import { useDispatch, useSelector } from 'react-redux';
import { saveAuthToken } from '../../redux/action/authActions';
import { ImageLogoWrapper, Main ,Container,LeftSection} from '../register/register.style';
import WebImage from '../../assets/images/Logo/HubitaWeb.png';
import MobileImage from '../../assets/images/Logo/HubitaMobile.png';

import getPlatformType from '../../helpers/helpers';

const LoginScreen = ({ navigation }) => {
  const layoutType = getPlatformType();
  let LogoImg = layoutType != 'phone' ? WebImage : MobileImage;
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passError, setPassError] = useState('');

  const loginAPiCall = () => {
    const requestBody = {
      email: email,
      password: password,
    };
    ajax
      .post(Urls.LoginAPI, requestBody)
      .then(({ data }) => {
        if (data.status) {
          
          setAuthHeaders(data?.data?.token);
          dispatch(saveAuthToken(data?.data?.token , navigation) );
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const _onLogin = () => {
    let emailValidate = EmailValidation(email);
    let passwordValidate = PasswordValidation(password);
    if (emailValidate.status && passwordValidate.status) {
      loginAPiCall();
    } else {
      setEmailError(emailValidate?.status ? '' : emailValidate?.msg);
      setPassError(passwordValidate?.status ? '' : passwordValidate?.msg);
    }
  };
  return (
    <>
    <Container>
      <LeftSection>
        <FormContainer>
          <ImageLogoWrapper source={LogoImg} />
          <Text fontSize={30} marginBottom={30} marginTop={10} color={Colors.light.gradientColor1} fontWeight={450}>
            Login to your{'\n'}existing account
          </Text>
          <TextInputComponent
            error={emailError}
            title={'Email Address'}
            onChangeText={(e) => {
              setEmail(e), setEmailError('');
            }}
            colored
          />
          <TextInputComponent
            error={passError}
            password
            maxLength={30}
            title={'Password'}
            renderRightComponent={true}
            onChangeText={(e) => {
              setPassword(e), setPassError('');
            }}
            colored
          />
          <TouchableWrapper onPress={() => navigation.navigate('ForgotPasswordScreen')}>
            <Text fontSize={20} color={Colors.light.purple} fontWeight={450}>
              Forgot Password?
            </Text>
          </TouchableWrapper>
          <Button onPress={_onLogin} childText={'Login'} alignSelf={'flex-end'} />
        </FormContainer>
      </LeftSection>
      <RightSection>
        <TopSection>
       {layoutType != 'phone'?<>
          <Text fontSize={20} color={Colors.light.white} fontWeight={450}>
            Don't have an account yet?   {' '}
          </Text>
          <Button
            onPress={() => navigation.navigate('RegisterScreen')}
            type={'stripe'}

            childText={'Register'}
            color= {Colors.light.white}
            width={105}
          />
          </>:
          <>
             <Text fontSize={18} color={Colors.light.text} fontWeight={450}>
             Don't have an account yet?  {' '}
           </Text>
           <Button
             onPress={() => navigation.navigate('RegisterScreen')}
             type={'stripe'}
 
             childText={'Register'}
             color= {Colors.light.purple}
             width={105}
             />
             </>
}

        </TopSection>
        {
            layoutType == 'phone' ? null :
            <>
            <ImageWrapper source={rightImg} />       
            <GradientOverlay colors={['#7002BC90', '#3364F590']} start={{ x: 1, y: 0 }} end={{ x: 1, y: 1 }} />
            </>
        }
      </RightSection>
    </Container>
    </>
  );
};

export default LoginScreen;
