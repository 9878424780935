import React, { useState } from 'react';
import {
  Container,
  GradientView,
  BackButtonContainer,
  WhiteView,
  TextWrapper,
  LogoutContainer,
} from './StatusBar.style';
import { Ionicons } from '@expo/vector-icons';
import { goBack, navigate } from '../../navigation/RootNavigation';
import { TouchableOpacity, Image, View } from 'react-native';
import PopUp from '../popUp/PopupView';

import { useDispatch, useSelector } from 'react-redux';
import { removeAuthToken } from '../../redux/action/authActions';

const StatusBar = ({ backbutton, title }) => {
  const userToken = useSelector((state) => state?.AppReducer?.token);
  console.log('userTokenuserToken', userToken);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  return (
    <Container>
      <GradientView colors={['#6612B6', '#4258DB']} start={{ x: 1, y: 0 }} end={{ x: 1, y: 0 }} />
      <WhiteView>
        <BackButtonContainer onPress={() => goBack()}>
          {backbutton ? (
            <>
              <Ionicons name="chevron-back-outline" size={24} color="#4258DB" />
              <TextWrapper>Back</TextWrapper>
            </>
          ) : null}
        </BackButtonContainer>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TouchableOpacity onPress={() => navigate('home')}>
            <Image
              source={require('./../../assets/images/Logo/HubitaWeb.png')}
              style={{ height: 17, width: 150, marginRight: 20 }}
              resizeMode={'contain'}
            />
          </TouchableOpacity>
          {userToken ? (
            <TouchableOpacity onPress={() => setIsVisible(true)}>
              <LogoutContainer>{'Logout'}</LogoutContainer>
            </TouchableOpacity>
          ) : null}
        </View>
      </WhiteView>
      <PopUp
        modalVisible={isVisible}
        titleText={'Logout?'}
        hideClose={true}
        titleColor={'#6D08C0'}
        subTitleText={'Are you sure you want to logout?'}
        subTitleColor={'black'}
        actionButtons={[
          {
            text: 'Logout',
            width: 100,
            onPress: () => {
              dispatch(removeAuthToken());
              navigate('login');
              setIsVisible(false);
            },
          },
          {
            text: 'Cancel',
            width: 100,
            onPress: () => {
              setIsVisible(false);
            },
          },
        ]}
        closePopup={() => setIsVisible(false)}
      />
    </Container>
  );
};

export default StatusBar;
