import React, { useState } from 'react';
import { FlatList, Platform, TextInput, TouchableOpacity, View } from 'react-native';
import { Row, Left, Right, Center } from './index.style';
import PropTypes from 'prop-types';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';
import Box from '../box';
import StatusButton from '../statusButton/statusButton';
import PopUp from '../popUp/PopupView';
const DocumentView = (props) => {
  const { leftIcon, title, description, status, statusButton, url, onDelete } = props;
  const _openURL = () => {
    if (Platform.OS == 'web') {
      window.open(url);
    } else {
    }
  };
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Box style={{ marginVertical: 10 }}>
      <Row>
        <Left>
          <Text fontSize={16} fontWeight={'500'}>
            {title}
          </Text>
        </Left>
        <Center>
          <TouchableOpacity onPress={() => _openURL()}>
            <MaterialIcons size={20} name="visibility" />
          </TouchableOpacity>
        </Center>
        <Right>
          <TouchableOpacity onPress={() => setIsVisible(true)}>
            <MaterialIcons size={20} name="delete" />
          </TouchableOpacity>
        </Right>
      </Row>
      <PopUp
        modalVisible={isVisible}
        titleText={'Archive Document?'}
        titleColor={'#6D08C0'}
        subTitleText={`What do you want to do with this document?\nArchive the document or Keep it in in your "My Docs" section`}
        subTitleColor={'black'}
        actionButtons={[
          {
            text: 'Archive',
            width: 100,
            onPress: () => {
              onDelete();
              setIsVisible(false);
            },
          },
          {
            text: 'My Docs',
            width: 100,
            onPress: () => {
              onDelete(true);
              setIsVisible(false);
            },
          },
        ]}
        closePopup={() => setIsVisible(false)}
      />
    </Box>
  );
};
DocumentView.propTypes = {
  leftIcon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.number,
  statusButton: PropTypes.bool,
  onPress: PropTypes.func,
};

DocumentView.defaultProps = {
  leftIcon: 'map-marker',
  onPress: () => {},
  title: 'Title',
  status: 0,
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nod.',
};

export default DocumentView;
//Eg.
{
  /* <TextInputComponent title={"Email Address"} onChangeText={(e)=>console.log(e)} error={"This Field is required"}/> */
}
