import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import getPlatformType from '../../helpers/helpers';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import { Container, ContainerScrollView, TableTools } from './index.style';
import SortList from '../../components/sortList';
import SearchCardComponent from '../../components/searchCard';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import TenantTable from '../../components/tenantTable';
import Urls from '../../services/Urls';
import ajax from '../../helpers/ajaxHelper';
import { Feather } from '@expo/vector-icons';
import moment from 'moment';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import Text from '../../components/text';
import PopUp from '../../components/popUp/PopupView';

var layoutType = getPlatformType();
var colData = ['Document Title', 'Property Name', 'Expiry Date'];
var sortData = [
  {
    name: 'A-Z',
    value: 'A to Z',
  },
  {
    name: 'Z-A',
    value: 'Z to A',
  },
  {
    name: 'ID',
    value: 'ID',
  },
];

const DocumnetArchive = ({ navigation }) => {
  const [sort, setSort] = useState({ name: 'A-Z', value: 'A-Z' });
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [deteledIndex, setDeteledIndex] = useState(false);
  const [allArhcivedDocs, setAllArchivedDocs] = useState();

  useEffect(() => {
    getAllPropertiesArchivedDocuments();
  }, []);

  const setSortData = (name, value) => {
    setSort({
      name,
      value,
    });
  };

  const getAllPropertiesArchivedDocuments = () => {
    ajax
      .get(Urls.GET_ARCHIVE_DOCUMENTS)
      .then(({ data }) => {
        if (data.status) {
          data.data && setAllArchivedDocs(data.data);
          let temp = [];
          data.data &&
            data.data.map((e) => {
              temp.push({
                name: e?.file_name || e?.document_name,
                location: e?.property.name,
                date: moment(e?.expiry_date).format('DD/MM/YYYY'),
                url: e?.document_url,
              });
            });
          setData(temp);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const onDeleteDocument = (document) => {
    const docToDelete = allArhcivedDocs && allArhcivedDocs.filter(record => record.document_url === document.url)

    ajax
      .delete(`${Urls.DeleteDoc}${docToDelete[0].id}`, { data: docToDelete[0] })
      .then(({ data }) => {
        if (data.status) {
          getAllPropertiesArchivedDocuments();
          setDeteledIndex(null)
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <>
      {/* {layoutType != 'phone' ? <DrawerMenu navigation={navigation} /> : null} */}
      <Container>
        <TableTools>
          <View style={{ width: layoutType === 'web' ? '80%' : layoutType === 'tab' ? '70%' : '100%' }}>
            <SearchCardComponent
              placehorderText={"Document Search"}
              leftText={'Document archive'} />
          </View>
          <SortList renderTitle={sort.name} setSort={setSortData} data={sortData} width={200} />
        </TableTools>

        <TenantTable
          data={data}
          colData={colData}
          rightIcon={<Feather name="download" size={20} color={Colors.light.purple} />}
          rowIcon={<MaterialCommunityIcons name="file-certificate" size={20} color={Colors.light.purple} />}
          deleteFile={(data) => {
            setDeteledIndex(data)
            setIsVisible(true)
          }}
        />
      </Container>

      <PopUp
        modalVisible={isVisible}
        titleText={'Delete Document?'}
        titleColor={'#6D08C0'}
        subTitleColor={'black'}
        customSubtitle={
          <View>
            <Text>Are you sure you want to delete this document permananently?</Text>
          </View>
        }
        actionButtons={[
          {
            text: 'Delete',
            width: 100,
            onPress: () => {
              onDeleteDocument(deteledIndex);
              setIsVisible(false);
            },
          },
          {
            text: 'Cancel',
            width: 100,
            onPress: () => {
              setIsVisible(false);
            },
          },
        ]}
        closePopup={() => setIsVisible(false)}
      />
    </>

  );
};

export default drawerHoc({
  Component: DocumnetArchive,
  showBackButton: true,
});
