import React, { useState } from 'react';
import {
  Container,
  GradientView,
  BackButtonContainer,
  WhiteView,
  TextWrapper,
  LogoutContainer,
} from './StatusBar.style';
import { Ionicons } from '@expo/vector-icons';
import { goBack, navigate, openDrawer } from '../../navigation/RootNavigation';
import { TouchableOpacity, Image, View, Pressable } from 'react-native';
import PopUp from '../popUp/PopupView';
import Modal from 'modal-enhanced-react-native-web';
import { useDispatch, useSelector } from 'react-redux';
import { removeAuthToken } from '../../redux/action/authActions';
import DrawerMenu from '../../screens/drawerMenu/DrawerMenu';
const StatusBarMobile = ({ backbutton, navigation }) => {
  const userToken = useSelector((state) => state?.AppReducer?.token);
  console.log('backbutton', backbutton);
  const [isVisible, setIsVisible] = useState(false);
  const [openDrawer, setDrawer] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      {/* <GradientView colors={['#6612B6', '#4258DB']} start={{ x: 1, y: 0 }} end={{ x: 1, y: 0 }} /> */}
      <WhiteView>
        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 0.5 }}>
          <BackButtonContainer onPress={() => goBack()}>
            {backbutton ? (
              <>
                <Ionicons name="chevron-back-outline" size={24} color="#4258DB" />
                <TextWrapper>Back</TextWrapper>
              </>
            ) : null}
          </BackButtonContainer>

          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TouchableOpacity
          onPress={()=>navigate("home")}
          >
            <Image
              source={require('./../../assets/images/Logo/HubitaWeb.png')}
              style={{ height: 17, width: 87, marginHorizontal: '6%' }}
              resizeMode={'contain'}
            />
            </TouchableOpacity>
          </View>
        </View>
        {userToken? <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', flex: 0.5 }}>
        <TouchableOpacity onPress={() => setIsVisible(true)}>
            <LogoutContainer>Logout</LogoutContainer>
          </TouchableOpacity>
          <Pressable
            onPress={() => setDrawer(true)}
            style={{ flexDirection: 'row', alignItems: 'center', marginHorizontal: '6%' }}
          >
            <Image
              source={require('./../../assets/images/menu.png')}
              style={{ height: 16.8, width: 22.4 }}
              resizeMode={'contain'}
            />
          </Pressable>
        </View>:null}
      </WhiteView>
      <PopUp
        modalVisible={isVisible}
        hideClose={true}
        titleText={'Logout?'}
        titleColor={'#6D08C0'}
        subTitleText={'Are you sure you want to logout?'}
        subTitleColor={'black'}
        actionButtons={[
          {
            text: 'Logout',
            width: 100,
            onPress: () => {
              dispatch(removeAuthToken());
              navigate('login');
              setIsVisible(false);
            },
          },
          {
            text: 'Cancel',
            width: 100,
            onPress: () => {
              setIsVisible(false);
            },
          },
        ]}
        closePopup={() => setIsVisible(false)}
      />
      <Modal
        isVisible={openDrawer}
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInTiming={300}
        animationOutTiming={300}
        coverScreen={false}
        hasBackdrop={false}
        backdropOpacity={0}
        onBackdropPress={() => setDrawer(false)}
        style={{ margin: 0, width: '100%', right: 0, backgroundColor: '#00000010' }}
      >
        <View style={{ width: '70%', height: '100%', position: 'absolute', right: 0 }}>
          <DrawerMenu setDrawer={setDrawer} />
        </View>
      </Modal>
    </>
  );
};

export default StatusBarMobile;
