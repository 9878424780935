import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { MediaBox, MediaWrapper, TitleText, MediaDesc, MediaDate, FooterBox } from './styles';

const layoutType = getPlatformType();
import MediaDetails from './mediaDetails';

import moment from 'moment';
import getPlatformType from '../../helpers/helpers';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import { navigate } from '../../navigation/RootNavigation';
import Button from '../../components/button/Button';
import RenderHtml from 'react-native-render-html';

const MediaBlogHub = (props) => {
  const [showBlogDetail, setShowBlogDetail] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState('');
  const [posts, setPost] = useState([]);

  useEffect(() => {
    ajax
      .get(Urls.GET_POST + 'BLOG')
      .then(({ data }) => {
        if (data.status) {
          console.log('ASDASDAS', data);
          if (data?.payload && data?.payload?.length) {
            setPost(data?.payload);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [focus]);

  const createMarkup = (data) => {
    return { __html: data.description.split('.')[0] };
  };

  return (
    <>
      {showBlogDetail ? (
        <View style={{ overflow: 'visible' }}>
          <MediaDetails selectedPost={selectedMedia} />
          <FooterBox
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '100%',
              width: '100%',
            }}
          >
            <Button
              onPress={() => setShowBlogDetail(false)}
              borderRadius={15}
              type={'stripe'}
              childText={Back}
              height={layoutType == 'phone' ? 40 : 30}
              width={300}
            />
          </FooterBox>
        </View>
      ) : (
        <View style={{}}>
          <MediaWrapper>
            {posts != undefined &&
              posts.map((data, index) => {
                return (
                  <MediaBox key={`mbox${index}`}>
                    <View style={{ justifyContent: 'flex-start' }}>
                      <TitleText>{data.title}</TitleText>
                      <MediaDate>{moment(data.createdAt).format('DD-MM-YYYY')}</MediaDate>
                      <RenderHtml contentWidth={'100%'} source={{ html: `${data.description.split('.')[0]}` }} />

                      <Button
                        onPress={() => {
                          navigate('MediaHub Details', {
                            data: data.id,
                          });
                        }}
                        borderRadius={15}
                        type={'stripe'}
                        childText="Read More"
                        height={layoutType == 'phone' ? 40 : 30}
                        width={'60%'}
                      />
                    </View>
                    <View></View>
                  </MediaBox>
                );
              })}
          </MediaWrapper>
        </View>
      )}
    </>
  );
};

export default MediaBlogHub;
