import React, { useDebugValue, useEffect, useState } from 'react';
import { ScrollView, Text, TextInput, View } from 'react-native';
// import Hyperlink from "react-native-hyperlink";
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { contactUs, getProfile } from "../../redux/actions/auth";
import Button from '../../components/button/Button';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import Colors from '../../constants/Colors';
import ajax from '../../helpers/ajaxHelper';
import getPlatformType from '../../helpers/helpers';
import { EmailValidation, NameValidation, SubjectValidation } from '../../helpers/validation';
import Urls from '../../services/Urls';
import { ContactHead, ContactTitle, Container, FormContainer, MainContent } from './styles';
const layoutType = getPlatformType();

const ContactUs = ({ navigation }) => {
  const userDetails = useSelector((state) => state?.Login?.userDetails);
  const [state, setState] = useState({
    name: '',
    email: '',
    topic: '',
    message: '',
    profile: null,
    first_name: '',
    last_name: '',
    pre_email: '',
    errorMsg: '',
  });
  useEffect(() => {
    setState({ ...state, name: userDetails?.first_name + ' ' + userDetails?.last_name, email: userDetails?.email });
  }, [userDetails]);
  const validate = () => {
    let payload = {
      name: state?.name,
      email: state?.email,
      subject: state?.topic,
      message: state?.message,
    };

    let validated = validateRequiredFields();

    if(validated) {
    ajax
      .post(Urls.CONTACT_US, payload)
      .then(({ data }) => {
        console.log(data);
        alert(data?.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
    }
  };

  function validateRequiredFields() {
    let validationErrors = [];
    if(state.name === null|| state.name.length == 0) {
      validationErrors.push("Name must be set\n"); 
    }
    
    if(state.email === null|| state.email.length == 0) {
      validationErrors.push("Email must be set\n"); 
    }

    if(state.topic === null|| state.topic.length == 0) {
      validationErrors.push("Message subject must be set\n"); 
    }

    if(state.message === null|| state.message.length == 0) {
      validationErrors.push("Message must be set"); 
    }

    if(validationErrors.length == 0) {
      return true;
    }
    else {
      let errorMsg = validationErrors.map((error) => { return error;});
      setState({ ...state, errorMsg: errorMsg });
      alert('Could not send email, please set required fields');
      return false;
    }
    
  }

  return (
    <View style={{ flex: 1 }}>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <MainContent style={{ flexDirection: 'column' }}>
            <Container style={{ flexDirection: 'column' }}>
              <ContactTitle>Get in Contact</ContactTitle>
              <ContactHead
                style={{
                  flexDirection: layoutType !== 'phone' ? 'row' : 'column',
                }}
              >
                <View
                  style={{
                    flexGrow: layoutType !== 'phone' ? 1 : 1,
                    justifyContent: 'center',
                    alignItems: layoutType == 'phone' ? 'center' : 'flex-start',
                  }}
                >
                  <Text
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 15,
                      textAlign: layoutType == 'phone' ? 'center' : 'left',
                    }}
                  >
                    Email us at
                  </Text>
                  <View
                    style={{
                      width: '90%',
                      height: 40,
                      backgroundColor: '#ffffff',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <View onPress={(url) => openLink(url)}>
                      <Text style={{ color: Colors.orange, fontWeight: 'bold' }}>info@hubita.co.uk</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flexGrow: layoutType !== 'phone' ? 1 : 1,
                    justifyContent: 'center',
                    alignItems: layoutType == 'phone' ? 'center' : 'flex-start',
                  }}
                >
                  {/* <Text
                    style={{
                      fontWeight: 'bold',
                      marginBottom: 10,
                      marginTop: 10,
                      textAlign: layoutType == 'phone' ? 'center' : 'left',
                    }}
                  >
                    Call us
                  </Text>
                  <View
                    style={{
                      width: '90%',
                      height: 40,
                      marginLeft: layoutType != 'phone' ? 10 : 0,
                      backgroundColor: '#ffffff',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <View onPress={(url) => openLink(url)}>
                      <Text style={{ color: Colors.orange, fontWeight: 'bold' }}>0203 740 4721</Text>
                    </View>
                  </View> */}
                </View>
              </ContactHead>
            </Container>
            {/* <FormControls
                style={{
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.09,
                  shadowRadius: 6.68,
                  elevation: 11,
                }}
              > */}

            <Container style={{ backgroundColor: 'white', padding: 20, borderTopRightRadius: 10 }}>
              <View
                style={{
                  width: '100%',
                  paddingVertical: 15,
                }}
              >
                <Text
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: 20,
                    marginBottom: 24,
                  }}
                >
                  Send us message
                </Text>

                {state.errorMsg && <Text
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    fontSize: 14,
                    marginBottom: 24,
                  }}
                >
                  {state.errorMsg}
                </Text>}

                <FormContainer>
                  <View
                    style={{
                      flexGrow: layoutType !== 'phone' ? 1 : 0,
                      marginBottom: 16,
                      width: layoutType !== 'phone' ? 'auto' : '100%',
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                    >
                      Name
                    </Text>
                    <View
                      style={{
                        width: '100%',
                        height: 40,
                      }}
                    >
                      <TextInput
                        style={{
                          width: '100%',
                          height: '100%',
                          padding: 10,
                          borderColor: '#b2b2b2',
                          borderWidth: 1,
                          borderRadius: 5,
                        }}
                        maxLength={40}
                        onChangeText={(text) => setState({ ...state, name: text, emailErrorMsg: '' })}
                        value={state.name}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      flexGrow: layoutType !== 'phone' ? 1 : 0,
                      marginLeft: layoutType !== 'phone' ? 16 : 0,
                      marginBottom: 16,
                      width: layoutType !== 'phone' ? 'auto' : '100%',
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                    >
                      Your Email Address
                    </Text>
                    <View
                      style={{
                        width: '100%',
                        height: 40,
                      }}
                    >
                      <TextInput
                        style={{
                          width: '100%',
                          height: '100%',
                          padding: 10,
                          borderColor: '#b2b2b2',
                          borderWidth: 1,
                          borderRadius: 5,
                        }}
                        maxLength={40}
                        onChangeText={(text) => setState({ ...state, email: text, emailErrorMsg: '' })}
                        value={state.email}
                      />
                    </View>
                  </View>
                </FormContainer>
                <View style={{ width: '100%', marginBottom: 16 }}>
                  <View>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                    >
                      Subject of message
                    </Text>
                    <View
                      style={{
                        width: '100%',
                        height: 40,
                        marginBottom: 15,
                      }}
                    >
                      <TextInput
                        style={{
                          width: '100%',
                          height: '100%',
                          padding: 10,
                          borderColor: '#b2b2b2',
                          borderWidth: 1,
                          borderRadius: 5,
                        }}
                        maxLength={40}
                        onChangeText={(text) => setState({ ...state, topic: text })}
                        value={state.topic}
                      />
                    </View>
                  </View>
                </View>
                <View style={{ width: '100%' }}>
                  <View>
                    <Text style={{ fontWeight: 'bold', marginBottom: 10 }}>Write your message here</Text>
                    <View
                      style={{
                        width: '100%',
                        height: 150,
                        borderColor: '#b2b2b2',
                        borderWidth: 1,
                        borderRadius: 5,
                      }}
                    >
                      <TextInput
                        style={{
                          width: '100%',
                          height: '100%',
                          padding: 10,
                        }}
                        maxLength={2000}
                        multiline
                        onChangeText={(text) => setState({ ...state, message: text })}
                        value={state.message}
                      />
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: 129,
                    alignSelf: 'flex-end',
                    marginTop: 16,
                  }}
                >
                  <Button childText={'Send'} buttonType={'solid'} gradiant onPress={() => validate()} />
                </View>
              </View>
            </Container>
          </MainContent>
        </View>
      </ScrollView>
    </View>
  );
};
export default drawerHoc({
  Component: ContactUs,
  showBackButton: true,
});
