import styled from 'styled-components/native';
import { TextInput } from 'react-native';

export const Container = styled.View``;
export const TextInputWrapper = styled(TextInput)`
  width:100%;
  height:${(props) => (props?.height ? props.height + 'px' : '50px')};
  background-color:${(props) => (props?.colored ? 'rgba(159,120,255,0.16)' : null)};
  margin-bottom:10px;
  padding:10px;
  border-radius:5px;
  font-size:18px;
  // border:${(props) => (props?.error ? '1px solid red' : props?.colored ? 0 : '1px solid #EBEBEB')}
  border:${(props) => (props?.colored ? 0 : '1px solid #EBEBEB')}
  border-bottom-left-radius:${({ isAmount }) => (isAmount ? 0 : 5)}
  border-top-left-radius:${({ isAmount }) => (isAmount ? 0 : 5)}
  border-boxing:border-box;
`;

export const InputWrapper = styled.View`
  flex-direction: row;
`;
