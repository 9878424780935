import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/helpers";

const layoutType = getPlatformType();
let Container,
  SectionWrapper,
  TeamHeader,
  MemberHeader,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  MemberDetails,
  MemberDetailsText,
  SectionPageTitle,
  BulletText,
  Bullet,
  SectionInnerTitle,
  SectionTitleTop,
  PrivacyGrid,
  PrivacyGridHeader,
  PrivacyGridBody,
  PrivacyLeftBox,
  PrivacyRightBox,
  BulletInlineText,
  CookieTable,
  CookieCells,
  Asterick;

Container = styled.View`
  margin: 0 auto;
`;

SectionWrapper = styled.View`
  width: 100%;
  padding: 30px;
`;
MemberHeader = styled.View`
  text-align: center;
  padding: 25px;
`;
TeamHeader = styled.Text`
  color: #000000;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
`;

DualView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
TextBox = styled.View`
  height: 415px;
  width: 526px;
  padding-top: 40px;
`;
SectionTitle = styled.Text`
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 30px;
  line-height: 24px;
`;
SectionInnerTitle = styled.Text`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 30px;
  line-height: 18px;
`;
SectionPageTitle = styled.Text`
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-top: 20px;
`;
SectionTitleTop = styled.Text`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 25px;
  line-height: 26px;
`;
SectionText = styled.Text`
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 20px;
`;
BulletText = styled.Text`
  padding: 10px 10px 10px 30px;
  position: relative;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`;
BulletInlineText = styled.Text`
  padding: 10px 10px 10px 30px;
  position: relative;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`;
Bullet = styled.Text`
  position: absolute;
  top: 7px;
  left: 20px;
  font-size: 15px;
`;
Asterick = styled.Text`
  position: absolute;
  top: 12px;
  left: 20px;
  font-size: 15px;
`;
ImageBox = styled.View`
  height: 415px;
  width: 526px;
`;
TeamsSection = styled.View`
  background-color: #fff;
  box-shadow: rgba(128, 128, 128, 0.9) 0px 1px 4.68px;
  margin-top: 20px;
`;
MemberBoxWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  padding-top: 0px;
`;
MemberBox = styled.View`
  width: 250px;
  text-align: center;
  align-items: center;
`;
MemberImage = styled.View`
  height: 202px;
  width: 200px;
`;
MemberName = styled.View`
  padding: 10px 0px;
`;
MemberNameText = styled.Text`
  color: #e71e82;
  font-size: 18px;
  font-weight: 600;
`;
MemberDetails = styled.View``;
MemberDetailsText = styled.Text`
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
`;
PrivacyGrid = styled.View`
  border: 1px solid black;
  margin-top: 20px;
`;
PrivacyGridHeader = styled.Text`
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  padding: 15px;
`;
PrivacyGridBody = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
PrivacyLeftBox = styled.Text`
  padding: 15px;
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  border: 1px solid black;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 40%;
`;
PrivacyRightBox = styled.Text`
  padding: 15px;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  border: 1px solid black;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  width: 60%;
`;
CookieTable = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border: 1px solid black;
`;
CookieCells = styled.Text`
  padding: 10px;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  border: 1px solid black;
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

if (layoutType == "phone") {
  Container = styled.View`
    margin: 0 auto;
  `;

  TeamHeader = styled.Text`
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  `;
  TextBox = styled.View`
    width: 312px;
    padding-top: 0px;
  `;
  ImageBox = styled.View`
    height: 240px;
    width: 312px;
  `;
  SectionWrapper = styled.View`
    width: 100%;
    padding: 10px;
    padding-bottom: 30px;
  `;
  DualView = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  `;
  MemberBox = styled.View`
    width: 150px;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
  `;
  MemberBoxWrapper = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    flex-wrap: wrap;
  `;
  MemberImage = styled.View`
    height: 140px;
    width: 140px;
  `;
  MemberDetailsText = styled.Text`
    color: #000000;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
  `;
  BulletText = styled.Text`
    padding: 10px 10px 10px 15px;
    position: relative;
    font-size: 15px;
    display: flex;
    width: 100%;
  `;
  Asterick = styled.Text`
    position: absolute;
    top: 12px;
    left: 5px;
    font-size: 15px;
  `;
  SectionTitle = styled.Text`
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    margin-top: 20px;
    line-height: 24px;
  `;
  Bullet = styled.Text`
    position: absolute;
    top: 5px;
    left: 1px;
    font-size: 20px;
  `;
  SectionPageTitle = styled.Text`
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin-top: 20px;
  `;
  SectionTitleTop = styled.Text`
    color: #000000;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    margin-top: 25px;
    line-height: 26px;
  `;
  PrivacyGridBody = styled.View`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  `;
  PrivacyLeftBox = styled.Text`
    padding: 15px;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    border: 1px solid black;
    border-bottom-width: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
    width: 100%;
  `;
  PrivacyRightBox = styled.Text`
    padding: 15px;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    border: 1px solid black;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    width: 100%;
  `;
  CookieTable = styled.View`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 20px;
    border: none;
  `;
  CookieCells = styled.Text`
    padding: 10px;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    border: 1px solid black;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  `;
} else if (layoutType == "web") {
} else {
  TextBox = styled.View`
    height: 429px;
    width: 430px;
    padding-top: 40px;
  `;
  ImageBox = styled.View`
    height: 429px;
    width: 430px;
  `;
  SectionTitle = styled.Text`
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  `;
  SectionText = styled.Text`
    color: #000000;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 20px;
  `;
  Container = styled.View`
    margin: 0 auto;
  `;
  TeamHeader = styled.Text`
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  `;
}

export {
  Container,
  SectionWrapper,
  TeamHeader,
  MemberHeader,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  MemberDetails,
  MemberDetailsText,
  SectionPageTitle,
  SectionTitleTop,
  BulletText,
  Bullet,
  SectionInnerTitle,
  PrivacyGrid,
  PrivacyGridHeader,
  PrivacyGridBody,
  PrivacyLeftBox,
  PrivacyRightBox,
  Asterick,
  BulletInlineText,
  CookieTable,
  CookieCells,
};
