import React, { useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { ModalHeader, ModalBody } from './style';
import { Button } from '../button';

const OpenFlowModal = (props) => {
  const {
    modalBody,
    footerButtonText,
    handleOnModalButtonClick,
    showFooter,
    showModal,
    setShowModal,
    modalHeading = '',
    modalSubHeading = '',
    modalWidth = 500,
  } = props;

  return (
    <Modal
      open={showModal ? showModal : false}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <div
        style={{
          width: modalWidth,
          backgroundColor: '#f4f4f4',
          boxShadow: 'none',
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
          outline: 'none',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          position: 'relative',
          color: '#000',
          maxHeight: '81vh',
          overflowX: 'auto',
        }}
      >
        <span
          style={{
            position: 'absolute',
            right: 25,
            fontWeight: 'bold',
            color: '#6D08C0',
            cursor: 'pointer',
          }}
          onClick={() => setShowModal(false)}
        >
          x
        </span>
        <ModalBody>
          <ModalHeader>{modalHeading}</ModalHeader>
          <p style={{ fontSize: 14 }}>{modalSubHeading}</p>
          {modalBody}
        </ModalBody>
        {showFooter && (
          <div className="modal-footer" style={{ border: 'none' }}>
            <Button
              type="stripe"
              style={{ color: '#FC4850', borderColor: '#FC4850' }}
              onClick={handleOnModalButtonClick}
              title={footerButtonText}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default OpenFlowModal;
