import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { MediaWrapper, TitleText, MediaVideoBox, MediaVideoDesc } from './styles';

const layoutType = getPlatformType();
import YoutubePlayer from '../../components/youtubePlayer/youtubePlayer';
import getPlatformType from '../../helpers/helpers';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import { useIsFocused } from '@react-navigation/native';

const MediaVideoHub = (props) => {
  const [posts, setPost] = useState([]);

  useEffect(() => {
    ajax
      .get(Urls.GET_POST + 'VIDEO')
      .then(({ data }) => {
        if (data.status) {
          console.log('ASDASDAS', data);

          if (data?.payload) {
            if (data?.payload && data?.payload?.length) {
              setPost(data?.payload);
            }
          }
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [focus]);

  return (
    <>
      <View style={{}}>
        <MediaWrapper>
          {posts != undefined &&
            posts.map((data, index) => {
              return (
                <MediaVideoBox
                  key={`mbox${index}`}
                  style={{
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.09,
                    shadowRadius: 6.68,
                    elevation: 11,
                  }}
                >
                  <View style={{ justifyContent: 'flex-start' }}>
                    <View style={{ height: 200, marginBottom: 20 }}>
                      {/* <Video  
                                            source={{
                                                uri: data.video_id
                                            }}
                                            progressUpdateIntervalMillis={500}
                                            positionMillis={0}
                                            shouldPlay={false}
                                            rate={1.0}
                                            shouldCorrectPitch={false}
                                            volume={1.0}
                                            isMuted={false}
                                            isLooping={false}
                                            resizeMode={Video.RESIZE_MODE_COVER}
                                            useNativeControls={true}
                                            style={{
                                                flex: 1,
                                                backgroundColor: "black",
                                                justifyContent: "center",
                                            }}
                                        /> */}
                      <YoutubePlayer url={data.video_id} height={200} width={'100%'} />
                    </View>
                    <View style={{ paddingLeft: 25, paddingRight: 25 }}>
                      <TitleText>{data.title}</TitleText>
                      <MediaVideoDesc>{data.desc}</MediaVideoDesc>
                    </View>
                  </View>
                </MediaVideoBox>
              );
            })}
        </MediaWrapper>
      </View>
    </>
  );
};

// const mapStateToProps = (state) => ({
//   loading: state.mediaReducer.postLoading,
//   posts: idx(state, (_) => _.mediaReducer.videoPost.data),
// });
// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       getPosts,
//     },
//     dispatch
//   );
export default MediaVideoHub;
