import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.web.css';
import { StyleSheet, View } from 'react-native';

import Text from '../text';
import moment from 'moment';
import { useIsFocused } from '@react-navigation/native';

const DatePickerComponent = (props) => {
  const focus = useIsFocused();
  const [state, setState] = useState({
    value: moment().format('MM/DD/YYYY'),
    placeholder: '',
  });
  useEffect(() => {
    if (props?.value) {
      setState({
        ...state,
        value: props?.value,
        placeholder: props.placeholder,
      });
    }
  }, [props?.value, props.placeholder]);

  const onChange = (date) => {
    let tempdate = moment(date).format('MM/DD/YYYY');
    //  console.log("new Date(state.value)",new Date(tempdate) )
    props?.onUpdateInput(tempdate);
  };

  return (
    <If condition={focus}>
      <View
        style={{
          width: props?.showMonths ? 0 : '100%',
          alignItems: 'flex-start',
          marginBottom: 10,
        }}
      >
        {state.placeholder ? (
          <Text fontWeight={'600'} lineHeight={22} marginBottom={10} fontSize={16}>
            {state.placeholder}
          </Text>
        ) : null}
        <View
          style={
            props?.showMonths
              ? {
                  justifyContent: 'center',
                  padding: 5,
                  width: 20,
                  height: 20,
                  backgroundColor: 'transparent',
                  position: 'absolute',
                  top: '-8px',

                  right: 0,
                  left: 0,
                  display: 'flex',
                }
              : {
                  width: '100%',
                  height: 40,
                  borderWidth: 1,
                  justifyContent: 'center',
                  padding: 5,
                  borderColor: '#EBEBEB',
                  borderRadius: 5,
                  display: 'flex',
                }
          }
        >
          {/* <div className="App"> */}
            <DatePicker
              onChange={(data) => onChange(data)}
              selected={props?.showMonths ? null : new Date(state.value)}
              // wrapperClassName="App"
              portalId="root-portal"
              dateFormat={props?.showMonths ? 'MMM yyyy' : 'dd/MM/yyyy'}
              showMonthYearPicker={props?.showMonths ? true : false}
              yearDropdownItemNumber={100}
              scrollableYearDropdown={true}
              showYearDropdown
              showMonthDropdown
              disabled={props?.isDisable ? true : false}
            />
          {/* </div> */}
        </View>
      </View>
    </If>
  );
};
const styles = StyleSheet.create({
  inputLabel: {
    marginBottom: 10,
    fontWeight: 'bold',
  },
});
export default DatePickerComponent;
